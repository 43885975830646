@import "./mixin.scss";
@import "./variable.scss";
@import "../css/font-awesome.css";

* {
  box-sizing: border-box;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

html {
  font-size: 16px !important;
}

body {
  line-height: 1.5 !important;
  font-family: "daimler_cs_regular" !important;
  width: 100%;
  overflow: hidden;
}

blockquote,
q {
  quotes: none;
}

blockquote {

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:active {
    color: inherit;
  }
}

.align-center {
  align-items: center;
}

//reports styles
.del {
  padding: 0 !important;
}

.padding-edit-geo {
  margin: 0.5rem;
}

.driving-analysis-chart {
  height: 160px;
}

.driving-donut-chart {
  height: 155px;
  overflow: hidden;
  position: relative;
  bottom: 1.5rem;
}

.arrow-up {
  width: 0;
  height: 0;
  border-bottom: 9px solid #afafb0;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  margin: auto;
}

#alertReport-new {
  .navTab {
    background: #afafb0;

    align-items: center;
    margin-left: 6rem;

    font-weight: bold;
    width: 90%;

    ul li {
      color: #3f3f3f;
    }

    ul {
      display: flex;
      justify-content: space-between;
    }

    li {
      margin-right: 0;
    }

    ul li.active {
      background: #666666 !important;
      color: #bdbdbd !important;
    }

    color: #3f3f3f;
  }

  // .navTab::before {
  //   content: "";
  //   position: absolute;
  //   top: 10.8rem;
  //   right: 23rem;

  //   width: 14px;
  //   height: 14px;
  //   background: #AFAFB0;

  //   transform: rotate(45deg);
  //   -webkit-transform: rotate(45deg);
  //   -moz-transform: rotate(45deg);
  //   -o-transform: rotate(45deg);
  //   -ms-transform: rotate(45deg);
  //   border-width: 0 1px 1px 0;
  //   right: 45rem;
  // }
}

.alert-drop-box-reports {
  align-items: center;
  margin-left: 17rem;
  // height: 2rem;
  background: #afafb0;
  color: #3f3f3f;
  font-weight: bold;
  width: 70%;
}

.alerts-sub-box {
  padding: 0.5rem;
}

#repTable .table th {
  color: #bdbdbd !important;
  border: none;
}

#repPage .reports .reportFilters .form-group .dropdown {
  background: transparent !important;
}

#repTable .table thead tr th {
  border: 2px solid #20284c;
}

#repTable .table td {
  color: #a4a4a4 !important;
  border: 2px solid #20284c;
}

#repTable .table thead {
  background-color: #131c34;
  color: #a4a4a4 !important;
  border: 2px solid #20284c;
}

#repTable .table tbody tr:nth-of-type(odd) {
  background-color: #060818;
}

#repTable .table tbody tr:nth-of-type(even) {
  background-color: #131c34;
}

.tableWrapperAnalysys .table tbody tr:nth-of-type(odd) {
  background-color: #131c34;
}

.tableWrapperAnalysys .table tbody tr:nth-of-type(even) {
  background-color: #060818;
}

.report-filter-wrapper-new {
  select {
    background-color: transparent !important;
    color: #a4a4a4 !important;
    border: none;
  }

  display: flex;
}

.report-filter-background {
  background: transparent;
  color: #bdbdbd;
}

.submit-fuel-button {
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  font-size: 14px !important;
  color: black;

  &[disabled] {
    box-shadow: none;
  }
}

.react-datepicker__input-container input {
  border-radius: 4px;
  border: none;
  background: transparent;
  color: #bfbfc0;
}
.driver-profile-datepicker{
  border: 2px solid #212a54 !important;
  width: 100% !important;
  height: 33px !important;
}
.driver-profile-datepicker:focus{
  border: 2px solid #212a54 !important;
  width: 100% !important;
  height: 33px !important;
}

.header-reports-new {
  height: 4.5rem !important;
}

.text-border-report {
  border-bottom: 2px solid rgb(189, 189, 189);
}

//profile styles
.update-loader {
  line-height: 8rem;
  font-size: 15px;
  text-align: center;
  color: #bdbdbd;
  font-weight: bold;
}

.update-loader-graph {
  font-size: 15px;
  margin: auto;
  color: #bdbdbd;
  font-weight: bold;
}

#inputIdIpad {
  width: 11.5rem;
  text-indent: 8px;
  margin-top: 1rem;
}

.leftSidePanelIpad {
  background-color: #141728;
  margin-left: 0.5rem;
  margin-bottom: 0.7rem;
  margin-right: 0.5rem;
  border-radius: 5%;
  height: 7.9rem;
  color: white;
  cursor: pointer;
  padding-left: 0.2rem;
}

.react-datepicker__navigation--previous:focus {
  border-right-color: #ccc !important;
  outline: none !important;
}

.react-datepicker__navigation--next:focus {
  border-left-color: #ccc !important;
  outline: none !important;
}

.carousel-dtc-code {
  border-radius: 4px;
  margin-left: 0.5rem;
  padding: 0.2rem;
}

.carousel-custom-wrapper {
  display: flex;
  color: white;
  align-items: center;
}

.carousel-description {
  margin-left: 1rem;
  // font-size: 1.5rem;
}

.ant-calendar-date-panel {
  background: #141728;
}

.ant-calendar-input {
  background: transparent !important;
  color: #bdbdbd !important;
}

.ant-calendar-header {
  color: #bdbdbd !important;
}

.ant-calendar-date {
  color: #bdbdbd !important;
  // background: transparent !important;
}

.ant-calendar-disabled-cell .ant-calendar-date {
  color: #bdbdbd !important;
  background: transparent !important;
}

.ant-calendar-column-header-inner {
  color: #bdbdbd !important;
}

.ant-input {
  border: 2px solid #212a54 !important;
}

.btn-container {
  //display: flex;
  margin-left: 10px;

  img {
    zoom: 0.8;
    cursor: pointer;
    height: 44px;
  }
}

.legend-font {
  font-size: 11px;
}

.insight-dot {
  border-radius: 5px;
  width: 10px;
  margin-right: 0.3rem;
  height: 10px;
}

.optimalLi-first,
.optimalLi-second,
.optimalLi-last {
  border-radius: 5px;
  width: 6px;
  margin-right: 0.3rem;
  height: 7px;
}

.optimalLi-first {
  background-color: #19de0c !important;
}

.optimalLi-second {
  background-color: #380ec1 !important;
}

.optimalLi-last {
  background-color: #fe1e1e !important;
}

.legend-insight {
  margin-left: 2rem;
}

.excel-icon-newsite {
  margin-right: 1rem;
  zoom: 0.8;
  padding-left: 1rem;
  cursor: pointer;

  position: relative;
  top: -17px;
}

.h-100 {
  height: 100%;
}

.pdf-icon-newsite {
  zoom: 0.8;
  padding-left: 1.5rem;
  cursor: pointer;

  position: relative;
  top: -17px;
}

.confirmationpop-profile {
  position: absolute;
  // border: 2px solid #212a54;
  color: #bdbdbd;
  bottom: 50px;
  right: 30rem;
  top: 0px;
  // border: 1px solid rgba(0, 0, 0, 0.2);
  @include box-shadow(0 5px 10px rgba(0, 0, 0, 0.2));
  border-radius: 6px;
  @include transition(opacity 0.3s linear);
  text-align: center;
  opacity: 0;
  visibility: hidden;

  .pophdr {
    width: 300px !important;
    padding: rem(8) rem(14);
    margin: 0;
    background: #141728;
    border-left: 1px SOLID #212a54;
    border-right: 1px SOLID #212a54;
    border-top: 1px solid #212a54;
    //  background-color: #f7f7f7;
    // border: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;

    h3 {
      font-size: rem(14);
      text-align: center;
      line-height: 1.1;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      white-space: nowrap;
    }
  }

  .modal-body {
    padding: rem(9) rem(28) rem(25);
    // background: #fff;
    //  border: 1px solid #ebebeb;
    background: #141728;
    border-radius: 0 0 5px 5px;
    border-left: 1px SOLID #212a54;
    border-right: 1px SOLID #212a54;
    border-bottom: 1px SOLID #212a54;

    .delete-btn {
      background-color: #cacaca;
      border: 1px solid #ebebeb;
      background: linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%);
      font-weight: 700;
      padding: rem(6) rem(12);
      margin-right: rem(5);

      &:hover {
        background-color: #cacaca;
        border-color: #cacaca;
      }
    }

    .clear-btn {
      background-color: $primary-color;
      border-color: $primary-color;
      font-weight: 700;
      padding: rem(6) rem(12);

      &:hover {
        background-color: #ec971f;
        border-color: #d58512;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -6px;
    width: 14px;
    height: 14px;
    background: #141728;
    border-top: 2px solid #212a54;
    border-right: 2px solid #212a54;
    border-bottom: 1px solid #212a54;
    @include transform(rotate(313deg));
    border-width: 0 1px 1px 0;
    top: 14px;
    right: -6px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }
}

.delete-btn-profile {
  background: transparent;
  zoom: 0.8;
}

.edit-btn-profile {
  background: transparent;
  zoom: 0.8;
}

.popup_overlay_profile {
  color: #bdbdbd;

  .form-group select {
    background-color: #081c32;
    color: #bdbdbd;
    border: 2px solid #212a54 !important;
  }

  .form-group .inputField {
    background: transparent;
    color: #bdbdbd;
  }

  .form-group .inputField:focus {
    border-color: grey !important;
    color: #bdbdbd;
    outline: 0;
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 10px;
  z-index: 9;
  background: rgba(0, 0, 0, 0.6);

  .pop-up {
    // background: #fff;
    background-color: #081c32;
    color: #bdbdbd;
    width: 100%;
    max-width: 43rem;
    margin: 3rem auto;
    padding: 1rem;
    left: 0;
    right: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border-radius: 6px;

    .popupHeader {
      position: relative;
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      background: transparent;
      padding: rem(11) rem(15);
      border: 0;

      h3 {
        font-size: rem(18);
        font-weight: 700;
        color: #bdbdbd;
        cursor: default;
        line-height: 1.42857143;
      }

      .popupclose {
        svg {
          cursor: pointer;

          path {
            fill: #fff;
          }
        }
      }
    }

    form {
      @include flexbox();
      @include flex-wrap(wrap);
      padding: rem(31) rem(15) 0;

      .leftwrapper,
      .rightwrapper {
        padding: 0 10px;
        width: 50%;
      }

      .footerBtn {
        margin: rem(20) 0 rem(10);
        width: 100%;
        text-align: center;

        button {
          margin-right: 10px;
        }
      }

      .errMsg {
        font-size: 0.75rem;
        font-weight: 700;
      }
    }

    .pop_footer {
      background: $primary-color;
      padding: rem(15);
    }

    &.threecol {
      max-width: 700px;

      section {
        form {

          .leftwrapper,
          .rightwrapper,
          .midwrapper {
            width: 33.3%;
            padding: 0 10px;

            select {
              &[disabled] {
                cursor: not-allowed;
                box-shadow: none;
              }
            }
          }

          .leftwrapperNew,
          .rightwrapperNew,
          .midwrapperNew {
            width: 50%;
            padding: 0 10px;

            select {
              &[disabled] {
                cursor: not-allowed;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

.pagination-profile li {
  margin-right: 3px;
  color: #337ab7;
  font-size: 1rem;
  //background-color: #fff;
  //border: 1px solid #ddd;
  //border-radius: 4px;
  cursor: pointer;
}

.inputField-profile:focus {
  border: 2px solid #a1a1a1;
  border-radius: 4px;
  box-shadow: 0 0 10px #a1a1a1;
}

.inputField-profile {
  background: transparent;
  width: 15rem;
  border: 1px solid #a1a1a1;
  margin-right: 0.6rem;
  border-radius: 5px;
  color: #a1a1a1;
  font-family: "daimler_cs_regular";
  padding: 0.3rem 0.3rem 0.4rem 0.6rem;
  font-size: 16px;
  font-weight: 100;
  background-image: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.create-customer-btn {
  border: none;
  background-color: #303050;
  color: #bdbdbd;
  padding: 0.8rem;
  margin-bottom: 1rem;

  cursor: pointer;
  //  width: 5rem;
  border-radius: 5px;
}

#profile-new-pages {
  .table {
    thead {
      border: 2px solid #20284c;
    }

    th {
      color: #bdbdbd !important;
      border: 2px solid #20284c;
    }

    td {
      color: #a4a4a4 !important;
      border: 2px solid #20284c;
    }

    tbody tr:last-child .confirmationpop-profile {
      top: -59px;
    }

    tbody tr:last-child .confirmationpop-profile {
      &:before {
        top: 72px;
      }
    }
  }

  .table tbody tr:nth-of-type(odd) {
    background-color: #060818;
  }

  .table tbody tr:nth-of-type(even) {
    background-color: #131c34;
  }
}

.header-profile {
  margin: 0.625rem;
  //padding-top: 0.5rem;
  height: 2.5rem;
  color: #616472;
  font-weight: bold;
  align-items: center;
  background-color: #1f243d;
  margin-bottom: 0;
}

.profile-heading-label {
  margin-left: 0.625rem;
  color: #4659b9;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
}

//geofence styles
.GeofencePopUp {
  position: relative;

  .popupclose {
    cursor: pointer;
  }
}

.overlay-geo {
  height: 70%;
  width: 100%;
  bottom: 13rem;
  top: 93px;
  position: absolute;
  left: 0;
  z-index: 9;
  background: #081c32;
  opacity: 0.8;
}

.overlay-geo-new {
  height: 17%;
  width: 100%;
  bottom: 13rem;
  top: 35px;
  position: absolute;
  left: 0;
  z-index: 9;
  background: #081c32;
  opacity: 0.8;
}

.geo-ok-button-img {
  zoom: 0.8;
}

.geo-no-button-img {
  zoom: 0.8;
}

.geo-edit {
  width: 5%;
}

.geo-view {
  width: 5%;
}

.geo-label {
  padding-left: 0.4rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

.geo-select-group {
  font-size: 13px;
  font-style: italic;
  color: #9d9d9d;
}

.geo-select-region {
  font-size: 13px;
  font-style: italic;
  color: #9d9d9d;
}

.geo-select-vehicle {
  font-size: 13px;
  font-style: italic;
  color: #9d9d9d;
}

.geofence-group-drop {
  font-style: italic;
  /* border: 1px solid grey; */
  height: 2.5rem;
  border: 2px solid #212a54;
  border-radius: 4px;
}

.geofence-region-drop {
  font-style: italic;
  /* border: 1px solid grey; */
  height: 2.5rem;
  border: 2px solid #212a54;
  border-radius: 4px;
}

.ant-calendar-input-wrap {
  background: #141728;
}

.ant-calendar-today-btn {
  color: #bdbdbd !important;
}

.geofence-vehicle-drop {
  font-style: italic;
  /* border: 1px solid grey; */
  height: 2.5rem;
  border: 2px solid #212a54;
  border-radius: 4px;
}

.geo-delete {
  width: 5%;
}

.geo-name {
  width: 80%;
}

.down-arrow-geo {
  top: 0.1rem;
  right: 1rem;

  position: relative;
}

.group-geo-input {
  height: 2.5rem;
  cursor: pointer;
}

.sugg-list-geo {
  width: 67%;
  position: absolute;
  top: 5.5rem;
  z-index: 9999;
  overflow: auto;
}

.geo-box {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.2rem;
}

.primary-btn-trip-new {
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black;
}

.primary-btn-trip-new:active {
  border: 1px solid -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid lightpink;
  position: relative;
  bottom: 1.2rem;
  left: 26rem;
}

// .geo-box:nth-of-type(odd) {
//   background-color:#212a54;
//   }

//   .geo-box:nth-of-type(even) {
//   background-color:#081c32;
//   }

.delete-pop-styles {
  position: relative;
}

.signout {
  color: lightgray;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  background: black;
}

.full-height {
  height: 100%;
}

.h-header {
  height: 20%;
}

.bg-color {
  background-color: #141728;
}

.owner-wrapper {
  height: 100vh;
  margin-left: 4.5rem;
  background: #0a0e22;
}

.main-wrapper {
  background: #0a0e22;
}

.home-icon,
.home8-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/Dashboard_grey.png) no-repeat;
  margin: auto;
}

.home8-icon {
  background: url(../images/profile_icon.png) no-repeat;
}

.home2-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  position: relative;
  right: 0.5rem;
  background: url(../images/Truck-trace_grey.png) no-repeat;
  margin: auto;
}

.home2alt-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  position: relative;
  right: 0.5rem;
  background: url(../images/Track-Trace.png) no-repeat;
  margin: auto;
}

.home3-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/Trip_ana.png) no-repeat;
  margin: auto;
}

.home4-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/fleetHealth_grey.png) no-repeat;
  margin: auto;
}

.home5-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/Driving-Ana_grey.png) no-repeat;
  margin: auto;
}

.home5alt-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/Driving-analysis-beta.png) no-repeat;
  margin: auto;
}

.home6-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/settings-new.png) no-repeat;
  margin: auto;
}

.home7-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/report-icons02.png) no-repeat;
  margin: auto;
}

.home7alt-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/Report-beta.png) no-repeat;
  margin: auto;
}

.home9-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/alert-icons02.png) no-repeat;
  margin: auto;
}

.home10-icon {
  height: 53px;
  width: 40px;
  zoom: 0.8;
  position: relative;
  right: 0.5rem;
  background: url(../images/Demo.png) no-repeat;
  margin: auto;
}

.home15-icon {
  height: 53px;
  width: 60px;
  zoom: 0.8;
  background: url(../images/Beta-menu-icon.svg) no-repeat;
  margin: auto;
}

.header-logo {
  // width: 20%;
  height: 32px;
}

.home-text,
.home-text-trip,
.home-text-dashboard {
  color: #4659b9;
  font-weight: bold;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.home-text {
  padding-top: 0.5rem;
}

.home-text-trip {
  margin: 0 !important;
}

.dashboard-select {
  width: 95%;
  background: #0a0e22;
  border: solid #212a54 0.1rem;
  color: #d7dbfd;
  border-radius: 4px;
  padding: 3px 3px 0px 6px;
}

.card-background {
  color: #d7dbfd;
}

.btn-submit {
  background: #1698cb;
  color: #d7dbfd;
  border: none;
  padding: 0.4rem 0.6rem 0.4rem 0.6rem;
  font-weight: bold;
  border-radius: 2px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.menu-search-truck {
  margin-top: 0.2rem;
  width: 100%;
  height: 34px;
  padding: 0.375rem 0.75rem;
  border: none;
  color: #bdbdbd;
  background: #081c32;
  border-radius: 4px;
  background-image: none;
  margin-bottom: 6px;

  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -ms-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.vehilefilter-truck,
.vehilefilter-truck-owner {
  width: 100%;
  height: 190px;
  cursor: pointer;
  overflow: hidden;
  overflow-y: scroll;
  color: #9d9d9d;
  list-style-type: none;
  background: #212a54;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -ms-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem !important;
  top: 2.75rem;
}

.vehilefilter-truck-owner {
  width: 100%;
  height: 190px;
  cursor: pointer;
  overflow: hidden;
  overflow-y: scroll;
  color: #9d9d9d;
  list-style-type: none;
  background: #212a54;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -ms-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem !important;
}

.vehilefilter-truck-owner {
  top: 0rem;
}

// ::-webkit-scrollbar {
//   width: 12px;
// }

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
// }

::placeholder {
  color: #d7dbfd;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d7dbfd;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d7dbfd;
}

.initial-truck {
  // margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.autoWidth {
  width: 95%;
}

.hamberger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  // padding-bottom: 0.5rem;
}

.inputField {
  background-color: #0c96e8;
  border: 1px solid #0c96e8;
  border-radius: 2px;
  color: #fff;
  font-family: "daimler_cs_regular";
  padding: 0.3rem 0.3rem 0.4rem 0.6rem;
  font-size: 16px;
  font-weight: 100;
  background-image: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -ms-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

// .inputField-dash{
//   select option {
//     margin: 40px;
//     background: rgba(0, 0, 0, 0.3);
//     color: #fff;
//     text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
// }
// }

.inputField-dash{
  background-color: #fff;

  select {
    border: solid #212a54 0.1rem;
  }
}
.combineWrapper {
  display: flex;
  padding-right: 1rem;
  align-items: center;
}

#generateReports {
  color: #fff;
  background: #fc8d02;
  border: 1px solid #fc8d02;
  border-radius: 2px;
  padding: 0.3rem 0.3rem 0.4rem 0.6rem;
  font-size: 16px;
  font-weight: 100;
  background-image: none;
  font-family: "daimler_cs_regular";
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -ms-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.dropdownCalendar {
  margin-right: 0.5rem;

  select {
    height: 2.1rem;
    border-radius: 4px;
    padding-left: 0.5rem;
    width: 140px;
    font-family: "daimler_cs_regular";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17.28px;
    border: solid #212a54 0.1rem;
  }

  select option {
    margin: 40px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
}

.ticker {
  color: #0c96e8;
  font-size: 16px;
  padding: 0.5rem;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.footerWrapper {
  display: flex;
  margin: 0.3rem 0rem 0rem 3.5rem;
}

.footerList {
  padding-left: 0px;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 0.91669rem;

  li {
    display: inline-block;
    // color: #656a82;
    margin-right: 1.25rem;
    cursor: pointer;
  }

  li::before {
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 0.8rem;
  }
}

.subWrapper {
  display: flex;
  // color: #656a82;
  font-size: 0.91669rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;

  p {
    margin-bottom: 0rem;
    // color: #656a82;
    margin-left: 1rem;
  }

  span {
    display: inline-block;
    padding: 0px 15px;
  }
}

footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: space-between;
  display: flex !important;
  flex-wrap: wrap;
  padding: 0.3125rem 1.125rem;
  background-color: #141728;
  z-index: 2 !important;
  border: none;

  p {
    svg {
      margin-right: 3px;
    }
  }
}

.firstRow ,.secondRow {
  margin-bottom: 10px;
  .innerRow {
   display: flex;
   margin: 0 0 10px 0;
  }
 
   .card-styles {
     flex-grow: 1;
     height: fit-content;
     padding: 0 10px 0 0;
   }  
 }
 .thirdRow {
   margin-bottom: 10px;
   .innerRow {
    display: flex;
    margin: 0 0 10px 0;
   }
  
    .card-styles {
      flex-grow: 1;
      height: fit-content;
      padding:  0 10px 0 0;
    } 
 }
 
 .rowTitleCard {
   margin: 0 !important;
   .card-title {
     position: static;
     padding: 7px 0 0 10px;
     margin: 0 !important;
     font-weight: bold;
   }
 }
 
 .tripStatusCard {
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 130px;
   .radial-legend {
     position: static;
   }
 }
 .secondRow .card-styles.drivingScoreMain {
   padding: 0 10px 0 0px;
 }
 .drivingScoreCard {
   display: flex;
   height: 130px;
   align-items: center;
   justify-content: space-around;
   .CircularProgressbarC div[data-test-id="CircularProgressbarWithChildren"] > div {
     max-width: 100px;
     max-height: 100px;
   }
 }
 
 .bandChart {
   height: 156px;
   align-items: center;
 }
 
 .trackTraceCard {
   height: 100%;
   padding: 0;
 }
 .trackTrace {
   margin-bottom: 10px;
   padding: 0 10px 0 0 !important;
 }
 
 @media screen and (max-width: 1023px) {
   .dashboardContainer,.firstRow .innerRow, .secondRow .innerRow, .thirdRow .innerRow {
     display: block;
    
   }
   .trackTraceCard {
     padding: 10px;
     
     .map-wrapper {
       height: 200px;
     }
   }
   .trackTrace {
     margin-bottom: 10px;
     padding: 0 10px 0 10px !important;
   }
   .sm-tb-padding {
     padding: 10px 0 !important;
   }
   .secondRow .card-styles.drivingScoreMain {
     padding-top: 10px;
   }
   .thirdRow .card-styles.serviceRemainderDiv  {
     padding-top: 10px;
   }
   .firstRow .card-styles.truckUtil {
     padding-right: 10px;
   }
 }
 

.conentWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: calc(100vh - 10rem);
}

#map-dashboard {
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.level1,
.level2 {
  display: flex;
  justify-content: space-between;
  margin-right: 2.5rem;

  .wrapper {
    display: flex;
    align-items: center;

    img {
      zoom: .6;
      padding: 1.5rem;
    }
  }
  .truckPerImg1{
    img {
      width:50px
    }
  }
  .truckPerImg2{
    img {
      width:45px
    }
  }
}


.text-width,
.text-width-nodata {
  margin-right: 0.1rem;
  font-family: "daimler_cs_regular";
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
  font-weight: normal;
}

.text-width {
  color: #ffffff;
  font-size: 14px;
  width: 4rem;
}

.text-width-nodata {
  font-size: 13px;
  width: 20%;
}

.temp1 {
  display: flex;
  margin-left: 0.5rem;
  padding-right: 1rem;
  width: 100%;
}

.progress-fill {
  border-radius: 1rem;
  margin-top: 4px;
  height: 18px;
  margin-bottom: 1.5rem;
}

.sub-text {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
  padding-left: 0.5rem;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.idling-text {
  color: #8e78ff;
}

.Offline-text {
  color: #ff8081;
}

.running-text {
  color: #0c96e8;
}

.statusBars,
.fuelWrapper {
  height: 140px;
  overflow: hidden;
}

.statusBars {
  padding-left: 1.5rem;
}

.fuelWrapper {
  padding-left: 0.6rem;
}

.hover-bar {
  background-color: #212a54;
  height: 44.5px;
  padding: 13px 6px 0px 5px;
}

.wrapper-icon:hover {
  width: 13rem;
}

.text-hover {
  font-size: 12px;
  color: white;
}

.preventText {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.getMobColor {
  font-size: 0.9em;
  color: #ADB5D7;
}

@media screen and (max-width: 768px) {
  .text-width-nodata {
    width: 10%;
  }

  .dashboard-select {
    padding: 0px !important;
  }
}

@media all and (width: 1024px) {
  .uam-button {
    margin-left: 37rem !important;
  }

  .text-width-nodata {
    width: 25%;
  }

  .CircularProgressbarC {
    width: 22% !important;
  }

  .drivingText {
    margin: auto 0px auto 0.2rem !important;
  }

  .fleet-truck-dashboard {
    padding-left: 12px !important;
    width: 12rem !important;
  }

  .progress-fill {
    height: 16px !important;
  }

  .text-width {
    width: 3rem !important;
    margin-top: 0.2rem !important;
  }
}

.map-wrapper {
  overflow: hidden;
  // padding-bottom: 8.7rem;
  position: relative;
  height: calc(100% - 50px);
  margin: 10px;
}

.CircularProgressbarC {
  width: 13%;
}

.driving-score-total {
  font-size: 2rem;
  font-weight: bold;
  color: #656a82;
}

.driving-wrapper {
  padding: 1rem;
}

.fleet-truck-dashboard {
  height: 5.7rem;

  padding-left: 2.7rem;

  width: 19rem;
  margin-top: 0.5rem;
}

// ticker

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  left: 25px;
  font-size: 36px;
}

.sidepanel {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.6);
  border: 1px solid #3c3e56;
  border-radius: 5px;
  border-right: 0px;
  width: 25rem;
  position: fixed;
  z-index: 1;
  right: 0;
  background-color: rgba(20, 23, 40, 1);
  padding-right: 0.6rem;
  padding-bottom: 2.5rem;
  transition: 0.5s;
  bottom: 0;
  height: 90vh;
}

.panel-card {
  background: #5b566e;
  opacity: 0.6;
  left: -1px;
  position: relative;
  margin-right: 0.5rem;
  // border-left: 4px solid red;
  margin-bottom: 0.5rem;
  padding-left: 0.8rem;
}

.panel-card p {
  color: white;
}

.ticker-heading {
  padding: 1rem;
  font-size: 1.5rem;
  color: #d5d9fa;
}

.ticker-sub-heading {
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 0rem;
  color: #fff;
}

.ticker-desc {
  font-size: 1.125rem;
  color: #fff;
}

.ticker-time {
  color: #6d97bd;
  font-size: 1rem;
}

.notification-underline {
  border-top: 3px solid #0c96e8;
  width: 43%;
  /* margin-right: 1rem; */
  margin-left: 1rem;
}

.panel-card-container {
  height: 90%;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0.2rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 0.2rem;
}

.openbtn {
  width: 10rem;
  right: -4rem;
  position: absolute;
  top: 50%;
  z-index: 1;
  // background: #141728;
  // border-bottom: 0px;
  padding: 0.2rem;
  color: #d7dbfd;
  //  border: 1px solid #0c96e8;
  -ms-transform: rotate(-90deg);
  /* IE 9 */
  -moz-transform: rotate(-90deg);
  /* Firefox */
  -webkit-transform: rotate(-90deg);
  /* Safari and Chrome */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .sidepanel {
    top: 25%;
  }
}

.graph-description {
  border: 1px solid #4659b9;
  border-radius: 51%;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  color: #4659b9;
  font-size: 14px;
}

.d-pl {
  padding-left: 2.5rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerWrapperNotLogged {
    margin: 0;
    display: flex;
  }

  .driving-analysis-chart {
    height: 150px;
  }

  .group-drop-fmt-search-coll {
    top: 3.5rem !important;
    width: 16.5rem !important;
  }

  .dr_img {
    height: 32px;
    zoom: unset !important;
    width: 21px;
  }

  .fuelWrapper {
    padding-left: 0rem;
  }

  .level2 {
    margin-right: 1.5rem;
  }

  .pie-chart-container {
    width: 0 !important;
    margin-left: 0rem !important;
    left: -12rem !important;
    zoom: 0.8;
    position: relative !important;
    background: transparent !important;
  }

  .test-class {
    width: 10rem !important;
  }
}

@media screen and (-ms-high-contracst: active),
(-ms-high-contrast: none) {
  .ie-styles-heading {
    width: 85%;
  }

  .CircularProgressbarC {
    margin-top: -2rem;
  }

  .ie-styles-heading-driving {
    width: 93%;
  }

  .ie-driver-padding {
    padding-bottom: 0rem !important;
  }

  .graph-description {
    padding: 0.2rem 0.4rem 0rem;
  }

  .level1,
  .level2 {
    display: flex;
    justify-content: space-between;
    margin-right: 2.5rem;

    .wrapper {
      display: flex;
      align-items: center;

      img {
        zoom: 0.6;
        padding: 0.5rem;
      }
    }
  }
}

.insightDiv {
  margin-bottom: 0px;
}

.customLi {
  font-size: 10px;

  ul li:before {
    content: "•";
    font-size: 1.2rem;
    /* or whatever */
    padding-right: 5px;
    // color:#e60000
  }

  ul li:first-child:before {
    content: "•";
    color: #5ec957;
  }

  ul li:last-child:before {
    content: "•";
    color: #ff8081;
  }

  ul li:nth-child(2):before {
    content: "•";
    color: #8e78ff;
  }
}

.zoom-radial {
  zoom: 0.378;
}

.optimal-new,
.recc-new,
.normal-new {
  border-radius: 5px;
  width: 10px;
  margin-right: 0.3rem;
  height: 10px;
}

.optimal-new {
  background: #19de0c !important;
}

.recc-new {
  background: #fe1e1e !important;
}

.normal-new {
  background: #380ec1 !important;
}

.customLis-new {
  align-items: baseline;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: #fff;
}

.reportFleet {
  display: flex;
  align-items: center;
  justify-content: center;
  .pdf-icon-newsite, .excel-icon-newsite {
    padding-top: 0;
    position: static;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .subWrapper {
    p {
      font-size: 0.6rem !important;
    }
  }

  .footerList {
    font-size: 0.7rem !important;
  }

  .conentWrapper {
    height: calc(100vh - 12rem);
  }

  .paginationScroller {
    height: calc(100vh - 9.5rem) !important;
    overflow-y: auto;
  }

  .fleet-truck-dashboard {
    width: 33rem;
    padding-left: 8.7rem;
  }

  .main-wrapper {
    .serviceInfoRemainder {
      top: 37rem;
      left: 4rem;
      width: 90%;
    }
  }

  .reports {
    .reportFilters {
      .report-filter-wrapper-new {
        display: block;
      }
    }
  }

  .setting-right-panel {
    .setting-inner-right-panel {
      .accordionContainer {
        .setAlerts {
          margin-left: 1rem;
        }

        .accordionContent {
          .resetpwd {
            .wrapper-password {
              top: 11%;
            }
          }

          .refresh-heading,
          .refresh-input {
            left: 32%;
            top: 9%;
          }
        }

        .rightcol {
          .accordionContent {
            .tableContainer {
              .scroll-group {
                height: calc(100vh - 26rem);
                font-size: 11px;

                .group-name-setting {
                  .newPopUp {
                    //transform: translate(153px, -100px);
                    right: 3rem;
                  }

                  .newPopUp::before {
                    top: 8px;
                    right: -6px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .accordionContainer {
    .setAlerts {
      margin-left: 1rem !important;

      &~.form-group {
        padding-top: 0px;
      }
    }
  }

  .header-profile {
    font-size: 12px;
  }

  #alertReport-new {
    .navTab {
      margin-left: 0rem !important;
      width: 100% !important;

      ul li {
        font-size: 12px;
        padding: 0.2rem 0.2rem !important;
      }
    }
  }

  // .newReportsContainerScreen{
  //   .form-group{
  //     width: 33.3%;
  //   }
  // }

  .vehicleSummaryProfile th {
    padding: 0.2rem !important;
  }

  .create-customer-btn {
    width: 24% !important;
    margin-right: 0.6rem !important;
    font-size: 16px;
  }

  .remainingWidth {
    width: 76%;
  }

  .inputField-profile {
    width: 9rem !important;
  }

  //your styles here
  .no-dtc-health {
    padding: 2rem !important;
  }

  .card-container-health {
    height: calc(100vh - 17rem) !important;
  }

  .liveNotification-image {
    right: 0rem !important;
  }

  .no-data-body-fleet {
    padding-left: 11rem !important;
  }

  .each-card-width {
    width: 47% !important;
  }

  .fleetVehicles {
    width: 20%;

    .dropdown {
      width: 8rem !important;
    }
  }

  .serviceText {
    font-size: 14px;
  }

  .fleetButtons {
    width: 20%;
    padding-left: 1rem;

    .health-btn {
      box-shadow: none !important;
      outline: none !important;
      font-size: 10px;
      width: 3rem;
    }
  }

  .button:focus {
    box-shadow: none !important;
  }

  .reportFleet {
    width: 20%;

    .excel-icon-newsite {
      margin-right: 0rem;
    }
  }

  .fleetTo,
  .fleetFrom {
    width: 20%;

    .date-fleet-health {
      .react-datepicker-wrapper {
        padding-left: 0.1rem !important;

        .react-datepicker__input-container {
          font-size: 13px !important;
        }
      }

      .date-label {
        font-size: 14px;
        padding-top: 0.3rem !important;
      }
    }
  }

  // .fleetFrom {
  //   width: 20%;
  //   .date-fleet-health {
  //     .react-datepicker-wrapper {
  //       padding-left: 0.1rem !important;
  //       .react-datepicker__input-container {
  //         font-size: 13px !important;
  //       }
  //     }
  //     .date-label {
  //       font-size: 14px;
  //       padding-top: 0.3rem !important;
  //     }
  //   }
  // }

  .imm-text {
    padding-top: 2rem !important;
    padding-left: 0.5rem !important;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size: 12px !important;
  }

  .screw-img {
    padding-right: 1.5rem !important;
    padding-top: 0.8rem !important;
    height: 2.5rem !important;
    margin-top: 20px !important;
    padding-left: 0.5rem !important;
  }

  .imm-img {
    width: 25%;
    border-top-right-radius: 25px;
    height: 5.8rem !important;
    background-color: #495583;
    border-bottom-right-radius: 25px;
  }

  .owner-wrapper-analysis {
    height: calc(100vh - 21rem);
    overflow-y: auto;

    .date-container-driver {
      .date-fleet {
        width: 50%;
      }
    }

    .btn-container {
      margin-left: 0px;
    }
  }

  .filter-container-analysis {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main-wrapper {
    .serviceInfoRemainder {
      top: 21rem;
      left: 12rem;
      width: 90%;
    }
  }

  .conentWrapper {
    height: calc(100vh - 10rem);
  }

  .legend-font {
    font-size: 10px !important;
  }

  .legend-insight {
    margin-left: 1rem !important;
  }

  .owner-wrapper-analysis {
    height: calc(100vh - 3rem) !important;
  }

  .inputField-profile {
    width: 15rem !important;
  }

  .wrapper-password {
    top: 13% !important;
  }

  .relative-speed-class {
    position: relative;
    top: 1rem;
  }

  .relative-rpm-class {
    position: relative;
    top: 1rem;
  }

  .fleetVehicles {
    .dropdown {
      width: 11rem !important;
    }
  }

  .each-card-width {
    width: 23% !important;
  }

  .newPopUp {
    right: 4.5rem !important;
    // transform: translate(230px, -100px) !important;
  }

  .newPopUp::before {
    right: -7px !important;
  }

  .d-pl-ipad {
    padding-left: 1.5rem;
  }

  .speed-height {
    height: 10.9rem !important;
  }

  .insight-height {
    height: 12.4rem !important;
  }

  .service-height {
    height: 12.4rem !important;
  }

  .radial-legend {
    position: relative !important;
    top: 1rem !important;
  }

  .radial-zoom {
    zoom: 0.79 !important;
    bottom: 0.5rem !important;
    left: 0rem !important;
    padding-bottom: 1.6rem;
  }

  .customLi {
    margin-left: 0;
    width: 30% !important;
    // padding-left: 1.5rem !important;
  }
}

.radial-zoom {
  zoom: 0.57;
  /* padding-bottom: 0px; */
  bottom: 1rem;
  left: 6rem;
}

.radial-ipad-font {
  font-size: 10px;
  padding-left: 1rem;
}

.radial-legend {
  position: relative;
  top: 1.5rem;
}

.date-container-driver {
  width: 27rem !important;
}

@media screen and (max-width: 768px) {
  .openbtn {
    padding: 0.1rem;
  }

  .customLi {
    margin-left: -37px;
  }
}

.speed-height {
  height: 9.3rem;
}

.trip-height {
  height: 11.5rem;
}

.service-height {
  height: 11.8rem;
}

.insight-height {
  height: 11.8rem;
}

// @media only screen
// and (min-width : 1230px)
// and (max-width : 1280px){
// .conentWrapper{
//   padding-bottom: 164px;
// }

// .insight-height{
//   height: 12.4rem;
// }
// .service-height{
//   height: 12.4rem;
// }
// }

// @media only screen
// and (min-width : 1440px)
// and (max-width : 1500px){
//   .conentWrapper{
//     padding-bottom: 164px;
//   }
//   .radial-zoom{
//     left:0px;
//   }
//   .insight-height{
//     height: 12.5rem;
//   }
//   .service-height{
//     height: 12.5rem;
//   }
// }

.card-title {
  position: relative;
  top: 3px;
}

.equal-padding {
  padding-right: 10px !important;
}

.map-sidebar {
  background-color: #081c32;
  border: 1px solid #081c32;
  border-radius: 10px;
}

.vehicle-scroll {
  overflow: hidden;
  overflow-y: scroll;
}

.map-panel {
  border: 1px solid white;
  position: relative;
}

.big-map,
.small-map {
  margin-right: 1.5rem;
  border-radius: 10px;
  border: 0.5px solid #141728;
}

.big-map {
  width: 82%;
  position: relative;
}

.small-map {
  width: 67%;
}

.panel-height {
  height: 85vh;
}

#map1 {
  height: 100%;
  border-radius: 10px;
}

#autosuggest {
  position: absolute;
  top: 5rem;
  left: 16rem;
  z-index: 100;
}

#wrapper {
  position: relative;
  height: 100%;
}

#mapw3w {
  height: 100%;
  width: 100%;

  .gmnoprint {
    display: block;
    zoom: 0.8;
  }

  .gm-style-mtc {
    opacity: 0;
  }
}

.expnd {
  width: 30%;
}

.collp {
  width: 15%;
}

.search {
  position: relative;
  margin: 0 auto;
  padding-left: 0.5rem;

  .fa-search {
    position: absolute;
    top: 1rem;
    left: 84%;
    font-size: 15px;
  }
}

.search input {
  text-indent: 30px;
  font-family: inherit;
}

input {
  font-family: inherit !important;
}

.fa-search {
  // position: absolute;
  top: 1.6rem;
  color: white;
  left: 78%;
  font-size: 15px;
}

#inputId {
  width: 12.5rem;
  text-indent: 8px;
}

.wrapper-search {
  position: relative;
  left: 32%;
}

.inside-panel {
  height: 8rem;
  color: white;
  cursor: pointer;
}

.w-40 {
  width: 40%;
}

.h-20 {
  height: 20px;
}

.expleftSidePanel {
  background-color: #141728;
  margin-left: 0.6rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 2%;
  font-size: 0.85rem;
  // height: 4rem;
  color: white;
  padding: 5px;
}

.leftSidePanel {
  background-color: #141728;
  margin-left: 0.5rem;
  margin-bottom: 0.7rem;
  margin-right: 0.5rem;
  border-radius: 5%;
  height: 5rem;
  color: white;
  padding-left: 0.2rem;
  cursor: pointer;
}

.RUNNING {
  border-left: 0.5rem solid #37d737;
}

.OFFLINE {
  border-left: 0.5rem solid #f72424;
}

.IDLE {
  border-left: 0.5rem solid #ffae00;
}

.truck-heading,
.truck-heading-live {
  color: #73a3cb;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.truck-heading {
  font-size: 1.4rem;
}

.truck-heading-live {
  font-size: 1.35rem;
}

.geofence-button,
.geofence-button-disabled {
  background-color: #081c32;
  border: 1px solid #18416a;
  border-radius: 0.2rem;
  color: white;
  font-size: 0.8rem;
}

.geofence-button-disabled {
  cursor: not-allowed !important;
}

.show-button {
  background-color: #081c32;
  border: 1px solid #18416a;
  height: 2.1875rem;
  margin-right: 0.3rem;
  border-radius: 0.2rem;
  color: white;
  padding: 5px;
  font-size: 0.4rem;
}

.search-input {
  color: white;
  text-indent: 8px;
  background-color: #141728;
  border: solid 1px #141728;
  border-radius: 0.3rem;
  height: 2.5rem;

  input {
    font-family: inherit !important;
  }
}

.status-panel {
  border: solid 1px #18416a;
  width: 8rem;
  font-size: 0.8rem;
  //padding: 5px;
  background-color: #081c32;
  border-radius: 0.2rem;
  color: white;

  img {
    vertical-align: middle;
    border-style: none;
  }
}

.filter-panel {
  background-color: #141728;
  color: white;
  margin: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 14px;
  padding-top: 0.5rem;
}

.refresh,
.refreshLive {
  font-size: 25;
  margin-top: 5px;
  cursor: pointer;
}

.refresh {
  margin-right: 10px;
}

.refreshLive {
  margin-right: 5px;
}

.search-icon-fmt {
  position: relative !important;
  top: -2rem !important;
  color: #73a3cb !important;
}

::-webkit-input-placeholder {
  /* Edge */
  font-size: 13px !important;
  color: #bdbdbd;
  font-weight: normal !important;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-size: 13px !important;
  color: #bdbdbd;
  font-weight: normal !important;
}

::placeholder {
  color: #bdbdbd;
  font-weight: normal !important;
  font-size: rem(13);
}

// .search-input::placeholder {
//   color: lightgrey;
//   opacity: 20%;
//   font-style: italic;

// }

// .search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
//  color: lightgrey;
//  opacity: 20%;
//  font-style: italic;
// }

// .search-input::-ms-input-placeholder { /* Microsoft Edge */
//  color: lightgrey;
//  opacity: 20%;
//  font-style: italic;
// }

.fmt-scroll::-webkit-scrollbar-track {
  background: #0d385a;
}

.fmt-scroll::-webkit-scrollbar-thumb {
  background-color: #0d385a;
}

.truckImage-fmt {
  // height: 10px;
  width: 25px;
  padding-right: 0.3rem;
}

.truckImage-fmt-coll {
  padding-right: 0.3rem;
  width: 25px;
}

.dateImage-fmt-coll {
  padding-right: 1.4rem;
}

.dateImage-fmt {
  // height: 12px;
  width: 16px;
  padding-right: 0.3rem;
}

.timeImage-fmt {
  width: 17px;
  padding-right: 0.3rem;
}

.locatonImg {
  height: 12px;
  width: 6%;
}

.alignCenter {
  align-items: center;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  //width: 39%;
}

.div-reg {
  width: 45%;
}

.div-date {
  width: 25%;
}

.div-time {
  width: 27%;
}

.div-location {
  width: 46%;
}

.div-driver {
  width: 25%;
}

.bg {
  background: url("../images/truck-new.png");
  background-repeat: no-repeat;
  // height: 30px;
  padding-left: 30px;
  background-position: left top;
}

.bg-location {
  background: url("../images/location-new.png");
  background-repeat: no-repeat;
  // height: 30px;
  padding-left: 30px;
  background-position: left top;
}

.bg-driver {
  background: url("../images/driver-new.png");
  background-repeat: no-repeat;
  // height: 30px;
  padding-left: 30px;
  background-position: left top;
}

.reg-width {
  width: 80%;
  white-space: nowrap;
  font-size: 15px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.date-width {
  width: 85%;
  white-space: nowrap;
  font-size: 15px;
}

.driverImg {
  width: 15px;
  height: 12px;
  padding-right: 0.3rem;
}

.reg-width1 {
  // cursor: pointer;
  // width: 80%;
  // white-space: nowrap;
  //margin: auto
}

.cursor {
  cursor: pointer;
}

.date-width1 {
  //width: 85%;
  // white-space: nowrap;
  // margin: auto
}

.filter-fmt {
  color: #73a3cb;
  font-size: 1.125rem;
}

.fmt-filter-icon {
  padding-top: 0.1rem;
  color: #73a3cb;
}

.vehicleList {
  background-color: #73a3cb;
  position: absolute;
  left: 23%;
  z-index: 1;
  width: auto;
  max-height: 250px;
  overflow: auto;
  padding: 5px;

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 2px solid #fff;
    background-color: #eee;
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 15px;
    font-weight: 700;
    color: #bdbdbd;
    width: 100%;

    input[type="checkbox"] {
      -webkit-appearance: none;
      position: absolute;
      width: 18px;
      background-repeat: no-repeat;
      height: 18px;
      background: url("../images/tick-mark02.png");
    }

    input[type="checkbox"]:checked {
      background: url("../images/tick-mark01.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    // input:checked ~ .checkmark {
    //   background: #00677f;
    // }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }
}

.region-drop-fmt {
  input[type="checkbox"] {
    -webkit-appearance: none;
    position: absolute;
    width: 18px;
    background-repeat: no-repeat;
    height: 18px;
    background: url("../images/filter-tick-disable.png");
  }

  input[type="checkbox"]:checked {
    background: url("../images/filter-tick-enable.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.5rem;
  background-color: #dee5ec;
  z-index: 1;
  width: 8rem;
  word-break: break-all;
  height: 11rem;
  overflow-y: scroll;

  // max-height: 250px;
  // overflow: auto;
  // width: 7%;
  // position: absolute;
  // left: 14rem;
  // background-color: #73a3cb;

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    margin: 0;

    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }

    li:nth-child(odd) {
      background: #c1d4dc;
      border-radius: 0.4rem;
    }

    li:nth-child(even) {
      background: #a7bfc9;
      border-radius: 0.4rem;
    }
  }

  li {
    padding: 1px 10px;
    padding-top: 0.5rem;
    //   margin: 3px 0;
    margin-bottom: 2px;
    border: none;
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #051d35;
    width: 100%;

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 0;
      width: 0;

      top: 3px;
      left: 0;
    }
  }
}

.overspeed-drop-fmt {
  border-radius: 0.7rem;
  position: absolute;
  background-color: #dee5ec;
  right: 0;
  z-index: 1;
  width: 8rem;

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    margin: 0;

    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }

    li:nth-child(odd) {
      background: #c1d4dc;
      border-radius: 0.4rem;
    }

    li:nth-child(even) {
      background: #a7bfc9;
      border-radius: 0.4rem;
    }
  }

  li {
    padding: 1px 10px;
    padding-top: 0.5rem;
    //  margin: 3px 0;
    margin-bottom: 2px;
    border: none;
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 11px;
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: #051d35;
    width: 100%;

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 0;
      width: 0;

      top: 3px;
      left: 0;
    }
  }
}

.group-drop-fmt {
  position: absolute;
  left: 1.8rem;
  border-radius: 0.5rem;
  background-color: #dee5ec;
  z-index: 1;
  width: 8rem;
  word-break: break-all;
  height: 11rem;

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    margin: 0;

    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }

    li:nth-child(odd) {
      background: #c1d4dc;
      border-radius: 0.4rem;
    }

    li:nth-child(even) {
      background: #a7bfc9;
      border-radius: 0.4rem;
    }
  }

  li {
    padding: 1px 10px;
    padding-top: 0.5rem;
    // margin: 3px 0;
    margin-bottom: 2px;
    border: none;
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 11px;
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: #051d35;
    width: 100%;

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 0;
      width: 0;

      top: 3px;
      left: 0;
    }
  }

  // position: absolute;
  //   background-color: #73a3cb;
  //   width: 7rem;
  //   left: 20rem;
}

.search-scroll-div {
  max-height: 212px;
  overflow: auto;
  margin-top: 10px;
  background-color: #141728;

  border-radius: 0.2rem;
}

.search-scroll-div-coll {
  max-height: 200px;
  overflow: auto;
  margin-top: 10px;
  background-color: #141728;

  border-radius: 0.2rem;
}

.group-drop-fmt-search-coll {
  input[type="checkbox"] {
    -webkit-appearance: none;
    position: absolute;
    width: 18px;
    background-repeat: no-repeat;
    height: 18px;
    background: url("../images/filter-tick-disable.png");
  }

  input[type="checkbox"]:checked {
    background: url("../images/filter-tick-enable.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  border: 1px solid #18416a;
  position: absolute;
  left: 0.5rem;
  top: 2.7rem;
  background-color: #141728;
  z-index: 1;
  width: 12.5rem;
  // max-height: 250px;
  // overflow: auto;

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }
  }

  li {
    padding: 1px 10px;
    margin: 3px 0;
    border: none;
  }

  .dropdown-container {
    display: block;
    position: relative;
    margin-bottom: 0.5rem;
    padding-left: 28px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 0.75rem;
    font-weight: 700;
    color: #bdbdbd;
    width: 100%;

    input[type="checkbox"] {
      background: url("../images/tick-mark02.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input[type="checkbox"]:checked {
      background: url("../images/tick-mark01.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 14px;
      width: 14px;
      top: 5px;
      left: 3px;
    }
  }
}

.group-drop-fmt-search {
  input[type="checkbox"] {
    -webkit-appearance: none;
    position: absolute;
    width: 18px;
    background-repeat: no-repeat;
    height: 18px;
    background: url("../images/filter-tick-disable.png");
  }

  input[type="checkbox"]:checked {
    background: url("../images/filter-tick-enable.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  border: 1px solid #18416a;
  position: absolute;
  background-color: #141728;
  z-index: 1;
  width: calc(100% - 1rem);

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }
  }

  li {
    padding: 1px 10px;
    margin: 3px 0;
    border: none;
  }

  .dropdown-container {
    display: block;
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 27px;
    cursor: pointer;
    font-size: 0.75rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-weight: 700;
    color: #bdbdbd;
    width: 100%;

    input[type="checkbox"] {
      background: url("../images/tick-mark02.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input[type="checkbox"]:checked {
      background: url("../images/tick-mark01.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 14px;
      width: 14px;
      top: 5px;
      left: 3px;
    }
  }
}

.search-btn-fmt {
  border-radius: 5px;
  border: none;
  background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
  //background-color: #cacaca;
  //background: linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%);
  color: #000000;
  font-weight: bold;
  font-size: 14px;
  font-family: "daimler_cs_regular";
  margin-bottom: 0.5rem;
  cursor: pointer;
  width: 4rem;
}

.liveNotification-image {
  width: 10rem;
  right: -1rem;
  position: absolute;
  top: 23%;
  zoom: 0.4;
  z-index: 1;
  padding: 0.2rem;
  cursor: pointer;
  display: none !important;
}

.each-search-tile {
  padding: 0.5px 8px;
  margin: 3px 0;
  border: none;
}

.region-drop-dash {
  // position: absolute;
  left: 11rem;
  // border-radius: 0.5rem;
  top: 12.5rem;
  background: #212a54;
  z-index: 1;
  width: 100%;
  word-break: break-all;
  height: 11rem;
  overflow-y: scroll;

  input[type="checkbox"] {
    -webkit-appearance: none;
    position: absolute;
    width: 18px;
    background-repeat: no-repeat;
    height: 18px;
    background: url("../images/tick-mark02.png");
  }

  input[type="checkbox"]:checked {
    background: url("../images/tick-mark01.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    margin: 0;

    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }

    // li:nth-child(odd) { background: #c1d4dc;   border-radius: 0.4rem; }
    // li:nth-child(even) { background: #a7bfc9;   border-radius: 0.4rem; }
  }

  li {
    padding: 1px 10px;
    //   margin: 3px 0;
    margin-bottom: 2px;
    border: none;
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #9d9d9d;
    width: 100%;

    input[type="checkbox"] {
      background: url("../images/tick-mark02.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input[type="checkbox"]:checked {
      background: url("../images/tick-mark01.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 0;
      width: 0;

      top: 3px;
      left: 0;
    }
  }
}

.group-drop-dash {
  left: 17.2rem;
  // border-radius: 0.5rem;
  top: 12.5rem;
  background: #212a54;
  z-index: 1;
  width: 100%;
  word-break: break-all;
  height: 10.2rem;
  overflow-y: scroll;

  input[type="checkbox"] {
    -webkit-appearance: none;
    position: absolute;
    width: 18px;
    background-repeat: no-repeat;
    height: 18px;
    background: url("../images/tick-mark02.png");
  }

  input[type="checkbox"]:checked {
    background: url("../images/tick-mark01.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    margin: 0;

    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }

    // li:nth-child(odd) { background: #c1d4dc;   }
    // li:nth-child(even) { background: #a7bfc9;   }
  }

  li {
    padding: 1px 10px;
    // margin: 3px 0;
    margin-bottom: 2px;
    border: none;
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: #9d9d9d;
    width: 100%;

    input[type="checkbox"] {
      background: url("../images/tick-mark02.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input[type="checkbox"]:checked {
      background: url("../images/tick-mark01.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 0;
      width: 0;

      top: 3px;
      left: 0;
    }
  }
}

.company-drop-dash {
  input[type="checkbox"] {
    -webkit-appearance: none;
    position: absolute;
    width: 18px;
    background-repeat: no-repeat;
    height: 18px;
    background: url("../images/tick-mark02.png");
  }

  input[type="checkbox"]:checked {
    background: url("../images/tick-mark01.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  left: 23.5rem;
  top: 12.5rem;
  background: #212a54;
  z-index: 1;
  width: 100%;
  word-break: break-all;

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }
  }

  li {
    padding: 1px 10px;
    margin-bottom: 2px;
    //margin: 3px 0;
    border: none;
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0.5rem;
    font-size: 11px;
    font-weight: 700;
    color: #9d9d9d;
    width: 100%;

    input[type="checkbox"] {
      background: url("../images/tick-mark02.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input[type="checkbox"]:checked {
      background: url("../images/tick-mark01.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 0;
      width: 0;

      top: 3px;
      left: 0;
    }
  }
}

.company-drop-fmt {
  input[type="checkbox"] {
    -webkit-appearance: none;
    position: absolute;
    width: 18px;
    background-repeat: no-repeat;
    height: 18px;
    background: url("../images/filter-tick-disable.png");
  }

  input[type="checkbox"]:checked {
    background: url("../images/filter-tick-enable.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  position: absolute;

  border-radius: 0.5rem;
  left: 5.6rem;
  top: 12.5rem;
  background-color: #dee7ec;
  z-index: 1;
  width: 8rem;
  word-break: break-all;
  // max-height: 250px;
  // overflow: auto;

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
  }

  ul {
    margin-bottom: 0;

    input {
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      font-size: 13px;
      display: block;
    }

    li:first-child {}

    li:nth-child(odd) {
      background: #c1d4dc;
      border-radius: 0.4rem;
    }

    li:nth-child(even) {
      background: #a7bfc9;
      border-radius: 0.4rem;
    }
  }

  li {
    padding: 1px 10px;
    padding-top: 0.5rem;
    // margin: 3px 0;
    margin-bottom: 2px;
    border: none;
  }

  .dropdown-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0.5rem;
    font-size: 11px;
    font-weight: 700;
    color: #051d35;
    width: 100%;

    input[type="checkbox"] {
      background: url("../images/tick-mark02.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input[type="checkbox"]:checked {
      background: url("../images/tick-mark01.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    // input:checked ~ .checkmark {
    // }

    input {
      position: absolute;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 0;
      width: 0;

      top: 3px;
      left: 0;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.fmt-apply-btn {
  background-color: #141728;
  height: 1.5rem;
  width: 4rem;
  color: wheat;
  font-size: 14px;
  border: 1px solid #141728;
}

.fmt-apply-btn-active {
  background-color: #081c32;
  border-radius: 5px;
  border: 1px solid #18416a;
  height: 1.5rem;
  width: 4rem;
  color: wheat;
  font-size: 14px;
}

.fmt-clear-btn {
  background-color: #141728;
  height: 1.5rem;
  width: 4rem;
  color: lightgrey;
  font-size: 14px;
  margin-right: 0.8rem;
  border-radius: 5px;
  border: 1px solid #141728;
}

.fmt-clear-btn-active {
  background-color: #081c32;
  border: 1px solid #18416a;
  height: 1.5rem;
  width: 4rem;
  color: wheat;
  font-size: 14px;
  margin-right: 0.8rem;
  border-radius: 5px;
}

.fmt-btn-panel {
  position: relative;
  bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
}

.close-icon-fmt {
  height: 9px;
  margin-right: 5px;
  width: 8px;
  margin-bottom: 1px;
}

.create-geo-img {
  height: 17px;
  width: 15px;
  vertical-align: middle !important;
}

.geo-img {
  height: 17px;
  width: 15px;
  vertical-align: middle !important;
}

img {
  vertical-align: middle !important;
}

.fmt-trip-label {
  background-color: green;
  border-radius: 10px;
  text-align: center;
  margin-right: 1rem;
  height: 1.5rem;
  width: 100%;
  padding: 0.3rem;
  color: white;
}

.fmt-trip-label-not {
  background-color: red;
  border-radius: 10px;
  text-align: center;
  margin-right: 1rem;
  height: 1.5rem;
  width: 100%;
  padding: 0.3rem;
  color: white;
}

.play-label-fmt {
  color: black;
  font-weight: bold;
  margin-top: 0.3rem;
}

//trip analysis

.tableWrapperAnalysys table tbody tr td {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trip-analysis {
  padding: 0 0.75rem 1.25rem;
  .home-text-theme {
    margin: 0;
  }
  .panel_container {
    .tableWrapper {
      .search-bar {
        max-width: 42%;
        margin-bottom: 15px;
      }
    }
  }
}

#infoclose {
  cursor: pointer;
}

.mapPopup {
  .close-popup {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: rgb(45, 187, 235);
    border-radius: 61%;
    width: 2rem;
    /* margin: auto; */
    /* align-content: center; */
    padding: 0.3rem 0.7rem;
    /* padding-left: 0.7rem; */
    height: 2rem;
  }

  .action-icons {
    text-align: right;
    position: absolute;
    z-index: 1;

    i {
      background-color: #00677f;
      border: solid 1px #004355;
      padding: 10px 14px;
      border-radius: 3px;
      margin: 10px;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #004355;
        border-color: #00677f;
      }
    }
  }

  .mapbody {
    min-height: 350px;
    padding: 15px;
    width: 80%;
  }
}

.graph-container {
  display: flex;
  width: 100%;
  margin-bottom: 0.625rem;
}

.graph-sub-container {
  // border: solid gray;
  width: 20%;
  margin-right: 1rem;
  padding-top: 1.5rem;
  border-right: solid grey;
  border-bottom: solid grey;
  border-top: solid grey;
}

.chart-flex {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.progress-bar {
  margin-bottom: 1rem;
}

.graph-sub-container1>p {
  margin-bottom: 0.95rem;
}

.graph-sub-container1 {
  width: 12%;
  padding-top: 1.5rem;
  border-left: solid grey;
  border-bottom: solid grey;
  border-top: solid grey;
}

.react-sweet-progress-line {
  background-color: white !important;
}

.react-sweet-progress-line-inner-status-active:before {
  animation: none !important;
}

.graph-sub-container2 {
  width: 5%;
  padding-top: 1rem;
}

.apexcharts-legend-marker {
  background: none !important;
}

.graph-sub-container3 {
  width: 29.9%;
}

.graph-sub-container-radial {
  width: 20%;
  margin-right: 1rem;
  zoom: 0.8;
  border: solid grey;
}

.bar-graph {
  margin-bottom: 1rem;
  width: 0.2rem;

  // display: flex;
  align-items: center;
  // justify-content: center;
  // width: 100%;
}

.progress-fill {
  border-radius: 1rem;
  margin-top: 7px;
  height: 8px;
  margin-bottom: 1.5rem;
}

.bar-graph-padding {
  // padding-bottom: 0.5rem;
}

// dummy----------------------------

.each-width {
  font-size: 14px;
  background-color: #141728;
  width: 21.5%;
  // border: solid 0.1rem lightgrey;
  border-radius: 1rem;
  padding: 1rem;
  padding-bottom: 0rem;
  // height: fit-content;
}

.d-flex {
  display: flex;
}

.text-width {
  width: 100%;
  margin-right: 0.1rem;
  //  font-size: 1rem;
}

.progress-fill {
  // width: 59%;
}

.temp1 {
  display: flex;
  width: 100%;
}

.sub-text {
  display: flex;
  justify-content: flex-end;
  padding-left: 0.5rem;
}

.graph-zoom {
  zoom: 0.7;
  margin-left: -41px;
}

.each-width1 {
  width: 34%;
  // padding: 1rem;
  height: 10%;
  // border: solid 0.1rem lightgrey;
  // border-radius: 1rem;
  border-radius: 1rem;
}

.graph-heading {
  margin-bottom: 0.8rem;
  font-size: 1rem;
  color: #d7dbfd;
  line-height: 1.37rem;
  font-weight: bold;
}

.radialNumber {
  font-weight: bold;
}

.padding-radial {
  color: white;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.panelHeading-trip {
  align-items: center;
  height: 3rem;
  background: #081c32;
  // padding: 0.25rem 0.9375rem;
  //  min-height: 38px;
}

.trip-tab>p {
  padding-right: 1.5rem;
  padding: 0.7rem;
  color: white;
}

.tableWrapperAnalysys {
  .table thead tr th {
    color: white;
  }
}

.trip-tab {
  width: 6rem;
  text-align: center;
  height: 100%;
  //  min-height: 38px;
  cursor: pointer;
}

.radial-heading {
  font-size: 14px;
  width: 50%;
  color: #ffffff;
}

.create-trip-element {
  width: 34%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.pb-1 {
  padding-bottom: 1rem;
}

.create-btn {
  border: none;
  padding: 0.7rem;
  color: white;
  background: #00677f;
  border-radius: 4px;
}

.trip-description {
  text-align: center;
}

.trip-description>p:first-of-type {
  font-weight: bold;
}

// custom map------------------------------------

#map-daily {
  height: 30.4vh;
  border-radius: 1rem;
}

@media (min-width: 1025px) {
  .d-flex-responsive {
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .panel-height {
    height: 89%;
  }

  .radial-heading {
    width: 45%;
  }

  .chart-zoom {
    zoom: 0.5;
  }

  .each-width {
    height: 17rem;
  }

  .each-width1 {
    height: 17rem;
  }

  #map-daily {
    height: 26.4vh;
  }

  .createTrip {
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    right: 0px;
    top: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .radial-heading {
    width: 33%;
  }

  .newPlayBackDiv {
    display: block !important;
  }

  .each-width {
    height: 17rem;
  }

  .each-width1 {
    height: 14rem;
  }

  #map-daily {
    height: 34.7vh;
  }

  .chart-zoom {
    zoom: 0.46;
  }

  .createTrip {
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    right: 0px;
    top: 15px;
  }
}

//create trip

.createTrip {
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  right: 0px;
  top: 15px;
}

.createTripModal {
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px 10px;
  bottom: 0;
  z-index: 9;
}

.W3PopUpModal {
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px 10px;
  bottom: 0;
  z-index: 9;
}

.W3PopUpModal .W3PopUpContainer {
  background-color: #141728;
  color: #bdbdbd;
  width: 100%;
  max-width: 1200px;
  height: 85%;
  margin: 30px auto;
  padding: 30px 50px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.w3heading {
  position: relative;
  @include flexbox();
  background-color: transparent;
  color: #bdbdbd;
  @include justify-content(space-between);
  @include align-items(center);
  border: 0;
}

.tripHeading {
  position: relative;
  @include flexbox();
  background-color: transparent;
  color: #bdbdbd;
  @include justify-content(space-between);
  @include align-items(center);
  border: 0;

  h3 {
    font-size: 24px;
    line-height: 22px;
    color: #bdbdbd;
    margin: 0 15px;
    padding: 15px 0px;
    margin-top: 10px;
    font-family: "daimler_cs_regular";
  }
}

.createTripModal .createTripContainer {

  .form-group .inputField:focus,
  .form-group .dd__selectControl:focus {
    border-color: #66afe9 !important;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  .formContainer .form-group input:focus {
    border-color: #66afe9 !important;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  .react-datepicker__input-container input:focus {
    box-shadow: none !important;
    border: none !important;
  }

  background-color: #141728;
  color: #bdbdbd;
  width: 100%;
  max-width: 730px;
  margin: 30px auto;
  padding: 30px 50px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.relative {
  position: relative;
}

.createTripContainer .formContainer .errorMsg {
  top: 45px;
  font-size: 0.7rem;
}

.createTripContainer .formContainer .pickers .errorMsg {
  top: 50px;
  width: 50%;
}

.createTripContainer .formContainer .pickers .timePicker .errorMsg {
  width: 100%;
}

.createTripContainer .formContainer .errorMsgNew .errorMsg {
  top: 15px;
  left: 160px;
}

.createTripContainer .formContainer .form-group {
  background: #141728;
  font-family: "daimler_cs_regular";
  // border: 1px solid #ccc;
  padding: 0px;
  width: 100%;
  outline: 0;
  font-size: 16px;
  box-sizing: border-box;
  margin: 10px 0 20px;

  select {
    height: auto;
    padding: 13px 15px;
    background: #141728;
    font-weight: normal;
    border: none;
  }

  input[type="text"] {
    height: auto;
    padding: 10px 15px;
    border: none;
    font-family: "daimler_cs_regular";
    font-size: 0.875rem;
    line-height: inherit;
  }
}

.createTripContainer {}

.formContainer {
  display: flex;
  position: relative;
  padding: 0px 2%;
  justify-content: space-around;

  .form-group-new {
    display: flex;
    font-family: "daimler_cs_regular";
    padding: 0px;
    width: 100%;
    align-items: center;
    outline: 0;
    font-size: 16px;
    box-sizing: border-box;
    margin: 10px 0 18px;

    input[type="text"] {
      background: #141728;
      color: #bdbdbd;
      height: auto;
      padding: 10px 15px;
      width: 20%;
      font-family: "daimler_cs_regular";
      // border: none;
      border: 2px solid #212a54;
      font-size: 0.875rem;
      line-height: inherit;
    }

    label {
      width: 32%;
      font-family: "daimler_cs_regular";
      font-size: 0.875rem;
    }

    input:focus {
      //  border-color: #66afe9 !important;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  .form-group {
    background: #141728;
    font-family: "daimler_cs_regular";
    border: 2px solid #212a54;
    border-radius: 1%;
    padding: 15px;
    width: 100%;
    outline: 0;
    font-size: 16px;
    box-sizing: border-box;
    margin: 0 0 15px;

    input {
      background: #141728;
      color: #bdbdbd;
    }

    input[type="text"] {
      border: none;
      font-family: "daimler_cs_regular";
      padding-left: 5px;
      width: 100%;
    }
  }
}

.submitButton {
  border: none;
  padding: 10px 25px;
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black;
}

.submitButtonDisabled {
  cursor: not-allowed;
  border: none;
  padding: 5px 12px;
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black;
}

.leftWrapper {
  width: 49%;
}

.rightWrapper {
  width: 49%;
  margin-left: 2%;
}

.driverText,
.phoneText {
  border: none;
}

.cancelTrip {
  display: flex;
  position: absolute;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black;
  border: none;
  top: -10px;
  justify-content: center;
  right: -30px;
  /* font-size: 17px; */
  padding: 1.5px 7px;
  width: 30px;
  height: 30px;

  span {
    margin: auto;
  }
}

.location {
  // padding: 0.5% 2%;
  word-break: break-all;
}

.submitContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 2% 2%;

  .tripContainer {
    input {
      margin: 0.3rem 0.3rem 0.3rem 0 !important;
    }
  }
}

.tripButton {
  cursor: pointer;
  padding: 5px;
  background: #0c96e8;
  color: #fff;
  border: 0;
  transition: all 0.3 ease;
  font-family: "Roboto";
}

.destinationContainer {
  height: 47vh;
  left: 12px;
  top: 56px;
  width: 84vh;
  position: absolute;
  z-index: 999;
}

.mapContainer {
  /* height: 81%; */
  top: 44px;
  width: 84vh;
  height: 47vh;
  left: 1px;
  /* width: 96%; */
  /* left: 2%; */
  position: absolute;
  z-index: 999;
}

.errorMsg {
  position: absolute;
  color: red;
}

.pickers {
  display: flex;

  .react-datepicker-wrapper {
    width: 93%;
  }

  .form-group.datePicker {
    width: 65%;
    padding: 0px 8px 0px 15px;
    display: flex;
    align-items: center;

    .react-datepicker-wrapper {
      input[type="text"] {
        padding: 10px 10px;
      }
    }
  }

  .timePicker {
    margin-left: 2%;
    padding: 0px 5px;
    display: flex;
    // svg {
    //   display: none;
    // }
  }

  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }

  input[type="text"] {
    border: none;
    padding-left: 5px;
    width: 100%;
  }
}

.ant-time-picker-panel.ant-time-picker-panel-narrow {
  margin-left: -3px;
  margin-top: 47px;
}

.ant-time-picker-panel-inner {
  top: 47px;
  left: 0px !important;
}

.mainHeader {
  display: flex;
}

.inputContainer {
  position: absolute;
  z-index: 999;
  left: 30px;
  width: 40%;
  top: 30px;

  input[type="text"] {
    border: none;
    padding: 10px;
    width: 100%;
  }
}

.gmnoprint {
  display: none;
}

#map1 {
  .gmnoprint {
    display: block;
    zoom: 0.8;
    //   opacity: 0;
  }

  .gm-style-mtc {
    opacity: 0;
  }
}

//  .pickers{
//   .react-datepicker__input-container{
//   input[type="text"]{
//   padding:10px 10px;
//   }}
//   }

@media screen and (max-width: 768px) {
  .mapContainer {
    width: 59vh !important;
    height: 33vh !important;
  }

  .destinationContainer {
    width: 59vh;
    height: 33vh;
    left: 13px;
  }
}

@media screen and (max-width: 1024px) {
  .mapContainer {
    width: 79vh;
    height: 44vh;
  }

  .destinationContainer {
    width: 79vh;
    height: 44vh;
    left: 13px;
  }
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-card {
  margin: 10px 10px 0 0;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-family: Roboto;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

.custom-map {
  display: flex;
  width: 55rem;
  position: relative;
  right: 8rem;
}

.playData {
  width: 20%;
}

.playNotification {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  background: #141728;
  padding: 0.7rem;

  h1 {
    text-align: center;
    color: #bdbdbd;
    text-decoration: underline;
    font-size: 1.8rem;
    padding-bottom: 2rem;
  }

  div {
    color: #bdbdbd;
    // padding: 1rem;
  }
}

.play-section {
  position: absolute;
  top: 20%;
}

.p-1 {
  padding: 1rem;
}

.speed-panel-custom-map {
  position: absolute;
  top: 11.6rem;
  right: 39rem;
}

.custom-map {
  .gm-style .gm-style-iw-c {
    // transform: translate(-47%, -141%);
  }
}

.gm-style .gm-style-iw-c {
  overflow-y: auto;
}

.tooltip-trip {
  position: relative;
  display: inline-block;
}

.tooltip-trip .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-trip .tooltiptext {
  visibility: visible;
  width: 100px;
  height: auto;
  background-color: white;
  border: solid black 1px;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  border-radius: 6px;
  // padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  opacity: 1;
  transition: opacity 0.3s;
}

.tooltip-trip .tooltiptext::before {
  border-width: 6px;
  left: 49%;
  content: "";
  position: absolute;
  text-align: center;
  top: 100%;
  margin-left: 14px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-trip .tooltiptext::after {
  content: "";
  position: absolute;
  text-align: center;
  top: 100%;
  left: 50%;
  margin-left: 15px;
  border-width: 4px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.emailImage {
  // padding-right:0.3rem;
  zoom: 0.9;
  //padding-right: 0.1rem;

  padding-bottom: 0.5rem;
  padding-top: 0.8rem;
}

.email-wrapper {
  display: block;
  margin-left: 1.3rem;
  width: 40px;
  height: 40px;

  background: darkred;
  // padding: 0.4rem;
  border-radius: 50%;
  //  // overflow: hidden;
}

.endTrip {
  padding: 5px 15px;
  margin-left: 10px;
}

.endTripContainer {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0rem;
  left: 0rem;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px 10px;
  bottom: 0;
  z-index: 9;
}

.endTripContainer .endTripModal {
  background-color: #081c32;
  // border: 2px solid grey;
  width: 100%;
  max-width: 400px;
  margin: 30px auto;
  padding: 30px 50px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;

  .rghtTick {
    display: flex;
    justify-content: center;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;

    .okayButton {
      padding: 5px;
    }

    .cancelButton {
      padding: 5px;
    }
  }

  .content {
    text-align: center;
    color: #afafb0;
  }
}

.btn.cancel-btn {
  color: #afafb0;
  border: 2px solid grey;
  background-color: #323232;
}

.btn.cancel-btn:hover {
  color: #afafb0;

  box-shadow: 0 0 10px 5px;
}

.tableWrapperAnalysys table tbody tr td {
  font-size: 0.8rem;
  padding: 0.1rem;
  color: #a3a9c4;
}

.tableWrapperAnalysys table thead tr th {
  padding: 0.1rem;
  font-size: 0.8rem;
}

.tableWrapperAnalysys table tbody tr td:last-child {
  white-space: normal;
  overflow: unset;
}

.tableWrapperAnalysys table thead tr th:last-child {
  min-width: 48px;
}

.tableWrapperAnalysys {
  .playback {
    border-radius: 50%;
    background-color: #337ab7;
    padding: rem(10);
    font-size: rem(14.667);
    color: #333;
    cursor: pointer;
    border: none;
    outline: none;
  }
}

#rating>.ant-rate {
  width: 9rem;
}

.tripCheck {
  display: flex;
}

#trip-wrapper {
  background-color: #141728;
  border-radius: 1rem;
  height: 30.4vh;
}

#trip-wrapper {
  .gm-style .gm-style-iw-t::after {
    background: linear-gradient(45deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(255, 255, 255, 0) 100%);
    box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
    content: "";
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 15px;
  }

  .gm-style-iw .gm-style-iw-c {
    overflow-y: auto;
  }
}

.d-flex-map {
  display: flex;
}

@media (min-width: 769px) {
  .adl-fuel-width {
    width: 50%;
  }

  .custom-width1 {
    width: 45%;
    word-break: break-all;
  }

  .custom-width2 {
    width: 45%;
  }

  .maps-popup {
    // .myfleetsprite {
    //   background-image: url(../../assets/images/myfleetspritealter_updated-v1.png);
    //   &.one {
    //     width: 56px;
    //     height: 24px;
    //     background-position: -5px -2px;
    //     display: inline-block;
    //   }
    //   &.two {
    //     width: 34px;
    //     height: 27px;
    //     background-position: -5px -28px;
    //     padding-right: 2.2rem;
    //     display: inline-block;
    //     background-repeat: no-repeat;
    //   }
    //   &.three {
    //     width: 56px;
    //     height: 28px;
    //     background-position: -6px -50px;
    //     display: inline-block;
    //   }
    //   &.four {
    //     width: 33px;
    //     padding-right: 2.2rem;
    //     height: 28px;
    //     background-position: -6px -74px;
    //     display: inline-block;
    //     background-repeat: no-repeat;
    //   }
    //   &.five {
    //     width: 34px;
    //     height: 30px;
    //     background-position: -11px -100px;
    //     display: inline-block;
    //   }
    //   &.six {
    //     width: 37px;
    //     height: 28px;
    //     background-position: -9px -127px;
    //     display: inline-block;
    //   }
    //   &.seven {
    //     width: 37px;
    //     height: 28px;
    //     background-position: -9px -156px;
    //     display: inline-block;
    //   }
    // }
  }

  .max-data1 {
    // text-align: center;
    // display: flex;
    align-items: center;
  }

  .max-data2 {
    align-items: center;
  }

  .lastdata {
    justify-content: space-between li {
      width: 71%;
    }
  }

  .firstdata {
    padding-bottom: 2px;
  }

  .regid {
    padding-bottom: 0.8rem;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .d-flex-map {
    display: block;
  }

  .adl-fuel-width {
    width: 64%;
  }

  .maps-popup {
    .max-data1 {
      span {
        position: relative;
        top: -7px;
      }
    }

    .max-data2 {
      span {
        position: relative;
        top: -7px;
      }
    }

    .myfleetsprite {
      background-image: url(../../assets/images/myfleetspritealter_updated-v1.png);

      &.one {
        width: 56px;
        height: 24px;
        background-position: -5px -2px;
        display: inline-block;
        background-repeat: no-repeat;
      }

      &.two {
        width: 32px;
        height: 27px;
        background-position: -5px -28px;
        display: inline-block;
      }

      &.three {
        width: 56px;
        height: 28px;
        background-position: -6px -50px;
        display: inline-block;
      }

      &.four {
        width: 30px;
        height: 28px;
        background-position: -6px -74px;
        display: inline-block;
        background-repeat: no-repeat;
      }

      &.five {
        width: 27px;
        height: 30px;
        background-position: -11px -100px;
        display: inline-block;
      }

      &.six {
        width: 37px;
        height: 28px;
        background-position: -9px -127px;
        display: inline-block;
      }

      &.seven {
        width: 37px;
        height: 28px;
        background-position: -9px -156px;
        display: inline-block;
      }
    }
  }

  .regid {
    padding-bottom: 0.8rem;
    font-weight: bold;
  }
}

.trip-analysis {
  .reportFilters {
    div:first-child {
      padding: 0px;
    }

    .select-vehc {
      padding: 0px !important;

      .selectLabel {
        margin-left: 0.5rem;
      }
    }

    .search-fil {
      padding: 0px !important;
    }

    .create-btn1 {
      padding: 0px !important;

      button {
        background: -webkit-linear-gradient(left,
            rgba(230, 230, 230, 1) 0%,
            rgba(111, 111, 111, 1) 73%,
            rgba(111, 111, 111, 1) 100%);
        color: black;
        font-weight: bold;
      }
    }

    .shr-btn {
      padding: 0px !important;
    }

    .end-trp {
      padding: 0px;
      border-radius: 4px;
      background-color: darkred;

      .btn.primary-btn {
        background-color: darkred;
        border: none;
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: landscape) {
  .custom-width1 {
    width: 80%;
  }

  .maps-popup .myfleetsprite.five {
    width: 33px;
  }
}

.initial-style {
  display: none;
}

.checkBoxed {
  width: 2%;
}

.tripId0 {
  width: 4%;
}

.fromCity1 {
  width: 6%;
}

.toCity2 {
  width: 6%;
}

.scheduledStartTime3 {
  width: 9%;
}

.scheduledEndTime4 {
  width: 9%;
}

.registrationId5 {
  width: 9%;
}

.driverFullName6 {
  width: 10%;
}

.averageSpeed7 {
  width: 0%;
}

.plannedStops8 {
  width: 0%;
}

.actualNoStops9 {
  width: 3%;
}

.distance10 {
  width: 6%;
}

.score11 {}

.playback12 {}

.arrivalStatus13 {}

.eta14 {}

.owner-wrapper-trip {
  height: 95vh;
  margin-left: 3.5rem;
  background: #0a0e22;
}

.trip-analysis {
  .ant-input {
    background-color: transparent !important;
  }

  .ant-calendar-picker-icon {
    color: #bdbdbd !important;
  }

  .ant-calendar-range-picker-separator {
    color: #bdbdbd !important;
  }
}

.ant-calendar-range-picker-input {
  color: #a3a9c4 !important;
}

.filter-btn {
  background-color: #141728;
  color: white;
  border: none;
}

.disable-btn {
  cursor: not-allowed;
}

.filter-btn:active,
.filter-btn:focus {
  outline: none;
}

.truck-perf-font {
  font-size: 11px;
}

.radial-no-data {
  font-size: 16px;
  font-weight: bold;
  color: grey;

  padding: 3rem;
}

.clearButtonDashboard {
  padding: 0.3rem !important;
  cursor: pointer;
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black;
  font-size: 13px;
  font-weight: bold !important;
  border-radius: 4px;
  margin-bottom: 0.2rem;
}

.fleet-status-loading {
  color: #bdbdbd;
  font-weight: bold;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.locationImg {
  padding-right: 0.3rem;
  width: 13px;
  height: 12px;
}

.driver-name-padding {
  // padding-right: 1.7rem;
}

.running-toggle {
  background: #4e6984;
}

.offline-toggle {
  background: #4e6984;
}

.idle-toggle {
  background: #4e6984;
}

.outline-none {
  outline: none;
}

.status-panel:focus {
  outline: none;
  box-shadow: none;
}

.wb {
  word-break: break-all;
}

.bg-date {
  background: url("../images/date-new.png");
  background-repeat: no-repeat;
  // height: 30px;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-play {
  height: 17px;
  background: url("../images/1.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  padding-top: 2px;
  background-position: left top;
  cursor: pointer;
}

.info-bg-driver,
.info-bg-driver-fmt {
  height: 17px;
  background: url("../images/user-newsite.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-driver-fmt {
  background: url("../images/fmt_pop_up/driver_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.info-bg-phone,
.info-bg-phone-fmt {
  height: 17px;
  background: url("../images/phone-newsite.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-phone-fmt {
  background: url("../images/fmt_pop_up/phone_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.info-bg-location,
.info-bg-location-fmt {
  min-height: 17px;
  height: auto;
  background: url("../images/location-newsite.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-date-fmt {
  background: url("../images/fmt_pop_up/date_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.info-bg-date,
.info-bg-date-fmt {
  // height: 17px;
  background: url("../images/Date-newsite.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-date-fmt {
  background: url("../images/fmt_pop_up/date_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.info-bg-time,
.info-bg-time-fmt {
  height: 18px;
  background: url("../images/time-newsite.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-time-fmt {
  background: url("../images/fmt_pop_up/time_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.info-bg-mill,
.info-bg-mill-fmt {
  height: 17px;
  background: url("../images/speed.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-mill-fmt {
  background: url("../images/fmt_pop_up/speed_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.info-bg-fuel,
.info-bg-fuel-fmt {
  height: 18px;
  background: url("../images/Fuel-newsite.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-fuel-fmt {
  background: url("../images/fmt_pop_up/fuel_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.info-bg-adl,
.info-bg-adl-fmt {
  height: 17px;
  background: url("../images/adblue-newsite.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-adl-fmt {
  background: url("../images/fmt_pop_up/adblue_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-time {
  background: url("../images/tt-new.png");
  background-repeat: no-repeat;
  // height: 30px;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-trip-distance,
.info-bg-trip-distance-fmt {
  height: 17px;
  background: url("../images/odo-newsite.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-trip-distance-fmt {
  background: url("../images/fmt_pop_up/odo_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.info-bg-trip-time {
  background: url("../images/time-newsite.png");
  background-repeat: no-repeat;
  // height: 30px;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-trip-eta {
  background: url("../images/eta-newsite.png");
  background-repeat: no-repeat;
  // height: 30px;
  padding-left: 30px;
  background-position: left top;
}

.info-bg-trip-count {
  height: 17px;
  background: url("../images/info_map.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
}

.expleftSidePanel {
  p {
    margin-bottom: 0;
  }
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

#map1 {
  button.gm-ui-hover-effect {
    visibility: visible;
    //  padding: 1rem !important;
  //  background: grey !important;
    color: white !important;
    font-weight: bold !important;
    zoom: 0.8;
    font-weight: bold !important;
    border-radius: 23px !important;
    height: 30px !important;
    width: 30px !important;
    top: -25px !important;
    margin-top: 2.2rem !important;
    margin-right: 1.8rem !important;
    //  margin-left: 1rem !important;
  }

  button.gm-ui-hover-effect:hover {
    box-shadow: none;
  }

  .gm-style .gm-style-iw-c {
    padding: 0;

  //  background-color: #dee7ec;
  }
}

.detail-closebtn {
  background-color: black;
}

.detail-reg-label {
  width: 70%;
}

.reg-static-label {
  margin-bottom: 0.2rem;
  font-weight: bold;
}

.live-button-panel {
  padding-left: 6rem;
  margin-bottom: 0.5rem;
}

.apply-live-not {
  background-color: #141728;
  height: 1.5rem;
  width: 5rem;
  color: wheat;
  font-size: 14px;
  margin-right: 1rem;
  border: 1px solid #141728;
  border-radius: 0.4rem;
}

.clear-live-not {
  background-color: #141728;
  height: 1.5rem;
  width: 5rem;
  color: wheat;
  font-size: 14px;
  border-radius: 0.4rem;
  border: 1px solid #141728;
}

// new info window styles

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

p {
  margin: 0;
}

.pr-15 {
  padding-right: 15px;
}

.w-25 {
  width: 25%;
}

.header {
  font-weight: bold;
}

.head {
  font-weight: bold;
  // margin:20px 0 10px;
}

//setting styles
.group-setting-heading {
  background-color: #131c34;
  color: #bdbdbd;
  font-weight: bold;
  padding: 0.5rem;
  //margin-right: 1rem;
}

.w-60 {
  width: 60%;
}

.w-80 {
  width: 80%;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-100 {
  width: 100%;
}

.group-name-setting:nth-child(odd) {
  background-color: #060818;
  color: #bdbdbd;
  padding: 0.5rem;
  position: relative;
  // margin-top: 0.2rem;
}

.group-name-setting:nth-child(even) {
  background-color: #131c34;
  color: #bdbdbd;
  position: relative;
  padding: 0.5rem;
  // margin-top: 0.2rem;
}

.scroll-group {
  height: calc(100vh - 25rem);
  overflow-y: auto;
  // padding-right: 0.7rem;
}

.popover-header {
  // background-color: #282828;
  // color: #BDBDBD;
}

.popover-body {
  // background-color: #BDBDBD;
  //color: #BDBDBD;
}

.overlay-btn {
  background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
  color: black;
  //  padding: 0.2rem;
  font-weight: bold;
  width: 4rem;
  font-size: 15px;

  border-radius: 5px;
}

.overlay-btn:focus {
  outline: none;
  border: none;
}

.confirmationpop {
  visibility: visible;
  width: 100px;
  height: auto;
  background-color: #fff;
  border: 1px solid #000;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  /* z-index: 1; */
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  /* opacity: 1; */
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;

  .pophdr {
    padding: rem(8) rem(14);
    margin: 0;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;

    h3 {
      font-size: rem(14);
      text-align: left;
      line-height: 1.1;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      white-space: nowrap;
    }
  }

  .modal-body {
    padding: rem(9) rem(28) rem(25);
    background: #fff;
    border-radius: 0 0 5px 5px;

    .delete-btn {
      background-color: #a70e13;
      font-weight: 700;
      padding: rem(6) rem(12);
      margin-right: rem(5);

      &:hover {
        background-color: #c9302c;
        border-color: #ac2925;
      }
    }

    .clear-btn {
      background-color: $primary-color;
      border-color: $primary-color;
      font-weight: 700;
      padding: rem(6) rem(12);

      &:hover {
        background-color: #ec971f;
        border-color: #d58512;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -7px;
    width: 14px;
    height: 14px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    @include transform(rotate(45deg));
    border-width: 0 1px 1px 0;
    right: 52px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.group-btn-setting,
.group-btn-setting-disabled {
  background-color: #cacaca;
  background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
  color: black;
  padding: 0.2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  width: 5rem;
  font-size: 15px;
  border-radius: 5px;
}

.group-btn-setting-disabled {
  cursor: not-allowed !important;
}

.text-color-alert {
  color: #bdbdbd;
}

.inner-text-color-alert {
  color: #a4a4a4;
}

.w-46 {
  width: 46%;
}

.groups-setting-tittle {
  color: #bdbdbd;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.groups-setting-add {
  color: #bdbdbd;
  font-size: 15px;
}

.kpi-btn {
  background-color: #cacaca;
  background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
  outline: none;
  border: none;
  color: black;
  padding: 0.3rem;
  font-weight: bold;
  width: 5rem;
  font-size: 15px;
  border-radius: 5px;

  &[disabled] {
    cursor: not-allowed;
    box-shadow: none;
  }
}

.kpi-btn:focus {
  outline: none;
  border: none;
}

.btn-alert-new {
  width: 33%;
  text-align: start;

  .btn-setting {
    background-color: #cacaca;
    background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
    color: black;
    padding: 0.3rem;
    font-weight: 700 !important;

    width: 5rem;
    font-size: 15px;
    border-radius: 5px;
  }

  .btn-setting:focus {
    outline: none;
    border: none;
  }
}

.heading-alert {
  margin-bottom: 2rem;
  font-size: 18px;
  font-weight: bold;
}

.w-33 {
  width: 33%;
}

.w-30 {
  width: 30%;
}

.accordionContent {
  .form-group .inputField {
    background-color: #0a0e22;
  }
}

.middle-div {
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  ::-webkit-scrollbar-thumb {
    background: grey;

    background-clip: padding-box;
  }
}

.accordionContainer {
  // padding: 4rem;
  // margin-top: 2rem;

  .ChkBox input[type="checkbox"] {
    visibility: hidden;
    opacity: 0;
  }

  .ChkBox input[type="checkbox"]:checked+label {
    background: #aeaeae;
    border: 1px solid #aeaeae;
  }

  .ChkBox input[type="checkbox"]:checked+label:before {
    opacity: 1;
  }

  .ChkBox {
    position: relative;
    display: inline-block;

    label {
      display: inline-block;
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #ccc;
      cursor: pointer;
      @include transition(all 0.3s ease);

      &::before {
        content: "";
        position: absolute;
        border: 2px solid #fff;
        width: 11px;
        height: 5px;
        display: inline-block;
        @include transform(rotate(320deg));
        border-width: 0px 0px 2px 2px;
        top: 4px;
        left: 4px;
        opacity: 0;
      }
    }
  }

  .setAlerts {
    margin-left: 11rem;
    // padding: 0 rem(15);
    // max-width: 300px;
    font-size: rem(14.667);
    color: #333;

    li {
      @include flexbox;
      @include justify-content(space-between);
      margin-bottom: 1rem;

      &.heading {
        margin-bottom: 15px;
        font-weight: bold;
        color: #000;
      }

      .ChkBox {
        // width: 31px;
      }
    }

    &~.form-group {
      width: 100%;
      text-align: right;
      padding-top: 10px;
    }
  }
}

.inputWrapper {
  .form-group .inputField {
    background: #0a0e22;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .btn {
    background-color: #cacaca;
    color: black;
  }
}

.scaling-btn {
  background-color: #cacaca;
}

.kpi-title {
  color: #bdbdbd;
  font-size: 18px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-weight: bold;
}

.form-custom-label {
  position: relative;
  display: block;
  font-size: 0.8125rem;
  // font-weight: 700;
  color: #bdbdbd;
  margin-bottom: 5px;
}

.setting-left-panel {
  width: 20%;
  height: 100vh;
}

.setting-right-panel {
  width: 80%;
}

#auto-ref {
  margin-top: 2rem;
}

.setting-inner-left-panel {
  background-color: #141728;
  height: 80%;
  margin-top: 0.3rem;
  padding-top: 1rem;
  color: white;
}

.setting-inner-right-panel {
  // background-color: green;
  height: 85%;
  // margin-top: 1rem;
  margin-right: 1rem;
}

.left-sub-wrapper {
  padding: 0.9rem;
  cursor: pointer;
}

.disable-vehicle {
  cursor: not-allowed;
}

.filter-disable {
  cursor: not-allowed;
}

.no-data-live {
  text-align: center;
  color: white;
  padding: 1rem;
}

.refresh-input {
  position: absolute;
  top: 15%;
  left: 27%;
  width: 50%;
}

.refresh-heading {
  position: absolute;
  left: 27%;
  color: #bdbdbd;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2rem;
  // margin-top: 2rem;
}

.alert-setting-heading {
  color: #bdbdbd;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2rem;
}

.setting-password {
  padding: 2rem;
  padding-top: 0;
}

.pass-title {
  color: #bdbdbd;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  left: 33%;
}

.wrapper-password {
  position: absolute;

  text-align: center;
  margin-top: 2rem;
  top: 17%;
  left: 31%;
}

.pdfDownload {
  // float:right;
  // line-height: 2.4;
  width: 10rem;
  background-color: grey;
  border: 0.4px solid grey;
  border-radius: 24px;
  cursor: pointer;
}

.pdf-icon-settings {
  float: left;
  padding: 0.2rem;
}

.pdf-text {
  padding-top: 0.4rem;
}

.user-title {
  color: #bdbdbd;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2rem;
}

.maintain-image {
  border: 1px solid #bdbdbd;
}

.maintain-button,
.maintain-button-disabled {
  background-color: #cacaca;
  background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
  color: black;
  padding: 0.2rem;
  font-weight: bold;
  width: 5rem;
  font-size: 15px;
  border-radius: 5px;
  margin-left: 0.3rem;
  margin-top: 0.5rem;
}

.maintain-button-disabled {
  cursor: not-allowed !important;

  &:hover {
    box-shadow: none;
  }
}

.maintain-button:focus {
  outline: none;
  border: none;
}

.maintain-title {
  label {
    color: #bdbdbd;
    font-weight: bold;
    font-size: 18px;
    // margin-bottom: 2rem;
  }
}

.no-data-dashboard {
  padding: 0.5rem;

  font-size: 11px;
  font-weight: 700;

  color: #9d9d9d;
}

.no-trucks-fmt {
  text-align: center;
  color: #bdbdbd;
  font-weight: bold;
  font-size: 18px;
}

.padding-no-trucks {
  padding: 8rem;
}

.padding-no-trucks-coll {
  padding-top: 8rem;
}

.no-data-fmt-drop {
  font-size: 11px;
  font-weight: 700;
  color: #051d35;
  padding: 0.5rem !important;
}

#map-dashboard div.gm-style>button[title="Toggle fullscreen view"] {
  display: none;
}

#map-daily div.gm-style>button[title="Toggle fullscreen view"] {
  display: none;
}

#map-dashboard {
  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw-c {
    padding: 0rem !important;
    max-width: 440px !important;
    height: 260px;
    // background-color: #dee7ec;
    color: black;
  }

  .gm-style-iw-d {
    height: auto;
    //  height: 315px;
    width: 450px;
    padding: 0rem 1rem;
    // padding-bottom: 0;
  }
}

#map-daily {
  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw-c {
    padding: 0rem !important;
    max-width: 515px !important;
    height: auto !important;
    background-color: #dee7ec;
    color: black;
  }

  .gm-style-iw-d {
    height: auto;
    //  height: 315px;
    width: 490px;
    padding: 1rem;
    // padding-bottom: 0;
  }
}

#map1 {
  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw-c {
    padding: 0rem !important;
    max-width: 515px;
    height: auto !important;
  //  background-color: #dee7ec;
    color: black;
  }

  .gm-style-iw-d {
    height: auto;
    //  height: 315px;
    //  width: 490px;
    padding: 1rem;
    // padding-bottom: 0;
  }
}

#geofence_delete_pop {
  display: none;
  background-color: #fcd1d1;
  border: 2px solid #d43434;
  border-radius: 4px;
}

.btn-submit-dash:focus {
  outline: none;
  border: none;
}

.btn-submit-dash {
  outline: none;

  height: 2.4rem;
  width: 83px;
  border-radius: 5px;
  border: none;
  background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
  //background-color: #cacaca;
  //background: linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%);
  color: #000000;
  font-family: "daimler_cs_regular";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17.28px;
}

.playBackPopUp {
  position: absolute;
  left: 62%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  width: 21rem;
  height: 10rem;
  background-color: #141728;
  color: white;
}

.geo-type-popup {
  position: absolute;
  left: 62%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  width: 21rem;
  height: 10rem;
  color: black;
}

.geo-type-heading {
  color: #0c96e8;
  padding-bottom: 1rem;
  font-weight: bold;
}

.playBackPopUp-heading {
  color: #0c96e8;
  padding-bottom: 1rem;
  text-align: center;
}

.geo-type-button {
  margin-top: 1rem;
  // margin-bottom: 0.5rem;
  background-color: #081c32;
  border-radius: 5px;
  border: 1px solid #18416a;
  height: 1.8rem;
  width: 6rem;
  color: wheat;
  font-size: 14px;
}

.playback-close-button {
  margin-top: 3rem;
  // margin-bottom: 0.5rem;
  background-color: #081c32;
  border-radius: 5px;
  border: 1px solid #18416a;
  height: 1.8rem;
  width: 6rem;
  color: wheat;
  font-size: 14px;
}

.locationAlert {
  margin-top: -0.7rem;
  width: 16rem;
}

.gm-ui-hover-effect:hover {
  opacity: 1;
}

.gm-style button[title^="Close"] {
  // color: #fff;
  // top: 7px!important;
  // right: 7px!important;
}

.alertWindowClose {
  position: absolute;
  left: 54%;
  top: 0;
  cursor: pointer;
}

.geo-header {
  background-color: #081c32;
}

.geo-wrapper-pop {
  .menu-dropdown {
    display: block;
    position: absolute;
    top: 39px;
    background: #212a54;
    left: -2px;
    z-index: 1;
    min-width: 200px;
    max-height: 250px;
    overflow: auto;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-style: italic;
    padding-left: 0.5rem;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-style: italic;
    padding-left: 0.5rem;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-style: italic;
    padding-left: 0.5rem;
  }

  // input[type="checkbox"]{
  //   -webkit-appearance: none;
  //  // position: absolute;
  //   width: 18px;
  //   background-repeat: no-repeat;
  //   background-color: #969696;
  //   height: 14px;
  // //  background: url("../images/tick-mark02.png");

  //   }

  // input[type="checkbox"]:checked{
  //   background: url("../images/tick-mark01.png");
  //   background-repeat: no-repeat;
  //   background-color: #969696;
  //   background-size: contain;
  // }

  input {
    background-color: #081c32;
    border: 2px solid #212a54;
    border-radius: 4px;
    color: #bdbdbd;
  }

  input:focus {
    outline: none !important;
    background-color: #081c32;
    border: 2px solid #a1a1a1;
    border-radius: 4px;
    box-shadow: 0 0 10px #a1a1a1;
  }

  // input:focus{
  //   background-color: #323232;
  //   border: 2px solid #A1A1A1;
  //   border-radius: 4px;
  //  // height: 2.5rem;
  // }
  .second-geo-input {
    height: 2.5rem;
  }

  .ant-calendar-picker-icon {
    color: #bdbdbd;
  }

  .ant-input {
    height: 2.5rem;
    background-color: #081c32 !important;
    outline: none !important;
  }

  .ant-input:focus {
    border: 2px solid #a1a1a1;
    border-radius: 4px;
    box-shadow: 0 0 10px #a1a1a1;
  }

  #selectedGeoFence {
    border: 2px solid #a1a1a1;
    border-radius: 4px;
    width: 100%;
    background-color: #081c32;
    color: #bdbdbd;
    height: 2.5rem;
  }

  #selectedGeoFence:focus {
    border: 2px solid #a1a1a1;
    border-radius: 4px;
    box-shadow: 0 0 10px #a1a1a1;
  }
}

.first-geo-input {
  width: 70%;
  margin-right: 0.5rem;
  height: 2.5rem;
}

.geo-button {
  height: 2.5rem;
  width: 108px;
  // background-color: #cacaca;
  // background: linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%);
  background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
  color: #000000;
  font-family: "daimler_cs_regular";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17.28px;
  border-radius: 5px;
}

.save-btn-reset {
  // height: 2.5rem;
  font-weight: 700 !important;
  width: 108px;
  background-color: #cacaca;
  background: linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%);
}

.save-btn-profile {
  height: 2.2rem;
  font-weight: bold !important;
  padding: 0.3rem 0.75rem !important;
  // width: 108px;
  font-weight: bold;
  border-radius: 5px;
  background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
}

.health-wrapper {
  padding: 0.3rem 1rem 1rem 0;

  .dropdown {
    margin-right: 0.5rem;
    color: #bfbfc0;
    border: 2px solid #212a54;
    border-radius: 4px;
    width: 12rem;
    background: transparent;
    /* border: 2px solid grey; */
    height: 2rem;
    padding: 0.2rem;
  }

  .lightThemeAlert {
    margin-top: -0.7rem;
  }

  .ant-input {
    background-color: transparent !important;
  }

  .ant-calendar-range-picker-separator {
    color: #bfbfc0;
  }

  .ant-calendar-picker-icon {
    color: #bfbfc0;
  }
}

.darkTheme .geofence-popup-text {
  color: #ccc;
}
.no-data-body-fleet {
  padding: 11rem;
  padding-left: 39rem;

  color: #bdbdbd;
  font-weight: bold;
}

.no-dtc-health {
  padding: 2rem;
  font-weight: bold;
  padding-left: 15rem;
  color: #bfbfc0;
}

.health-indicator {
  width: 40%;
  color: white;
  text-align: center;
  border-radius: 5px;
  height: 6rem;
  background-color: #212a54;
  margin-right: 1rem;
}

.health-carousel {
  height: 6rem;
  border-radius: 5px;
  width: 60%;
  background-color: #212a54;
}

.imm-img {
  width: 25%;
  border-top-right-radius: 25px;
  height: 6rem;
  background-color: #495583;
  border-bottom-right-radius: 25px;
}

.carousel-main {
  width: 78%;
}

.carousel-control {
  width: 2%;
}

.dots-btn {
  background: no-repeat;
  border: none;
}

.dots-class {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  cursor: pointer;
}

.screw-img {
  padding-right: 1.5rem;
  padding-top: 0.8rem;
  height: 5rem;
}

.imm-text {
  // padding: 1rem;
  padding-top: 2rem;
  padding-left: 1rem;
  color: white;
  font-weight: bold;
  text-align: center;
}

.w-20 {
  width: 20%;
}

.bold-style {
  font-weight: bold;
}

.privacyPopUp {
  .modal {
    display: block !important;
  }
}

.clear-btn-trip {
  border-radius: 4px;
  padding: 0.3rem !important;
  cursor: pointer;

  background: -webkit-linear-gradient(left,
      rgba(230, 230, 230, 1) 0%,
      rgba(111, 111, 111, 1) 73%,
      rgba(111, 111, 111, 1) 100%);
  color: black;
  font-size: 13px;
  font-weight: bold !important;
}

.ok-trip-btn {
  background: -webkit-linear-gradient(left,
      rgba(230, 230, 230, 1) 0%,
      rgba(111, 111, 111, 1) 73%,
      rgba(111, 111, 111, 1) 100%);
  color: black;
  font-weight: bold !important;
}

.ok-trip-btn:hover {
  color: #afafb0;

  box-shadow: 0 0 10px 5px #afafb0;
}

.clear-btn-trip:hover {
  //color: #afafb0;

  box-shadow: 0 0 10px 5px #afafb0;
}

.share-new {
  color: black;
  outline: none;
  border: none;
  background: -webkit-linear-gradient(left,
      rgba(230, 230, 230, 1) 0%,
      rgba(111, 111, 111, 1) 73%,
      rgba(111, 111, 111, 1) 100%);
}

.disable-cursor {
  cursor: not-allowed;
}

.BrainhubCarousel__dots {
  display: flex;
  flex-direction: column;
}

.BrainhubCarousel__dots .BrainhubCarousel__thumbnail {
  border-radius: 50%;
  zoom: 0.5;
  margin: 0.5rem;
  margin-right: 1.5rem;
}

.health-wrapper {
  .menu-dropdown {
    display: block;
    position: absolute;
    top: 36px;
    background: #212a54;
    left: 0;
    z-index: 1;
    min-width: 200px;
    max-height: 250px;
    overflow: auto;
  }

  .react-datepicker__input-container {
    input {
      border-radius: 4px;

      border: none;
      background: transparent;
      color: #bfbfc0;
    }
  }

  .react-datepicker-ignore-onclickoutside {
    border: none;
    background: transparent;
  }

  .react-datepicker-wrapper {
    // padding: 0.2rem !important;
    padding-left: 1rem !important;
  }

  .menu-dropdown-close {
    display: none;
  }
}

.profile-new-wrapper {
  .select-vehc {
    width: 100%;
    height: 34px;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem !important;
    /* color: #555; */
    /* background-color: #fff; */
    background-image: none;
    border: 2px solid #212a54;
    border-radius: 4px;
    font-weight: 600;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .menu-dropdown {
    display: block;
    /* padding: 1rem; */
    position: absolute;
    top: 27px;
    background: #444;
    left: -13px;
    z-index: 1;
    min-width: 18.5rem;
    max-height: 190px;
    overflow: auto;
  }

  .react-datepicker__input-container {
    input {
      border-radius: 4px;

      border: none;
      background: transparent;
      color: #bfbfc0;
    }
  }

  .react-datepicker-ignore-onclickoutside {
    border: none;
    background: transparent;
  }

  .react-datepicker-wrapper {
    // padding: 0.2rem !important;
    padding-left: 1rem !important;
  }

  .menu-dropdown-close {
    display: none;
  }
}

.health-btn {
  margin-right: 0.5rem;
  width: 5rem;
  background: -webkit-linear-gradient(left,
      rgba(230, 230, 230, 1) 0%,
      rgba(111, 111, 111, 1) 73%,
      rgba(111, 111, 111, 1) 100%);
  color: black;
  font-weight: bold;
  outline: none;
  border: none;
  border-radius: 4px;
  height: 2rem;
}

.health-btn:focus {
  border: none;
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
  outline: none;
}

button:hover {
  box-shadow: 5px 10px 20px #444 inset;
}

.date-fleet-health {
  margin-right: 0.5rem;
  height: 2rem;
  // padding: 0.2rem;
  color: #bfbfc0;
  border: 2px solid #212a54;
  border-radius: 4px;
}

// .react-datepicker__input-container{
//  input{
//   border-radius: 4px;

//   border: none;
//   background: transparent;
//   color: #BFBFC0;
//  }
// }

// .react-datepicker-wrapper{
//  // padding: 0.2rem !important;
//   padding-left: 1rem !important;
// }

.date-label {
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 10px;
}

.date-label-none {
  display: none;
}

.each-card-width {
  color: #bfbfc0;
  margin: 0.5rem;
  width: 23%;
  min-height: 13rem;
  padding: 1rem;
  background-color: #141728;
  margin-bottom: 1.5rem;
}

.health-dtc-code {
  padding-right: 0.6rem;
  background-color: #0a0e22;
  /* padding: 1px; */
  border-radius: 6px;
  padding-left: 0.6rem;
}

.severity-block {
  border-radius: 4px;
  position: relative;
  top: -2rem;
  left: 2rem;
  // bottom: 30rem;
  height: 2rem;
  width: 9rem;
  padding: 0.8rem;
  padding-left: 1.3rem;
  align-items: center;
  display: flex;
  &.service-now {
    background: #E54C3E;
  }
  &.service-later {
    background: #424242;
  }
}

.health-desc-height {
  min-height: 6rem;
}

.health-font-small {
  font-size: 15px;
  font-weight: bold;
}

.health-font-big {
  font-size: 18px;
  line-height: 20px;
}

.card-container-health {
  height: 58vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.finalContainer {
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 95%;

  &.sm-finalContainer {
    width: auto;
    left: 10px;
    transform: translateX(10px);

    >.row {
      width: 97px;
    }
  }

  .toggleWrapper {
    background: $alertbtn-bg;
    justify-content: center;
    height: 40px;
    line-height: 40px;
    max-width: 100px;

    label {
      .react-toggle-track {
        height: 20px;
        width: 40px;
      }

      .react-toggle-thumb {
        height: 18px;
        width: 18px;
      }

      .react-toggle.react-toggle--checked {
        .react-toggle-track {
          background: $alertbtn-active;
        }

        .react-toggle-thumb {
          left: 21px;
        }
      }

      .react-toggle {
        vertical-align: text-bottom;

        .react-toggle-track {
          background: $alertbtn-inactive;
        }

        .react-toggle-thumb {
          background: $alertbtn-bg;
          box-shadow: none;
        }
      }
    }
  }

  .layoutContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .go-button {
      background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
      color: #000000;
      cursor: pointer;
      flex-grow: 1;
      flex-basis: 0;
      height: 40px;

      .goButton {
        font-weight: bold !important;
        padding: 0;
        width: 100%;
        height: 100%;
      }

      .goButton:hover {
        box-shadow: none;
      }
    }

    .additionalCtrl,
    .backOption {
      padding-left: 5px;
      cursor: pointer;
      flex-grow: 1;
      flex-basis: 0;
      height: 40px;

      img {
        height: 100%;
      }
    }
  }
}
.finalContainer {
  &.sm-container {
    .newPlayBackDiv {
      background: $alertbtn-bg;

      &.noBg {
        background: none;
      }
    }

    .controlsContainer {
      max-width: 27vw;
      padding-bottom: 0 !important;
    }
  }

  .newPlayBackDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .controlsContainer {
      .speed-div {
        background-color: #888888;
        cursor: pointer;
        width: 25px;
        line-height: 25px;
        border-radius: 6px;
        height: 25px;
        color: #dee7ec;
        font-family: "daimler_cs_regular";
        font-size: 15px;
        letter-spacing: 0;
      }

      .speed-div-active {
        cursor: pointer;
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 6px;
        color: #dee7ec;
        font-family: "daimler_cs_regular";
        font-size: 15px;
        letter-spacing: 0;
        background-color: #141728;
      }

      .level {
        color: #888888;
        font-weight: bold;
      }

      .route-play-btn {
        margin-right: 5px;
        width: 33px;
      }

      .reloadIcon {
        margin-left: 0.5rem;
      }

      .reloadIcon,
      .playIcon,
      .closeIcon,
      .pauseIcon {
        color: #dee7ec;
        cursor: pointer;
      }

      padding: 0.4rem;
      background-color: #dee7ec;

      ul {
        margin: 0;
        display: flex;
      }
    }

    .newplayBackContainer {
      padding: 0.45rem;
      background-color: #dee7ec;

      ul {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          width: 50%;
          align-items: center;
          display: flex;

          label {
            margin: 0;
            color: #0a3044;
            font-family: "daimler_cs_regular";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17.28px;
          }

          .react-datepicker-wrapper {
            .react-datepicker__input-container {
              input {
                color: #0a3044 !important;
              }
            }
          }
          .react-datepicker-popper{
            width: 339px;
          }
          .react-datepicker__time-list-item {
            width: 100%;
          }
        }
      }
    }
  }
}

.react-datepicker__tab-loop {
  .react-datepicker-popper {
    .react-datepicker {
      background-color: #141728 !important;

      .react-datepicker__header {
        background-color: #141728;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected,
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: grey !important;
      }

      .react-datepicker__time-container .react-datepicker__time {
        background-color: #141728 !important;

        .react-datepicker__time-box {
          .react-datepicker__time-list {
            display: block;
          }
        }
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 2500 !important;
}

.react-datepicker__day {
  color: #bdbdbd !important;
}

.react-datepicker__day--disabled,
.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  color: #232844 !important;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  color: #ccc !important;
}

.react-datepicker__day-name {
  color: #bdbdbd !important;
}

.react-datepicker__current-month {
  color: #bdbdbd !important;
  border-radius: 20px 20px 0 0;
}

.react-datepicker-time__header {
  color: #bdbdbd !important;
}

.react-datepicker__time-list-item {
  color: #bdbdbd !important;
}

.react-datepicker__time-list-item--disabled {
  cursor: not-allowed !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #141728 !important;
}

.react-datepicker__triangle {
  border-bottom-color: #141728 !important;
}

.reloadIconPause {
  cursor: not-allowed;
  margin-left: 0.5rem;
  pointer-events: auto;
}

.speed-panel-custom-map {
  margin-left: 0.5rem;
  border-radius: 7px;

  .speed-div {
    background-color: #888888;
    cursor: pointer;
    width: 1.5rem;
    border-radius: 6px;
    height: 1.5rem;
    color: #dee7ec;
    font-family: "daimler_cs_regular";
    font-size: 15px;
    letter-spacing: 0;
  }

  .speed-div-active {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6px;
    color: #dee7ec;
    font-family: "daimler_cs_regular";
    font-size: 15px;
    letter-spacing: 0;
    background-color: #141728;
  }

  .level {
    color: #888888;
    font-weight: bold;
  }

  .route-play-btn {
    width: 2rem;
    margin-right: 0.5rem;
  }

  .reloadIcon {
    margin-left: 0.5rem;
  }

  .reloadIcon,
  .playIcon,
  .closeIcon,
  .pauseIcon {
    color: #dee7ec;
    cursor: pointer;
  }

  padding: 0.5rem;
  display: flex;
  background-color: #dee7ec;

  ul {
    margin: 0;
    display: flex;
  }
}

.no-click {
  cursor: not-allowed !important;
}

.triPlotingBack {
  position: absolute;
  right: 44px;
  top: 7px;
  background-color: #021923;
  padding: 0.5rem;
  border: 1px solid #dee7ec;
  cursor: pointer;
}
.logoWrapper {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appIcons {
  height: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.loginButton {
  width: 100%;
  background: #fff;
  color: #141728;
  padding: 0.3rem;
  outline: none;
  border: none;
  border-radius: 8px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
  padding: 18px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.loginButton:hover,
.loginButton:focus {
  background: #fff !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

.subText {
  color: white;
  font-size: 0.9rem;
  letter-spacing: 0;
  line-height: 17.28px;
}

.mainText {
  display: block;
  color: white;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17.28px;
}

.form-group {
  .newLabel {
    float: left;
    display: block;
    margin-bottom: 5px;
    color: #e4e3e3;
    font-family: "daimler_cs_regular";
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 17.28px;
  }
}

.password-container {
  width: 100% !important;
  position: relative;

  span {
    position: absolute;
    right: 10px;
    top: 27px;
    color: white;
    cursor: pointer;
  }
}

.termsAndConditionsPage {
  position: relative;
  height: 111vh;
  margin-top: -80px;
  background: url(../images/forgot_pwd.png) no-repeat;
  background-size: cover;

  .termsAndConditionsWrapper {
    position: absolute;

    @include flex-direction(row);
    background: #0a0e22;

    top: 25%;
    left: 25%;
    bottom: 16%;
    width: 72%;
    max-width: 850px;

    .acceptContainer {
      text-align: center;
      border: none;
      outline: none;
      margin: 5px;
      padding: 5px;

      .acceptBtn {
        padding: 0.375rem 0.75rem;
        font-size: 15px;
        line-height: 1.42857143;
        border: none;
        border-radius: 4px;
        font-weight: bold;
        margin-right: 1rem;
        background: linear-gradient(271deg, #ffffff 0%, #4b4b4b 100%);
      }

      .acceptBtn:focus {
        outline: none !important;
      }
    }

    hr {
      position: relative;
      border: none;
      height: 2px;
      background: #212a54;
      font-weight: bold;
      padding-left: 2rem;
      margin-left: 2rem;
      padding-right: 25px;
      margin-right: 25px;
      margin-top: 0px;
      margin-bottom: 1rem;
    }

    .imageContainer {
      display: flex;
      padding: 20px 20px 5px 20px;
      margin: 20px 20px 5px 20px;

      img {
        //  background-color: white;
      }

      .termsAndConditionsContent {
        margin-left: 0.9rem;
        margin-top: 0.7rem;
      }
    }

    .termsContent {
      color: white;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 20px;

      p {
        color: white;
        line-height: 1.6;
        font-size: 0.875rem;
        margin-top: 0;
        margin-bottom: 1rem;
        font-family: "daimler_cs_regular";
      }
    }

    h1 {
      color: white;
      font-weight: bold;
      font-size: 1rem;
    }
  }
}

.helloText {
  color: #fff;
}

.alertValue {
  margin-bottom: 1.5rem;
}

.level-header {
  font-weight: bold;
}

.owner-wrapper-analysis {
  height: 95vh;
  margin-left: 3.5rem;
  background: #0a0e22;

  .analysis-wrapper {
    padding: 1rem;

    .menu-dropdown {
      display: block;
      top: 31px;
    }

    .date-container-driver {
      display: flex;
      border: 2px solid #212a54;
      border-radius: 4px;
      height: 2rem;

      .date-fleet {
        color: #bfbfc0;
        margin-right: 0.5rem;
        border: none;

        .date-label {
          color: #ffffff;
          font-family: "daimler_cs_regular";
          font-size: 14px;
          letter-spacing: 0;
          line-height: 26.28px;
        }

        .react-datepicker-wrapper {
          input {
            background: #0a0e22;
            border: none;
            color: #bdbdbd;
            font-family: "daimler_cs_regular";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 26.28px;
          }
        }
      }
    }

    .dropdownDriver {
      margin-right: 0.5rem;
      color: #bfbfc0;
      border: 2px solid #212a54;
      border-radius: 4px;
      width: 12.5rem;
      background: #0f111d;
      /* border: 2px solid grey; */
      height: 2rem;
      padding: 0.2rem;
      position: relative;
    }
  }
}

.card-analysis {
  background-color: #141728;
  height: 17rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.card-analysis-row {
  background-color: #141728;
  border-radius: 5px;
  height: 15rem;

  a:hover {
    text-decoration: none;
  }
}

.card-analysis-events {
  background-color: #141728;
  border-radius: 5px;
  height: 17rem;
}

.analysis-title {
  color: #bdbdbd;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}

.customLis div:first-child:before {
  content: "•";
  color: #5ec957;
}

.customLis div:nth-child(2):before {
  content: "•";
  color: #cfe9fc;
}

.customLis div:last-child:before {
  content: "•";
  color: #fc7f7f;
}

.customLis div:before {
  font-size: 16px;
}

.customLis {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: #fff;
}

.serviceDiv {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  align-items: center;
  color: #fff;
  position: relative;
  bottom: 18px;
}

.grayDiv {
  width: 10px;
  margin-right: 0.3rem;
  border-radius: 5px;
  height: 10px;
  background-color: gray;
}

.orangeDiv {
  width: 10px;
  height: 10px;
  margin-right: 0.3rem;
  border-radius: 5px;
  background-color: orange;
}

.redDiv {
  border-radius: 5px;
  width: 10px;
  margin-right: 0.3rem;
  height: 10px;
  background-color: red;
}

// .serviceDiv div:before{
//   font-size: 16px;
// }

// .serviceDiv div:first-child:before {
//   content: "•";
//   color: gray;
// }

// .serviceDiv div:nth-child(2):before {
//   content: "•";
//   color: orange;
// }

// .serviceDiv div:last-child:before {
//   content: "•";
//   color: red;
// }

.analysis-sub-title {
  color: #bdbdbd;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}

.analysis-sub-title-cnt {
  color: #bdbdbd;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}

.cntInfo {
  display: flex;
  justify-content: space-around;
  padding: 7px;
}

.cntInfo-t {
  display: flex;
  justify-content: space-between;
  padding: 7px;
}

.titleInfo {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  color: #bdbdbd;
}

.numInfo {
  color: #ffffff;
  font-family: CorpoS;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.reportsContainer {
  margin-left: 35rem;
  top: -10px;
}

.report-header {
  height: 4.2rem;
  .active{
    border-bottom: 2px solid #bdbdbd;
  }
}

.report-search-btn {
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black;
}

.uam-button {
  margin-left: 60rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .uam-button {
    margin-left: 24rem;
  }
}

.cntValue {
  color: #f5f5f5;
  font-family: "daimler_cs";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin: 4rem;
}

.reports-icon {
  height: 2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.fuel-title {
  color: #bdbdbd;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 1rem;
  margin-top: 0.3rem;
  width: 10rem;
}

.dr_img {
  zoom: 0.7;
}

.fuel-value {
  color: #bdbdbd;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 2rem;
  margin-top: 0.3rem;
}

.fuel-speed {
  //color:#ff8081;
}

.home-text-analysis {
  color: #4659b9;
  font-weight: bold;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.drivingScrore {
  padding: 2rem;
  // background: url("../images/chart_trip_details.png") no-repeat;
  // position: relative;
  // height: 17rem;
  // top: 2rem;
  // left: 30px;
  // zoom: 0.8;
  // text-align: center;
}

.drivingScoreText {
  position: relative;
  //left: 8.1rem;
  top: -10rem;
  text-align: center;
  //padding-top: 0rem;
}

.drivingValue {
  color: #bdbdbd;
  font-size: 16px;
  font-weight: bold;
}

.drivingCnt {
  text-align: center;
  font-size: 3rem;
  color: #f2d304;
}

.deleteGrp {
  position: relative;
  cursor: pointer;
}

.deletePopUp::before {
  content: "";
  position: absolute;
  top: 78px;
  width: 14px;
  height: 14px;
  background: #fff;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-width: 0 1px 1px 0;
  right: 84px;
}

.newPopUp {
  position: absolute;
  z-index: 1060;
  max-width: 276px;
  top: 0px;
  right: 6.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #141728;
  background-clip: padding-box;
  border: 2px solid #212a54;
  border-radius: 0.3rem;
  transition: opacity 0.15s linear;
}

.deleteHeader {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #141728;
  // border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.newPopUp::before {
  content: "";
  position: absolute;
  top: 14px;
  width: 14px;
  height: 14px;
  background: #141728;
  border-top: 2px solid #212a54;
  border-right: 2px solid #212a54;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  //  border-bott: 1px solid rgba(0, 0, 0, 0.2);
  // border-width: 0 1px 1px 0;
  right: -8px;
}

.group-name-setting:last-child .newPopUp {
  top: -43px;
}

.group-name-setting:only-child .newPopUp {
  top: 0px;
}

.group-name-setting:last-child .newPopUp {
  &:before {
    top: 53px !important;
  }
}

.group-name-setting:only-child .newPopUp {
  &:before {
    top: 14px !important;
  }
}

.deleteBody {
  padding: 0.5rem 0.75rem;
  color: #212529;
  text-align: center;
}

.showDetails {
  padding-bottom: 2rem;
  zoom: 0.9;
}

.showDetailsId {
  padding-bottom: 1rem;
  zoom: 0.9;
  font-weight: bold;
}

#markerDivPlaybackdiv {
  display: flex;

  img {
    height: 18px;
    cursor: pointer;
  }
}

.pop-close {
  height: 18px;
}

.plotImg {
  height: 18px;
}

.gm-style a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.vehicleAlert {
  background: url("../images/offline.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: left top;
  height: 19px;
  width: 18px;
  zoom: 0.7;
}

//new responsive styles
.display-responsive-class {
  display: flex;
}

.mbfleet-3 {
  margin-bottom: 1rem;
}

@media only screen and (min-width: 540px) and (max-width: 720px) and (orientation: landscape) {
  .uam-button {
    margin-left: 24rem !important;
  }

  .subWrapper {
    p {
      font-size: 0.5rem !important;
    }
  }

  .footerList {
    font-size: 0.6rem !important;
  }

  .footerWrapperNotLogged {
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .footerWrapper {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .paginationScroller {
    height: calc(100vh - 9.5rem) !important;
    overflow-y: auto;
  }

  .reponsive-popup {
    max-height: 223px !important;
  }

  #map1 .gm-style .gm-style-iw-c {
    max-width: 440px !important;
    max-height: 300px !important;
  }

  .tempo:before {
    content: unset !important;
  }

  .fleet-truck-dashboard {
    width: 33rem;
    padding-left: 8.7rem;
  }

  .finalContainer {
    left: 38% !important;
  }

  .layoutContainer {
    justify-content: space-between;
  }

  .group-drop-fmt-search-coll {
    width: 11rem;
  }

  .vehicle-scroll-ipad {
    height: 51%;
  }

  #inputIdIpad {
    width: 11rem;
    text-indent: 8px;
    margin-top: 0.5rem;
  }

  .mbfleet-3 {
    margin-bottom: 0.2rem;
  }

  .panel-height {
    height: 80vh;
  }

  .small-map {
    margin-right: 0.7rem;
  }

  .leftSidePanelIpad {
    height: 4rem;
  }

  .setting-inner-left-panel {
    font-size: 11px;
  }

  .image-center {
    text-align: center;
  }

  .drivingScrore {
    padding: 1rem;
  }

  .drivingScoreText {
    top: -12rem;
  }

  .idling-container-new {
    display: flex;
    justify-content: space-around;
    padding: 7px;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  #alertReport-new .navTab {
    margin-left: 0;

    width: 100%;
  }

  #alertReport-new .navTab ul li {
    font-size: 9px;
    padding: 0.2rem 0.2rem;
  }

  .chart-zoom {
    right: 4.8rem;
    position: relative;
  }

  #map-daily {
    height: 50.4vh !important;
    border-radius: 1rem;
  }

  .setting-right-panel {
    .setting-inner-right-panel {
      .accordionContainer {
        .setAlerts {
          margin-left: 1rem;
          overflow-y: auto;
          height: calc(100vh - 13rem);

          &~.form-group {
            padding-top: 0px;
          }
        }

        .accordionContent {

          .refresh-heading,
          .refresh-input {
            left: 32%;
            top: 17%;
          }

          .statusContainer {
            .maintain-title {
              label {
                font-size: 11px;
              }
            }
          }
        }

        .rightcol {
          .accordionContent {
            .tableContainer {
              .group-setting-heading {
                font-size: 11px;
              }

              .scroll-group {
                height: calc(100vh - 26rem);
                font-size: 11px;

                .group-name-setting {
                  .newPopUp {
                    right: 3rem;
                    //transform: translate(153px, -100px);
                  }

                  .newPopUp::before {
                    top: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .carousel-dtc-code {
    justify-content: space-evenly;
    background-color: #0a0e22;
    border-radius: 4px;
    padding: 0.2rem;
  }

  .carousel-description {
    margin-left: 0;
    font-size: 13px;
  }

  .carousel-custom-wrapper {
    display: block;
    color: white;
    align-items: center;
  }

  body .conentWrapper {
    height: calc(100vh - 19rem);
    overflow-x: hidden;
  }

  .card-container-health {
    height: calc(100vh - 19rem) !important;
  }

  .health-wrapper {
    .display-responsive-class {
      .health-indicator {
        margin-right: 0.4rem;
      }

      .health-indicator,
      .health-carousel {
        width: 50%;
      }
    }
  }

  .imm-text {
    padding-top: 2rem !important;
    padding-left: 0.5rem !important;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size: 12px !important;
  }

  .screw-img {
    padding-right: 1.5rem !important;
    padding-top: 0.8rem !important;
    height: 2.5rem !important;
    margin-top: 20px !important;
    padding-left: 0.5rem !important;
  }

  .imm-img {
    width: 25%;
    border-top-right-radius: 25px;
    height: 5.8rem !important;
    background-color: #495583;
    border-bottom-right-radius: 25px;
  }

  .serviceText {
    font-size: 14px;
  }

  .liveNotification-image {
    right: 0rem;
  }

  .fleetVehicles {
    width: 20%;
    margin-right: 0.4rem;

    .dropdown {
      width: 8rem !important;
    }
  }

  .fleetFrom {
    width: 20%;

    .date-fleet-health {
      .react-datepicker-wrapper {
        padding-left: 0.1rem !important;

        .react-datepicker__input-container {
          font-size: 12px !important;
        }
      }

      .date-label {
        font-size: 10px;
        padding-top: 0.3rem !important;
      }
    }
  }

  .fleetTo {
    width: 20%;

    .date-fleet-health {
      .react-datepicker-wrapper {
        padding-left: 0.1rem !important;

        .react-datepicker__input-container {
          font-size: 12px !important;
        }
      }

      .date-label {
        font-size: 10px;
        padding-top: 0.3rem !important;
      }
    }
  }

  .each-card-width {
    width: 30%;
  }

  .severity-block {
    left: 1rem;
  }

  .fleetButtons {
    width: 20%;
    margin-left: 1rem;

    .health-btn {
      width: 3rem;
      font-size: 10px;
    }
  }

  .reportFleet {
    width: 20%;

    .pdf-icon-newsite {
      padding-left: 0.1rem;
    }

    .excel-icon-newsite {
      margin-right: 0rem;
      padding-left: 0.1rem;
    }
  }

  .adminsProfile {
    margin-bottom: 0.5rem;

    .VEHICLE,
    .DRIVER,
    .DEALER {
      padding: 0.7rem;
    }

    .CUSTOMER {
      padding: 0.7rem;
    }
  }

  .header-reports-new {
    font-size: 10px;
  }

  .create-customer-btn {
    font-size: 10px;
    padding: 0.3rem;
    margin: auto;
    margin-right: 0.2rem;
  }

  .inputField-profile {
    width: 5rem;
  }

  // .display-responsive-class{
  //   display:block;
  // }

  .no-dtc-health {
    padding-left: 0;
    font-weight: bold;
    padding-left: 0;
    color: #bfbfc0;
  }

  .health-carousel {
    height: 6rem;
    border-radius: 5px;
    width: 100%;
    background-color: #212a54;
  }

  .imm-img {
    width: 30%;
    border-top-right-radius: 25px;
    height: 6rem;
    background-color: #495583;
    border-bottom-right-radius: 25px;
  }

  .health-indicator {
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 5px;
    height: 6rem;
    background-color: #212a54;
    margin-right: 0;
  }

  // .each-card-width {
  //   color: #bfbfc0;
  //   margin: 0.5rem;
  //   width: 100%;
  //   height: 13rem;
  //   padding: 1rem;
  //   background-color: #141728;
  //   margin-bottom: 1.5rem;
  // }
  // .card-container-health {
  //   height: 26vh;
  //   overflow-y: scroll;
  // }

  .filter-container-analysis {
    display: block !important;
  }

  .card-analysis-row {
    margin-bottom: 1rem;
  }

  .drivingScrore {
    left: 20px;
  }

  .card-analysis {
    height: 17.5rem;

    .drivingScoreText {
      //  left: 7.3rem;
    }

    .speeding-graph-new {
      display: flex;
      justify-content: space-around;

      .circle-width-new {
        width: 32%;
        margin: 0 !important;
      }

      .circle-width-new1 {
        width: 32%;
        margin: 0 !important;
      }

      .cntInfo {
        display: flex;
        justify-content: space-around;
        padding: 7px;
        text-align: center;
        align-items: center;
      }

      .cntInfo {
        .numInfo {
          margin-left: 4px;
        }
      }
    }
  }

  .cntInfo-t {
    .numInfo {
      margin-left: 4px;
    }
  }

  .analysisContent {
    .pl-0-new {
      padding-left: 15px !important;
    }

    .pl-0-pr-0 {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }

    .pr-0-new {
      padding-right: 15px !important;
    }
  }

  .owner-wrapper-analysis {
    height: calc(100vh - 3rem) !important;
    overflow: auto;
  }

  .secondRow {
    .pl-0-pr-0 {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  .main-wrapper {
    .serviceInfoRemainder {
      top: 6rem;
      left: 3.5rem;
      width: 91%;
    }
  }
}

@media screen and (max-width: 736px) {
  .footerWrapperNotLogged {
    margin: 0;
    display: flex;
  }
}

@media screen and (max-width: 540px) {
  .uam-button {
    margin-left: 15rem !important;
  }

  .subWrapper {
    display: block;
  }

  .footerWrapperNotLogged {
    margin: 0;
    display: flex;
  }

  .paginationScroller {
    height: calc(100vh - 12rem) !important;
    overflow-y: auto;
  }

  .reg-width {
    white-space: break-spaces;
    word-break: break-all;
  }

  .vehicleDetails {
    display: block !important;
  }

  .reponsive-popup {
    max-height: 273px;
  }

  #map1 .gm-style .gm-style-iw-c {
    max-width: 307px !important;
  }

  .tempo:before {
    content: unset !important;
  }

  .mbfleet-3 {
    margin-bottom: 0.2rem;
  }

  .circle-width-new1 {
    width: 45%;
    margin: 0 !important;
    //  margin-left: 5rem !important;
    // margin-right: 12rem !important;
  }

  .show-button {
    font-size: 0.4rem;
  }

  .vehicle-scroll-ipad {
    height: 21rem;
  }

  .leftSidePanelIpad {
    height: 4.9rem;
  }

  .panel-height {
    height: 82vh;
  }

  .group-drop-fmt-search-coll {
    top: 3.7rem;
    width: 8.5rem;
    word-break: break-word;
  }

  #inputIdIpad {
    width: 8.5rem;
  }

  .expnd {
    width: 32%;
  }

  .small-map {
    margin-right: 0.2rem;
  }

  .cntInfo {
    display: flex;
    justify-content: space-around;
    padding: 7px;
    text-align: center;
    align-items: center;
  }

  .idling-container-new {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    text-align: left;
    align-items: start;
    justify-content: space-around;
  }

  .circle-width-new {
    width: 45% !important;
    margin: 0 !important;
    //  margin-left: 5rem !important;
    // margin-right: 12rem !important;
  }

  .speeding-graph-new {
    display: flex;
    justify-content: space-around;
  }

  .image-center {
    text-align: center;
  }

  .drivingScrore {
    padding: 1rem;
  }

  .drivingScoreText {
    top: -12rem;
  }

  .drivingValue {
    font-size: 20px;
  }

  .cntValue {
    font-size: 22px;
  }

  .analysis-title {
    font-size: 20px;
  }

  .analysis-sub-title {
    font-size: 20px;
  }

  .analysis-sub-title-cnt {
    font-size: 20px;
  }

  .titleInfo {
    font-size: 20px;
    padding-right: 2rem;
  }

  .fleet-truck-dashboard {
    width: 26rem;
  }

  .chart-flex {
    display: block;
  }

  .end-trp {
    width: 22% !important;
  }

  .each-width {
    width: 100%;
    margin-bottom: 0.3rem;
  }

  .each-width1 {
    width: 100%;
  }

  body .conentWrapper {
    height: calc(100vh - 21rem);
    overflow-x: hidden;
  }

  .setting-inner-left-panel {
    font-size: 11px;
  }

  .setting-right-panel {
    .setting-inner-right-panel {
      .accordionContainer {
        .setAlerts {
          margin-left: 1rem;

          &~.form-group {
            padding-top: 0px;
          }
        }

        .accordionContent {

          .refresh-heading,
          .refresh-input {
            left: 32%;
          }

          .statusContainer {
            .maintain-title {
              label {
                font-size: 11px;
              }
            }
          }
        }

        .rightcol {
          .accordionContent {
            .tableContainer {
              .group-setting-heading {
                font-size: 11px;
              }

              .scroll-group {
                height: calc(100vh - 27rem);
                font-size: 11px;

                .group-name-setting {
                  .newPopUp {
                    right: 2.5rem;
                    //transform: translate(97px, -100px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .card-container-health {
    height: calc(100vh - 21rem) !important;
  }

  .carousel-dtc-code {
    justify-content: space-evenly;
    background-color: #0a0e22;
    border-radius: 4px;
    padding: 0.2rem;
  }

  .carousel-description {
    margin-left: 0;
    font-size: 13px;
  }

  .carousel-custom-wrapper {
    display: block;
    color: white;
    align-items: center;
  }

  .fleetVehicles {
    width: 20%;
    font-size: 9px;

    .dropdown {
      width: 5rem;
      padding-top: 0.5rem;
    }
  }

  .fleetFrom {
    width: 20%;

    .date-fleet-health {
      .react-datepicker-wrapper {
        padding-left: 0.1rem !important;

        .react-datepicker__input-container {
          font-size: 8px !important;
        }
      }

      .date-label {
        font-size: 7px;
        padding-top: 0.5rem !important;
      }
    }
  }

  .fleetTo {
    width: 20%;

    .date-fleet-health {
      .react-datepicker-wrapper {
        padding-left: 0.1rem !important;

        .react-datepicker__input-container {
          font-size: 8px !important;
        }
      }

      .date-label {
        font-size: 7px;
        padding-top: 0.5rem !important;
      }
    }
  }

  .fleetButtons {
    width: 20%;
    margin-left: 0.9rem;
    margin-top: 0.3rem;
    zoom: 0.7;

    .health-btn {
      width: 3rem;
      font-size: 10px;
    }
  }

  .reportFleet {
    width: 20%;

    .pdf-icon-newsite {
      padding-left: 0.1rem;
      zoom: 0.7;
    }

    .excel-icon-newsite {
      margin-right: 0rem;
      zoom: 0.7;
      padding-left: 0.1rem;
    }
  }

  .health-wrapper {
    .display-responsive-class {
      .health-indicator {
        margin-right: 0.4rem;
      }

      .health-indicator,
      .health-carousel {
        width: 50%;
      }
    }
  }

  .imm-text {
    padding-top: 2rem !important;
    padding-left: 0.5rem !important;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size: 10px !important;
  }

  .screw-img {
    padding-right: 1.5rem !important;
    padding-top: 0.8rem !important;
    height: 2.5rem !important;
    margin-top: 20px !important;
    padding-left: 0.1rem !important;
  }

  .imm-img {
    width: 25%;
    border-top-right-radius: 25px;
    height: 5.8rem !important;
    background-color: #495583;
    border-bottom-right-radius: 25px;
  }

  .serviceText {
    font-size: 13px;
  }

  #alertReport-new .navTab {
    margin-left: 0rem;
    width: 100% !important;

    ul {
      li {
        font-size: 10px;
        text-align: center;
        padding: 0.2rem 0.2rem !important;
      }
    }
  }

  .reports {
    .reportFilters {
      .report-filter-wrapper-new {
        display: block;
      }

      .form-group {
        .dropdown {
          width: 47%;
        }
      }
    }
  }

  .dateWidths {
    width: 50%;
  }

  .header-reports-new {
    font-size: 10px;
    zoom: 0.8;
  }

  .adminsProfile {
    margin-bottom: 0.5rem;

    .VEHICLE,
    .DRIVER {
      padding: 0.7rem !important;
    }
  }

  .header-profile {
    height: auto;
    text-align: center;
  }

  .create-customer-btn {
    font-size: 10px;
    padding: 0.3rem;
    margin: auto;
    margin-right: 0.2rem;
  }

  .inputField-profile {
    width: 5rem;
  }

  .pdf-icon-newsite,
  .excel-icon-newsite {
    padding-left: 0.1rem;
  }

  .btn-submit-dash {
    height: 2.2rem;
  }

  .liveNotification-image {
    right: 0rem;
  }

  .CircularProgressbarC {
    width: 17%;
  }

  .display-responsive-class {
    //display: block;
  }

  .no-dtc-health {
    padding-left: 0;
    font-weight: bold;
    padding-left: 0;
    color: #bfbfc0;
  }

  .health-carousel {
    height: 6rem;
    border-radius: 5px;
    width: 100%;
    background-color: #212a54;
  }

  .imm-img {
    width: 30%;
    border-top-right-radius: 25px;
    height: 6rem;
    background-color: #495583;
    border-bottom-right-radius: 25px;
  }

  .health-indicator {
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 5px;
    height: 6rem;
    background-color: #212a54;
    margin-right: 0;
  }

  .each-card-width {
    color: #bfbfc0;
    margin: 0.5rem;
    width: 100%;
    height: 13rem;
    padding: 1rem;
    background-color: #141728;
    margin-bottom: 1.5rem;
  }

  // .card-container-health {
  //   height: 26vh;
  //   overflow-y: scroll;
  // }

  .customLi {
    ul {
      margin-left: 4rem;
    }
  }

  .drivingScrore {
    left: 20px;
  }

  .filter-container-analysis {
    display: block !important;
  }

  .card-analysis-row {
    margin-bottom: 1rem;
  }

  .card-analysis {
    height: 17.5rem;

    .drivingScoreText {
      //   left: 7.3rem;
    }

    .speeding-graph-new {
      display: flex;

      .cntInfo {
        .numInfo {
          margin-left: 4px;
        }
      }
    }
  }

  .cntInfo-t {
    .numInfo {
      margin-left: 4px;
    }
  }

  .analysisContent {
    .pl-0-new {
      padding-left: 15px !important;
    }

    .pl-0-pr-0 {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }

    .pr-0-new {
      padding-right: 15px !important;
    }
  }

  .owner-wrapper-analysis {
    height: calc(100vh - 3rem) !important;
    overflow: auto;
  }

  .secondRow {
    .pl-0-pr-0 {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  .main-wrapper {
    .serviceInfoRemainder {
      top: 17rem;
      left: 3.5rem;
      width: 88%;

      .serviceInfoContainer {
        .serviceScroll {
          height: calc(100vh - 33rem);
          overflow: auto;
        }
      }
    }
  }
}

.idling-container-new {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  text-align: left;
  align-items: start;
}

.toast {
  background-color: #030303 !important;

  .toast-close-button {
    top:3%;
  }
}

.toast-success {
  background-color: #51a351 !important;
}

.toast-error {
  background-color: #bd362f !important;
}

.toast-info {
  background-color: #2f96b4 !important;
}

.toast-warning {
  background-color: #f89406 !important;
}

.tag-message {
  display: grid;
}

.driver_profile_new {
  .pop-up {
    form {
      .rightwrapper {
        .form-group {
          .ant-calendar-picker {
            width: 100%;

            .ant-input {
              background-color: transparent !important;
            }

            .ant-calendar-picker-icon {
              color: #bdbdbd;
            }

            .ant-input::placeholder {
              color: #bdbdbd !important;
            }
          }
        }
      }
    }
  }
}

.notAdminProfile {
  float: right;
  margin-bottom: 1rem;
}

.dueSoon,
.overDue {
  border-bottom: 1px solid #00b7ff;
  color: #00b7ff;
}

.dueSoonService,
.overDueSerive {
  cursor: pointer;
}

.serviceRemainderDiv {
  position: relative;
}

.serviceInfoRemainder {
  position: fixed;
  padding: 0px 10px;
  bottom: 5%;
  z-index: 9;
  right: 2%;
  width: 600px;
}
.popservice span{color:#00b7ff}
.serviceInfoRemainder .serviceInfoContainer {
  background-color: #0b0c16;
  color: #bdbdbd;
  width: 100%;
  max-width: 730px;
  margin: 30px auto;
  padding: 30px 20px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;

  .serviceLoading {
    text-align: center;
    height: 12.6rem;
  }

  .serviceLoading1 {
    text-align: center;
  }

  .serviceContent {
    display: flex;
    align-items: center;

    .serviceCheckBox {
      display: flex;
      align-items: center;
      padding: 0 10px 0 0;

      input[type="checkbox"] {
        -webkit-appearance: none;
        width: 18px;
        background-repeat: no-repeat;
        height: 12px;
        background: url("../images/Tick_inactive.png");
        background-size: 100%;
        cursor: pointer;
      }
      input[type="checkbox"]+ span {
        color: #98a5a9;
      }
      input[type="checkbox"]:checked {
        background: url("../images/Tick_active.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
      input[type="checkbox"]:checked + span {
        color: #0a80c8;
      }
    }

    .serviceRegID {
      width: 30%;
      font-size: 16px;
      line-height: 18px;
      font-family: "daimler_cs_regular";
    }

    span {
      width: 5%;
    }
    .checkmark {
      padding: 0 0 0 8px;
    }
    .serviceDue {
      width: 60%;
      font-size: 16px;
      line-height: 18px;
      font-family: "daimler_cs_regular";
    }
  }
}

.serviceHeading {
  position: relative;
  @include flexbox();
  background-color: transparent;
  color: #bdbdbd;
  @include justify-content(space-between);
  @include align-items(center);
  border: 0;
  margin-top: -15px;
  margin-bottom: 2rem;

  .remainderText {
    font-size: 16px;
    line-height: 22px;
    margin-top: 10px;
    font-family: "daimler_cs_bold";
  }

  span {
    font-size: 16px;
    line-height: 22px;
    margin: 0 15px;
    padding: 15px 0px;
    margin-top: 10px;
    font-family: "daimler_cs_bold";
  }
}


.cancelService {
  display: flex;
  position: absolute;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black !important;
  border: none;
  outline: none;
  top: -10px;
  justify-content: center;
  right: -10px;
  /* font-size: 17px; */
  padding: 1.5px 7px;
  width: 30px;
  height: 30px;

  span {
    margin: auto !important;
    padding: 0px !important;
    color: black !important;
  }
}
.popcancelService{
  display: flex;
  position: absolute;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  border: none;
  outline: none;
  top: 15px;
  justify-content: center;
  align-items: center;
  right: 15px;
  padding: 1.5px 7px;
  width: 30px;
  height: 30px;
}

.popservicecheck input{cursor: pointer;}





.serviceNowButton {
  text-align: right;
  
  .btn-submit-service {
    padding: 0.5rem;
    border-radius: 5px;
    border: none;
    outline: none;
    background: -webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f);
    //background-color: #cacaca;
    //background: linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%);
    color: #000000;
    font-family: "daimler_cs_regular";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17.28px;
  }
  .btn-submit-dash-theme {
    background:#1F3276;
    color:#fff;
  }
}


.btn-submit-service:disabled {
  cursor: not-allowed;
}
.popserviceNowButton {
  text-align: right;
  margin-right: 1rem;
}
.primary-btn:disabled{
  cursor: not-allowed;
}
.serviceScroll {
  height: 12.6rem;
  overflow-y: auto;
}

.serviceScroll::-webkit-scrollbar-track {
  background: #42486e;
}

.serviceScroll::-webkit-scrollbar-thumb {
  background-color: #42486e;
}

.padding-driving-new {
  padding: 1.5rem;
}

.margin-rpm-speed {
  margin-left: 90px;
}

.circle-width-new {
  width: 50%;
}

.circle-width-new1 {
  width: 45%;
}

@media all and (max-width: 1555px) {
  .pl-0-new {
    padding-left: 0 !important;
  }

  .pr-0-new {
    padding-right: 0 !important;
  }

  .pl-0-pr-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #inputIdIpad {
    width: 16.5rem;
    text-indent: 8px;
    margin-top: 1rem;
  }

  .vehicle-scroll-ipad {
    height: 71%;
  }

  .leftSidePanelIpad {
    background-color: #141728;
    margin-left: 0.5rem;
    margin-bottom: 0.7rem;
    margin-right: 0.5rem;
    border-radius: 5%;
    height: 5rem;
    color: white;
    cursor: pointer;
    padding-left: 0.2rem;
  }

  .drivingScrore {
    padding: 0.3rem;
    padding-top: 2rem;
  }

  .reportsContainer {
    margin-left: 1rem;
    top: -10px;
  }

  .pl-0-new {
    padding-left: 0 !important;
  }

  .pr-0-new {
    padding-right: 0 !important;
  }

  .pl-0-pr-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .margin-rpm-speed {
    margin-left: 50px;
  }

  .serviceDiv {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    align-items: center;
    color: #fff;
    position: relative;
    bottom: 40px;
  }

  .drivingScoreText {
    position: relative;
    //left: 8.1rem;
    top: -6rem;
    text-align: center;

    .drivingValue {
      font-size: 16px;
    }

    .drivingCnt {
      font-size: 2rem;
    }
  }

  .padding-driving-new {
    padding: 0.3rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .footerWrapperNotLogged {
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .footerWrapper {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .image-center {
    text-align: center;
  }

  #map1 .gm-style .gm-style-iw-c {
    max-width: 409px !important;
  }

  .reg-width {
    white-space: break-spaces;
    word-break: break-all;
  }

  .newPlayBackDiv {
    display: block !important;
  }

  .group-drop-fmt-search-coll {
    width: 11.5rem;
    top: 3.7rem;
  }

  .panel-height {
    height: 89%;
  }

  .vehicle-scroll-ipad {
    height: 76%;
  }

  .drivingScrore {
    padding: 1rem;
  }

  .drivingScoreText {
    top: -12rem;
  }

  .drivingValue {
    font-size: 25px;
  }

  .cntValue {
    font-size: 22px;
  }

  .analysis-title {
    font-size: 26px;
  }

  .titleInfo {
    font-size: 22px;
    padding-right: 2rem;
  }

  .numInfo {
    font-size: 22px;
  }

  .idling-container-new {
    display: flex;
    justify-content: space-around;
    padding: 7px;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  .fuel-title {
    font-size: 22px;
    width: 20rem;
    margin-left: 2rem;
  }

  .analysis-sub-title {
    font-size: 25px;
  }

  .analysis-sub-title-cnt {
    font-size: 25px;
  }

  .f-22 {
    font-size: 22px;
  }

  .fuel-value {
    font-size: 22px;
    font-weight: normal;
  }

  .reportsContainer {
    margin-left: 1rem;
    top: -10px;
  }

  .card-analysis-row {
    background-color: #141728;
    border-radius: 5px;
    height: 15rem;
    margin-bottom: 1rem;
  }

  .pl-0-pr-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pr-0-new {
    padding-right: 15px !important;
  }

  .circle-width-new {
    width: 30%;
    margin: 0 !important;
    // margin-left: 5rem !important;
    // margin-right: 12rem !important;
  }

  .pl-0-new {
    padding-left: 15px !important;
  }

  .circle-width-new1 {
    width: 30%;
    margin: 0 !important;
    //  margin-left: 5rem !important;
    // margin-right: 12rem !important;
  }

  .speeding-graph-new {
    display: flex;
    justify-content: space-around;
  }

  .font-driving-new {
    font-size: 30px !important;
  }

  .drivingScoreText {
    // position: absolute;
    // left: 8rem;
    // top: 4rem;
    // text-align: center;
    // padding-top: 0rem;
  }

  .owner-wrapper-analysis {
    height: 95vh;
    overflow-y: scroll;
    margin-left: 3.5rem;
    background: #0a0e22;
  }

  .btn-container {
    display: block;
  }

  .cntInfo {
    display: flex;
    justify-content: space-around;
    padding: 7px;
    text-align: center;
    align-items: center;
  }
}

.share-icon,
.satellite-icon,
.map-option-icon {
  position: absolute;
  opacity: 1;
  text-align: center;
  z-index: 9999;
  bottom: 10px;
  height: 37px;
  cursor: pointer;
}

.satellite-icon {
  left: 70px;
}

.map-option-icon {
  left: 20px;
}

.map_theme_outerWraper {
  max-width: 100px !important;
}

.theme_wraper {
  background: #dee7ec;
  border-radius: 100px;

  &.onLoad {
    position: absolute;
    top: 35px;
    left: 33px;
    width: 100%;
    max-width: 65px;
  }
}

.option-icon {
  &.light {
    background-image: url(../images/light_icon_inactive.png);

    &.enable {
      background-image: url(../images/light_icon.png);
    }
  }

  &.dark {
    background-image: url(../images/Dark_icon_inactive.png);

    &.enable {
      background-image: url(../images/Dark_icon.png);
    }
  }

  &.share {
    background-image: url(../images/share-icon2.png);
    height: 30px;
    width: 30px;
    z-index: 0;
  }

  background-size: 100% auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  text-align: center;
  z-index: 9999;
  display: inline-block;
  width: 50%;
  height: 37px;
  cursor: pointer;
  vertical-align: middle;
}

.control-image {
  height: 3rem;
  width: 3rem;
  zoom: 0.7;
}

.gm-fullscreen-control {
  zoom: 0.7;
}

.filter-container-analysis {
  display: flex;
}

.info-window {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  z-index: 1;
  font-size: smaller;
}

//tablet 10 inch screen
@media only screen and (device-width: 1280px) and (device-height: 800px) and (orientation: landscape) {
  .uam-button {
    margin-left: 50rem !important;
  }

  .footerWrapperNotLogged {
    margin: 0;
    display: flex;
  }

  .footerWrapper {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .legend-insight {
    margin-left: 1rem !important;
  }

  .pie-chart-container {
    width: 0 !important;
    margin-left: 0rem !important;
    left: -12rem !important;
    zoom: 0.8;
    position: relative !important;
    background: transparent !important;
  }

  .test-class {
    width: 10rem !important;
  }

  .serviceInfoRemainder {
    left: 20rem;
    width: 90%;
  }

  .newPopUp {
    right: 5.5rem;
  }

  .d-flex-responsive {
    display: block;
  }

  .finalContainer {
    display: block;
  }

  .reg-width {
    margin-bottom: 0.2rem;
    word-break: break-all;
    white-space: break-spaces;
  }

  .finalContainer .layoutContainer {
    margin-left: 0;
  }

  .fmt-scroll {
    // height: 68% !important;
  }

  .leftSidePanel {
    height: 6rem;
  }

  .group-drop-fmt-search-coll {
    word-break: break-all;
    width: 10.5rem;
  }

  .group-drop-fmt-search {
    left: 16.8rem;
  }

  #inputId {
    width: 10.5rem;
  }

  .relative-speed-class {
    position: relative;
    left: -0.6rem;
  }

  .relative-rpm-class {
    position: relative;
    left: -0.3rem;
  }

  .drivingScrore {
    // zoom: 0.7;
    padding: 0rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 2rem;
  }

  .card-analysis {
    .drivingScoreText {
      // left: 7.5rem;
      top: -8rem;

      .drivingValue {
        //  font-size: 14px;
      }

      .drivingCnt {
        font-size: 2rem;
      }
    }
  }
}

@media only screen and (device-width: 800px) and (device-height: 1280px) and (orientation: portrait) {
  .main-wrapper {
    .serviceInfoRemainder {
      top: 53rem;
      left: 3rem;
      width: 93%;
    }
  }

  .conentWrapper {
    height: calc(100vh - 12rem);
  }

  #map-daily {
    height: 21.4vh;
  }

  .drivingScrore {
    padding: 1rem;
  }

  .image-center {
    text-align: center;
  }

  .drivingScoreText {
    top: -12rem;
  }
}

.pie-chart-container {
  width: 50%;
  margin-left: 0rem;
}

.test-class {
  height: 143px;
  width: 140px;
}

.paginationScroller {
  height: calc(100vh - 12rem);
  overflow-y: auto;
}

.helpCarousel_guide {
  .BrainhubCarousel__dots {
    flex-direction: row !important;
  }
}

.left-arrow-guide {
  background: url(../images/arrow-left.png) no-repeat;
  height: 28px;
  width: 21px;
  margin-right: 10px;
  zoom: 0.8;
  cursor: pointer;
}

.right-arrow-guide {
  background: url(../images/right-arrow.png) no-repeat;
  height: 28px;
  width: 21px;
  margin-left: 10px;
  zoom: 0.8;
  cursor: pointer;
}

.noTextFoundFmt {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: #bdbdbd;
}

.distance-alert {
  background-color: #141728;
  color: #616472;
  font-size: 10px;
  padding: 0.5rem;
  width: 20rem;
  border-radius: 5px;
  font-weight: bold;
  text-align: justify;
}

.truck-performance-loading {
  color: #bdbdbd;
  font-weight: bold;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.helpScreenContainer {
  position: relative;

  // height: 111vh;
  // margin-top: -80px;
  .helpScreenWrapper {
    position: absolute;

    @include flex-direction(row);
    background: #0a0e22;

    // top: 25%;
    // left: 25%;
    // bottom: 16%;
    // width: 72%;
    // max-width: 850px;
  }
}

.next-btn-demo {
  position: absolute;
  bottom: 5rem;
  right: 2rem;
  width: 6rem;
  padding: 0.4rem;
  border-radius: 8px;
  outline: none;
  background-color: #0f3378;
  color: #bdbdbd;
  font-weight: bold;
  border: 1px solid #081c32;
  box-shadow: 0px 0px 27px #081c32;
}

.next-btn-demo:focus {
  outline: none;
  box-shadow: 0px 0px 27px #00677f;
}

.demo-image {
  position: relative;
}

.next-btn-demo:hover {
  box-shadow: 5px 10px 20px #081c32 inset;
}

.driver-profiles {
  .ant-input {
    color: #bdbdbd;
  }
}

.refreshDisabled {
  pointer-events: none;
}

.disable-btn-style {
  cursor: not-allowed;
}

.w3w-fleet {
  background-color: #081c32;
  color: white;
  width: fit-content;
  cursor: pointer;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 30px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: 4px;
}

.radial-no-data-support {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  color: #bdbdbd;
  top: 50%;
  left: 50%;
}

.supportUser {
  cursor: not-allowed;
}

#notDisabled {
  cursor: pointer;
}

#disableid {
  cursor: not-allowed;
}

.loadingMap {
  overflow: hidden;
  padding-bottom: 8.7rem;
  height: 0;
  font-weight: bold;
  position: relative;
  left: 50%;
  transform: translate(-14%, 28%);
  color: #bdbdbd;
}

.userManagementWrapper {
  width: 40%;
  padding-left: 0.75rem;
}

.uamPanel {
  background: #0b1023;
  @include border-radius(0px 5px 5px 0px);
  padding: rem(10) rem(12) rem(20);
  @include transition(0.3s all ease);

  .panel_container {
    @include border-radius(4px);
    //  border: 1px solid #ddd;
    padding-bottom: rem(25);

    .tableWrapper {
      overflow: auto;
      height: 145px;

      thead {
        th {
          position: sticky;
          top: 0;
          background-color: #131c34;
          color: white;
        }
      }

      tbody {
        th {
          position: sticky;
          left: 0;
        }
      }
    }
  }
}

.userManagementWrapper {
  .form-group {
    .dropdown {
      display: inline-block;
      position: relative;
      width: 100%;

      button {
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #a3a9c4;
        background-color: transparent;
        background-image: none;
        border: 2px solid #212a54;
        border-radius: 4px;
        text-align: left;
        cursor: pointer;

        i {
          float: right;
          color: #bdbdbd;
          font-size: 11px;
          top: 4px;
        }

        &:focus {
          outline: none;
          border-color: #212a54 !important;
        }

        &:hover {
          color: #a3a9c4;
          background-color: #20284c;
          border-color: #212a54;
        }
      }

      &.open {
        button {
          @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
          color: #333;
          background-color: transparent;
          border-color: #212a54;
          color: #bdbdbd;
        }

        button:focus {
          outline: none;
          border-color: #212a54 !important;
        }

        .menu-dropdown {
          display: block;
          z-index: 2;
          width: 100%;
        }
      }
    }
  }
}

.warningMessage {
  background: #212a54;
  font-family: "daimler_cs_regular";
  font-weight: bold;
  line-height: 1.5;
  padding: 4px;
  margin: auto;
  color: white;
}

.link-demo {
  a {
    color: #656a82;
  }

  a:hover {
    color: #656a82;
    text-decoration: none;
  }
}

.previous-btn-demo {
  position: absolute;
  bottom: 5rem;
  left: 26rem;
  width: 6rem;
  padding: 0.4rem;
  border-radius: 8px;
  outline: none;
  background-color: #0f3378;
  color: #bdbdbd;
  font-weight: bold;
  border: 1px solid #081c32;
  box-shadow: 0px 0px 27px #081c32;
}

.previous-btn-demo:focus {
  outline: none;
  box-shadow: 0px 0px 27px #00677f;
}

.previous-btn-demo:hover {
  box-shadow: 5px 10px 20px #081c32 inset;
}

#map1 button.gm-ui-hover-effect:active {
  outline: none !important;
}

.iframew3w {
  height: 100%;
  width: 100%;
}

.create-trip-w3w {
  height: auto;
  padding: 10px 15px;
  border: none;
  padding-top: 0px;
  font-family: "daimler_cs_regular";
  font-size: 0.85rem;
  line-height: inherit;
}

.w3wImage {
  zoom: 0.8;
  cursor: pointer;
}

.justify-evenly {
  justify-content: space-evenly;
}

.cancelW3w {
  display: flex;
  position: absolute;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background: -webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%);
  color: black;
  border: none;
  top: -22px;
  justify-content: center;
  right: -41px;
  /* font-size: 17px; */
  padding: 1.5px 7px;
  width: 25px;
  height: 27px;

  span {
    margin: auto;
  }
}

.cancelW3w {
  button:focus {
    outline: none !important;
  }
}

button:focus {
  outline: none !important;
}

.hiddenClass {
  display: none;
}

.blockClass {
  display: block;
}

.plus-icon {
  margin-left: 0.5rem;
  zoom: 0.8;
  cursor: pointer;
}

.labelIcon {
  background: white;
  padding: 5px;
  border-radius: 24px;
}

.checkboxTitle {
  cursor: pointer;
  margin: 0 !important;
  font-size: 11px !important;
  font-weight: bold;
}

.batch-filter-block {
  input[type="checkbox"] {
    -webkit-appearance: none;
    background-repeat: no-repeat;
    height: 6px;
    background: url("../images/mark-disable-reports.png");
  }

  input[type="checkbox"]:checked {
    cursor: pointer;
    background: url("../images/tick-mark-reports.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  display: flex;
  color: white;
  border-radius: 3px;
  font-size: 10px;
  background-color: #0a0e22;
  margin: 2.5px;
  padding: 0.2rem;
  align-items: center;
  min-width: 5rem;
  justify-content: center;
  height: auto;
}

.batch-wrapper {
  margin-left: 7px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.batch-inputbox {
  margin: 2px 5px !important;
  padding: 2px 5px !important;
}

#batch-menu-drop {
  min-width: 270px;
  max-height: 270px;
}

.vehicle-text {
  color: white;
  margin-left: 10px;
  margin-bottom: 5px;
  font-size: 0.8rem;
}

.markersLocation {
  background-color: #102b52;
  border-radius: 24px;
  padding: 15px 15px 15px 25px;
  background-image: url("../images/map_icon.png");
  background-repeat: no-repeat;
  background-position: left 10px center;
  text-align: center;
}

@media only screen and (max-device-width: 1024px) {
  .finalContainer {
    font-size: 13px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .finalContainer {
    &>div:first-child {
      position: relative;
      display: inline;
    }

    >div {
      .align-items-center.p-0.col-md-1 {
        max-width: 100px !important;
      }
    }

    .date-wraper {
      display: block !important;

      &>div:first-child {
        max-width: 100%;
      }

      &>div:nth-child(2) {
        width: 100%;
        max-width: 100%;
        left: -15px;
      }
    }

    .newplayBackContainer {
      position: absolute;
      top: -37px;
      left: 100px;
    }
  }
}

.alertWarningMsg {
  font-size: 0.75rem;
  font-weight: 700;
  color: #ff4500;
  text-align: left;
  padding-top: 2px;
  margin: 0;
}

.fieldErrorBorder {
  border-color: #ff4500 !important;
}

.loginInformation {
  margin: auto 15px auto auto;
  text-transform: capitalize;
}

.hidePanel {
  opacity: 0;
}

.loginCheckBox {
  padding: 0px 6px 6px 0px;

  .ChkBox {
    label {
      width: 16px;
      height: 16px;

      &::before {
        width: 8px;
        top: 3px;
      }
    }
  }
}

.rememberMe {
  display: block;
  color: white;
  width: 160px;
  height: 14px;
  line-height: 14px;
  padding-left: 24px;
  font-size: 14px;
  text-align: left;
}

.overlayReports {
  opacity: 0.5;
  pointer-events: none;
  max-width: none;
}

.openArrow {
  display: none;
}

.sticky-table {
  white-space: nowrap;

  // For Sticky Header
  thead {
    position: sticky;
  }

  thead {
    inset-block-start: 0;
    /* "top" */
  }
}

.table-container {
  overflow-y: auto;
  max-height: 100% !important;
  height: 100% !important;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 0.1rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 0.1rem;
  }
}

.wrapping-container {
  height: 100vh;
  margin-left: 4.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  button {
    &:hover {
      box-shadow: none !important;
    }
  }
}

.alert-table {
  white-space: nowrap;
}
.table-container {
  overflow-y: hidden;
  max-height: 100% !important;
  height: 100% !important;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 0.1rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 0.1rem;
  }

  ::-webkit-scrollbar-corner {
    background: inherit;
  }
}

.common-date-picker {
  border: 1px solid #7F97B7 !important;
  border-radius: 4px;
  width: 260px;
  height: 30px;
  // box-shadow: #5f8fc780 0px 2px 8px !important;
  padding: 0 14px;
}

.vehicle-selection {
  margin-left: 10px;
  align-self: start;
  position: relative;
}

.email-date-picker {
  margin-right: 20px;
}

.email-date {
  display: flex;
  margin-top: 20px;
  margin-left: 110px;
}

.fleetHealthTextBold {
  font-weight: 700;
}

.colorChange {
  color: red !important;
}

.severity-block.service-now {
  background-color: #fcc0c0;
  color: red;
}

.pref-btn {
  background-color: #212a54 !important;
  color: #fff !important;
  font-size: 15px !important;
  padding: 10px 20px !important;

  &:hover {
    color: #fff;

  }
}

.downloadWraper {
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    height: 35px;
    cursor: pointer;
  }
}

.card-container-scroll {
  overflow-x: auto;
  border-radius: 10px;
}

.track-and-trace-container{
  overflow-y: auto;
  overflow-x: hidden;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

.cluster-icon {
  div {
    top: -21px !important;
  }
}
.fitToMap-mapContainer{
  width: 100px;
}

.fitToMap {
  margin: 0 2px;
  border: 1px solid;
  width: 70px;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
  }
}

.mapIcons {
  position: absolute;
  cursor: pointer;
  bottom: 15px;
  left: 5px;

  div {
    margin: 0 2px;

    img {
      height: 2rem
    }
  }
}

// for checkboxes
.sch_alerts {
	display: flex;
  flex-direction: column;
	@media (max-width: 1023px) {
		flex-direction: row;
		flex-wrap: wrap;
	}
	label {
		margin-bottom: 0;
    margin-left: 0.5em;
		font-size: 15px;
	}
	span {
		display: inherit;
    align-items: center;
		@media (max-width: 1023px) {
			margin-right: 0.5rem;
		}
	}
	input[type="checkbox"] {
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		background-color: transparent; // for theme switch
		margin: 0;
    cursor: pointer;

		font: inherit;
		width: 1.15em;
		height: 1.15em;
		// border: 0.12em solid lightgray;
		border-radius: 0.25em;
		transform: translateY(-0.075em);

		display: grid;
		place-content: center;
	}
 
	input[type="checkbox"]::before {
		content: "";
		width: 0.65em;
		height: 0.65em;
		clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
		transform: scale(0);
		transform-origin: bottom left;
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em #4659b9;
		/* Windows High Contrast Mode */
		background-color: CanvasText;
	}

	input[type="checkbox"]:checked::before {
		transform: scale(1);
	}
}
.reportsMailForm {
  .sch_alerts {
  input[type="checkbox"]:disabled {
    background-color: #bcbcbc;
    cursor: not-allowed;
  }
}
}
.summaryCard {
  div {
    font-size: 13px;

    .fontbold {
      font-weight: bold;
    }

    .tripvalue {
      font-weight: bold;
      font-size: 12px;
    }
  }
  .rollover {
    font-size: 12px;
    padding: 0;
    text-align: right;
  }
}

.commonLink {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.get-info {
  border: 1px solid #4659b9;
  border-radius: 50%;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  color: #4659b9;
  font-size: 14px;
  cursor: pointer;
}
.get-info-alt {
  border: 1px solid #fff;
  border-radius: 50%;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.filterInfoBox {
  display: inline-flex;
  align-items: center;
  position: absolute;
  bottom: 6%;
  div {
    padding: 0 0 0 10px;
    font-size: 14px;
  }
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
  // pointer-events: none;
}

@media only screen and (min-width: 750px) and (max-width: 1170px) {
  .filterInfoBox {
    bottom: 11%;
  }
}

.loading:after {
  overflow: hidden;
  margin-left: 5px;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis 2s infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
}
@keyframes ellipsis {
  from {
      width: 2px;
  }
  to {
      width: 15px;
  }
}

 .colorwhite {
  color: white
 }

 .darkbgcolor {
  background: #141728;
 }

 #toast-container > .toast-info {
  background-color: #003171 !important;
 }

#repTabletheme .table th {
  color: #bdbdbd !important;
  border: none;
}

#repTabletheme .table thead tr th {
  border: 2px solid #20284c;
}

#repTabletheme .table td {
  color: #a4a4a4 !important;
  border: 2px solid #20284c;
  white-space: pre;
  overflow-wrap: break-word;
  word-break: break-word;
}

#repTabletheme .table td.tripstrat {
  width: 350px;
  max-width: 350px;
  text-wrap: wrap;
  min-width: 350px;
  white-space: pre-wrap;
}

#repTabletheme .table thead {
  background-color: #131c34;
  color: #a4a4a4 !important;
  border: 2px solid #20284c;
}

#repTabletheme .table tbody tr:nth-of-type(odd) {
  background-color: #060818;
}

#repTabletheme .table tbody tr:nth-of-type(even) {
  background-color: #131c34;
}

.primary-btn {
  background-color: #212a54;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  &:hover {
    color: #fff;
    background-color: #2d3a73;
    transform: scale(1.02);
    box-shadow: none;
  }
}

.primaty-alt-btn {
  background-color: #003171;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  &:hover {
    color: #fff;
    background-color: #023e8d;
    transform: scale(1.02);
    box-shadow: none;
  }
}

.secondary-btn {
  background-color: white;
  color: black;
  font-size: 14px;
  padding: 7px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  border: 1px solid #003171;
  transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  &:hover {
    background-color: #f2ebeb;
    transform: scale(1.02);
    box-shadow: none;
  }
}

.ucfontSize {
  font-size: 10px;
  margin-left: 2px;
}

.center-nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  font-size: 13px;
}

.common-tooltip {
  text-align: center;
  font-size: 12px;
  height: auto;
  padding: 5px;
  div {
    margin-bottom: 5px;
  }
}
// Chart CSS

.optimal-band,
.recc-band,
.normal-band {
  border-radius: 5px;
  width: 10px;
  margin-right: 0.3rem;
  height: 10px;
  margin: 0 10px;
}

.normal-band {
  background: #380ec1 !important;
}

.optimal-band {
  background: #19de0c !important;
}

.recc-band {
  background: #fe1e1e !important;
}

.cardHeading {
  text-align: initial;
  margin: 0.5rem 0 0.25rem 0.75rem;
  font-weight: bold;
  font-size: 14px;
}

.progressFleetGrid {
  display: grid;
  grid-template-columns: 30% 55% 15%;
  padding-left: 0.75rem;
}

.progressGrid {
  display: grid;
  grid-template-columns: 25% 55% 20%;
  padding-left: 0.75rem;
}

.drivingScore .progressGrid {
  grid-template-columns: 35% 50% 10% !important;
  .progressVisualFull {
    // margin-left: 10%;
  }
}
.singleIncidentCard.drivingScoreTrend {
  overflow-y: visible;
  padding-right: 1rem;
}
.profTab .active {
  color: #bdbdbd;
  border-bottom: 2px solid #bdbdbd;
}
.active-inactive-users{
  box-shadow: none !important;
margin-top: 0px !important;
}
.header-profile.profTab.active-inactive-users .cursor-pointer {
  margin-right: 30px;
  padding-bottom: 5px;
  margin-left: 20px;
}

// .MuiAutocomplete-listbox {
//   .MuiCheckbox-colorSecondary {
//     padding: 0 !important;
//     &.Mui-checked {
//       svg{
//         fill:#003171;
//       }
//     }
//   }
// }

.fleetStatusCard {
  .progress-fill {
    margin-bottom: 0.8rem;
  }
  .fleetStatusName {
    width: 55px;
  }
}

.incidentValue {
  font-size: 16px;
  font-weight: bold;
}
.incidentStatus {
  font-size: 10px;
}
.font-bold {
  font-weight: bold;
}
.noteMsg {
  font-size: 12px;
  margin: 2px 0 0 0;
  display: flex;
  align-items: flex-start;
  a {
    text-decoration: underline;
    font-weight: bold;
    color: #4659b9;
  }
  img {
    width: 15px;
    margin: 2px 5px;
  }
}
.parameterWeightage {
  border: 1px solid #7F97B7 !important;
  border-radius: 4px;
  padding: 3px 0px 3px;
  width: 60px;
  font-size: 12px;
  color: #7F97B7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}


/*Filter Styles starts here*/
.filterSpace {
  padding: 0;
}
.tripFilter {
  display: flex;
  align-items: center;
  .pdf-icon-newsite,.excel-icon-newsite {
    padding-top: 0;
    position: static;
  }
}
.filterMainDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .common-date-picker {
    padding: 0;
    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container input {
        padding: 0 10px;
      }
      .react-datepicker__input-container input[type="text"]:read-only {
        color: #999 !important;
        }
    }
    
  }
}
.filterDiv {
  position: relative;
  .filterNoVehicle {
    padding-left: 10px;
  }
}
.filterBox {
  color: black;
  width: 330px;
  border: 1px solid #e0d7e0;
  padding: 10px;
  z-index: 5;
  background: #fff;
  border-radius: 0 0 15px 15px;
  position: absolute;
  top: 32px;
  right: 0px;
  will-change: transform;
  max-height: 450px;
    overflow-y: auto;
    .error {
      color: red;
    }
  .common-date-picker {
    width: 100%;
    text-transform: uppercase;
  }
}
.filterInput {
  border: 1px solid #7F97B7 !important;
    border-radius: 4px;
    padding: 3px 10px;
    cursor: pointer;
    position: relative;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after {
      content: '';
      position: absolute;
      width: 0px;
   height: 0px;
   top:10px;
   right: 10px;
   border-style: solid;
   border-width: 0 5px 6px 5px;
    border-color: transparent transparent #7b7b7b transparent;
    transform: rotate(180deg);
    }
    .searchInput {
      width: 88%;
      border: none;
      margin: 0;
    }
    svg {
      path {
        fill: #777777;
      }
    }
}
.filterInput.active {
  &::after {
    transform: rotate(0deg);
  }
}
.filterInputDropdown {
  border: 1px solid #7F97B7 !important;
  border-radius: 4px;
  .filterScrollOption {
    max-height: 130px;
    overflow-y: auto;
  }
}
.filterOption {
  padding: 3px 10px;
  label {
    display: block;
    margin: 0;
    cursor: pointer;
    input {
      margin: 0 7px 0 0px;
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  &.selectAll {
    margin-top: 10px;
    border-top:1px #7F97B7 solid ;
  }
}
.filterBox {
  .react-datepicker__time-container {
    width:50px;
  }
}
.trip-analysis .reportFilters .filterInput,
.trip-analysis .reportFilters .filterOption {
  padding: 3px 5px !important;
}

.filterInput svg {
  margin-right: 25px;
}
.trip-analysis .react-share__ShareButton {
  padding: 5px !important;
}
/*Filter Styles ends here*/

/* Date Picker for filter */
.react-datepicker {
border-radius: 20px;
}

.react-datepicker__triangle {
  display: none;
}
.react-datepicker__header {
  border-radius: 20px 0 0 0px;
}
.react-datepicker__month-container {
  border-radius: 20px 0 0 20px;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__header {
  border-radius: 20px 20px 0 0;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 1.53rem;
  height: 1.53rem;
  font-size: 12px;
}
.filterBox .react-datepicker__time-container {
  width: 80px;
}
// .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
//   height: 205px;
// }
// .react-datepicker__month {
//   margin: 0.2rem;
// }
// .react-datepicker__time-container {
//   width: 70px;
// }
// .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
//   width: 70px;
// }
// .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
//   font-size: 12px;
//   padding: 3px;
//   height: 25px;
// }
/*Light theme style*/

.lightTheme.repSettingsCard {
  border: 1px #ebebeb solid;
  .reportListSettings {
    border-bottom:1px #ebebeb solid;
  }
}
.lightTheme .reportFilters .ant-calendar-picker, .lightTheme.trip-analysis .ant-calendar-picker-icon,
.lightTheme.trip-analysis .reportFilters .form-group label,
.lightTheme .reportFilters .ant-calendar-range-picker-input,
.lightTheme .ant-calendar-range .ant-calendar-input {
  color: #4f4f4f !important;
}
.lightTheme .reportFilters .ant-calendar-range-picker-input[disabled],
.lightTheme.trip-analysis .reportFilters .form-group .dropdown button {
  color: #a3a9c4 !important;
}
.lightTheme.trip-analysis .reportFilters .form-group .dropdown button:hover, .reports .reportFilters .form-group .dropdown button:hover {
  background-color: #fff !important;
  box-shadow: none;
}
.lightTheme.trip-analysis .reportFilters .create-btn1 button.btn-submit-dash-theme:hover,
.lightTheme .create-btn.btn-submit-dash-theme:hover,
.lightTheme .btn-submit-dash-theme:hover,
.lightTheme .serviceNowButton .btn-submit-dash-theme:hover,
.lightTheme.trip-analysis .reportFilters .end-trp .btn.primary-btn:hover {
  color:#ccc;
}
.lightTheme .react-datepicker__day--disabled {
  opacity: 0.5;
}
.lightTheme .date-fleet-health, .lightTheme .health-wrapper .dropdown {
  color: #4f4f4f;
}
.lightTheme .sidepanel {
  box-shadow: 0 20px 30px rgba(105,105,105,60%);
  border: 1px solid #ffffff;
  background-color:#fff;
  .ticker-underline {
    color: #0c96e8;
  }
  .myfleet-wrapper .notification-wrapper .notification-filter .alert-type-btn, .myfleet-wrapper .notification-wrapper .notification-filter .filter-dropdown {
    background: #f1f1f1;
    span {
      color: #000;
    }
  }
  .notification-wrapper .notification-filter .filter-dropdown .checkmark {
    border: solid 2px #000;
  }
  .notification-wrapper .notification-filter .filter-dropdown .dropdown-container {
    color: #000;
  }
  .panel-card {
    background: #f1f1f1;
  }
  .ticker-sub-heading, .ticker-desc {
    color: #4f4f4f;
  }
  .ticker-time {
    color:#0c96e8;
  }
}

.lightTheme .menu-dropdown .dropdown-container input[type="checkbox"]:checked {
  background: url('../images/checkboxActive.png') !important;
  background-repeat: no-repeat !important;
}

.lightTheme .group-drop-fmt-search .dropdown-container input[type="checkbox"]:checked {
  background: url('../images/checkboxActive.png') !important;
  background-repeat: no-repeat !important;
}
.lightTheme .group-drop-fmt-search .dropdown-container input[type="checkbox"] {
  background: url('../images/checkboxInactive.png') !important;
  background-repeat: no-repeat !important;
}
.lightTheme .profTab .active {
  color: #1c7cd6;
  border-bottom: 2px solid #1c7cd6;
}
.lightTheme #profile-new-pages .table th {
  color: #4f4f4f !important;
  border: 2px solid #e3e3e3;
}
.lightTheme #profile-new-pages .table thead{
  background-color: #f4f6fc !important;
  color: #4f4f4f;
  border: 2px solid #e3e3e3;
}
.lightTheme #profile-new-pages .table td {
  border: 2px solid #e3e3e3;
  color: black !important;
}
.lightTheme #profile-new-pages .table tbody tr:nth-of-type(odd) {
  background: #fff;
}
.lightTheme #profile-new-pages .table tbody tr:nth-of-type(even) {
  background: #f4f6fc;
}
.lightTheme.privacyContainer {
  background: #fff;
  color: #4f4f4f;
  .policy-level-header, .policy-subheader {
    color: #4f4f4f;
  }
}
.lightTheme .create-customer-btn {
  background: #1F3276;
  color: #fff;
}
.lightTheme.popup_overlay_profile {
  background: rgba(255,255,255,0.5);
  .pop-up {
    background: #fff;
    color: #3c3c3c;
    .popupHeader {
      h3 {
        color: #3c3c3c;
      }
      .popupclose svg path {
        fill: #3c3c3c;
      }
    }
    .hints {
      color: #3c3c3c;
    }
    .save-btn-profile {
      background: #1F3276;
    color: #fff;
    }
    .profile-new-wrapper{
      .menu-dropdown {
        background: #fff;
      }
    } 
    .form-group{
      .inputField {
        color: #3c3c3c;
      }
      select {
        background-color: #ffffff;
        color: #3c3c3c;
        border: 2px solid #000000 !important;
    }
    }
  }
}
.lightTheme .geo-type-popup {
  background-color: #fff;
  .geo-type-button {
    background-color: #1F3276;
    border: 1px solid #1F3276;
    color: #fff;
  }
}
.lightTheme .fmt-tab-header .show-button, .lightTheme .fmt-tab-header .show-button:focus {
  color: #fff !important;
  background-color: #1F3276 !important;
  border-color: #1F3276 !important;
}
.lightTheme .confirmationpop-profile {
  &:before {
    background: #ffffff;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-bottom: 1px solid #f9f9f9;
  }
  .pophdr {
    background: #ffffff;
    border: 1px SOLID #ffffff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .modal-body {
    background: #fdfdff;
    border: 1px SOLID #ffffff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  h3 {
    color: #4f4f4f;
  }
  .delete-btn {
    background: #1F3276;
    color: #fff;
  }
} 
.lightTheme .truckPerform {
  color: #141728;
}
.lightTheme .newPopUp {
  background: #fff;
  .deleteHeader {
    background: #fff;
  }
  &:before {
    background: #ffffff;
  }
  .overlay-btn {
    background: #1F3276;
    color: #fff;
  }
}
.lightTheme.help-wrapper .home-text {
 padding-left: 10px;
}
.lightTheme .tabWraper p,
.lightTheme .searchWrapper .MuiFormLabel-root  {
  color: #222;
}
.lightTheme .searchWrapper .Search-root-6 {
  border: 2px solid #F5F6FB;
}
.lightTheme .searchWrapper input {
  color: #222;
}
/*Light theme styles ends here */

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.fmt-tab-header .show-button {
  border-color: #007bff;
}
@media only screen and (min-device-width: 768px) {
  .common-filter{
    display:flex;
    align-items: center;
  }
}

.report-btn-grp{
  display: flex !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1365px)  {
  .cardHeight {
    height: 240px;
  }
  .drivingScoreSettings {
    height: 360px;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1365px)  {
  .drivingScoreSettings {
    height: 360px;
  }
}
@media only screen and (min-width: 1240px) and (max-width: 1280px)  {
  .drivingScoreSettings {
    height: 280px;
  }
}
.gm-style .share-control {
  left: 170px !important;

  .gg-share {
    background: url('../images/shareicon.svg') #fff no-repeat 5px;

    &:hover {
      background: url('../images/shareicon.svg') #ebebeb no-repeat 5px;
      border-color: #ebebeb !important;
    }
  }
}

.gg-share.mapShareDisabled {
  pointer-events: none;
  background-color: #A9A9A9 !important;
  color: #fff !important;
  left: 170px !important;
  border: 2px solid #A9A9A9 !important;
  cursor: not-allowed !important;
}

.gm-style .share-control .gg-share.mapShareDisabled {
  background: url('../images/track_trace/sharedisabled.svg') #fff no-repeat 5px;
}
 
/** Mozilla Firefox */
@-moz-document url-prefix() {
  .gm-style .share-control {
    left: 220px !important;
    top: 4px !important;
  
    .gg-share {
      background: url('../images/shareicon.svg') #fff no-repeat 5px;
  
      &:hover {
        background: url('../images/shareicon.svg') #ebebeb no-repeat 5px;
        border-color: #ebebeb !important;
      }
      height: 38px;
    }
  }
  }

.place-hold{
 &::placeholder{
  color: hsl(0, 0%, 50%);
  font-size: 16px;
  font-weight: bold !important;
 }
  }

.font-normal{
  font-weight: normal;
  font-size: 10px;
  text-decoration: none;
  }

.geoProfSpan{
    &:hover{
      text-decoration: underline ;
    }
}

.live-map-sidebar {
  background-color: rgb(37, 42, 74);
  border-radius: 10px;
}

.selectedTrip{
  border: 2px solid #fda000;
  border-left: 5px solid #fda000;
}

.dateTimeDiv{
  display: flex;
  font-size: 12px;
}

.startTimeSpan{
  padding-right: 0.5rem;
  white-space: nowrap;
}

.startTimePicker{
  height: 2.5rem;
  width: 150px;
  margin-right: 0.5rem;
}

.react-datepicker {
  margin-left: -15px;
  border: 1px solid #7F97B7 !important;
  border-radius: 20px;
  bottom: 5px;
  }
  // .react-datepicker__triangle {
  //   display: none;
  // }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle{
    border-top-color: #b6b6b6;
  }
  .react-datepicker__header {
    border-radius: 20px 0 0 0px;
  }
  .react-datepicker__month-container {
    border-radius: 20px;
  }
  .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__header {
    border-radius: 20px 20px 0 0;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 1.53rem;
    height: 1.53rem;
    font-size: 12px;
  }
  .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list,
  .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__time-container .react-datepicker__time {
    border-radius: 0 0 20px 0;
  }
  .MuiPaper-elevation8{
    box-shadow: none !important;
    padding-top: 0 !important;
    border-top: 1px solid #7F97B7 !important;
  }
  
  .MuiList-padding{
    padding: 0 !important;
  }
  
  .vehicle-list-item {
    height: 28px;
  }

  .MuiAutocomplete-listbox{max-height:150px !important; min-height:130px}

  @media screen and (max-width: 460px) {
    .live-map-sidebar{
        display: none;
    }
  }

  @media screen and (max-height: 460px) {
    .live-map-sidebar{
        display: none;
    }
  }

  .live-status-label {
    border-radius: 10px;
    text-align: center;
    margin-right: 1rem;
    height: 1.5rem;
    width: 100%;
    padding: 0.3rem;
    color: white;
  }

#map2 {
  height: 100vh;
  border-radius: 10px;
}

#map2 {
  .gmnoprint {
    display: block;
    zoom: 0.8;
    //   opacity: 0;
  }

  .gm-style-mtc {
    opacity: 0;
  }
}


#map2 {
  button.gm-ui-hover-effect {
    visibility: visible;
    //  padding: 1rem !important;
  //  background: grey !important;
    color: white !important;
    font-weight: bold !important;
    zoom: 0.7;
    font-weight: bold !important;
    border-radius: 23px !important;
    height: 30px !important;
    width: 30px !important;
    top: -25px !important;
    margin-top: 2.2rem !important;
    margin-right: 0.8rem !important;
    //  margin-left: 1rem !important;
  }

  button.gm-ui-hover-effect:hover {
    box-shadow: none;
  }

  .gm-style .gm-style-iw-c {
    padding: 0;

  //  background-color: #dee7ec;
  }
}

#map2 {
  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw-c {
    padding: 0rem !important;
    max-width: 515px;
    height: auto !important;
   // background-color: #dee7ec;
    color: black;
  }

  .gm-style-iw-d {
    height: auto;
    //  height: 315px;
    //  width: 490px;
    padding: 1rem;
    // padding-bottom: 0;
  }
}

@media only screen and (min-width: 540px) and (max-width: 720px) and (orientation: landscape) {

  #map2 .gm-style .gm-style-iw-c {
    max-width: 440px !important;
    max-height: 300px !important;
  }
 }
 
 
 @media screen and (max-width: 540px) {
    #map2 .gm-style .gm-style-iw-c {
    max-width: 307px !important;
  }
  }

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  #map2 .gm-style .gm-style-iw-c {
    max-width: 409px !important;
  }
}

#map2 button.gm-ui-hover-effect:active {
  outline: none !important;
}

/* Location share toggle color styles */
#locationShareBeta .MuiSwitch-colorSecondary.Mui-checked {
  color: #fff;
}

 /* Incident trend dark theme changes */
 .darkTheme {
  .drivingScoreTrend, .driverCategory {
    .highcharts-legend-item text,
    .highcharts-xaxis-labels text,
    .highcharts-yaxis-labels text,
    .highcharts-yaxis text {
      fill:#fff !important;
    }
  }
}

 /* Carousel Screens starts */
 .slick-slide {
  img {width: 80%; display: inline !important;}}
 .slick-slide > div {text-align: center;}
 .slick-slide > div > div {height: 100%;}
 .carouselPage {
   // height: 90%;
    padding: 0px 20px 20px 20px;
    .MuiButtonBase-root.MuiTab-root {
      background: white;
      color: #003171;
      width: 120px !important;
      min-width: 120px !important;
      padding: 5px;
      font-size: 12px;
      border-left:none !important;
      &.Mui-selected {
        background: #003171;
        color: #fff;
      }
     }
     .MuiTabs-indicator {display: none;}
     .slick-prev {left:0; z-index: 10;}
     .slick-next {right:0; z-index: 10;}
     .slick-prev:before, .slick-next:before{color:#003171;opacity: 1 !important;}
     .slick-disabled{opacity: 0.25;}
     .slick-dots {
      bottom: -30px;
     }
     .slick-dots li {
      width: 10px;
      height: 10px;
     }
     .slick-dots li button {
      width: 10px;
      height: 10px;
     }
      .slick-dots li button:before { 
        background: black;
        border-radius: 50%;
        content:'';
      width:10px;
    height: 10px;}
    .MuiTab-textColorInherit {
      opacity: 1;
    }
 }
 .carouselPage.demoScreen {
  padding: 10px 20px 20px 0;
  
  .MuiTabs-root{
    min-height: 20px;
  }
 .MuiButtonBase-root.MuiTab-root {
  width: 87px !important;
  min-width: 87px !important;
}
.slick-slider {
  margin: 10px 0 0;
}
}
 .carouselPage.darkTheme {
  .slick-dots li button:before {
    background: #fff;
  }
  .slick-prev:before, .slick-next:before {
    color: #fff;
  }
  .MuiButtonBase-root.MuiTab-root {
    background: #212a54;
    color: #9d9d9d;
  }
  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    background: #003171;
    color: #fff;
  }
 }
 
 @media screen and (min-height: 680px) {
  .slick-slide img {width: 70%;}
  .demoScreen .slick-slide img {width: 82%;}
 }
 .btnSearch {
  .primary-btn:disabled {
    background-color: #858db3;
  }
 }
 .demoScreen .slick-slide img {
  width: 53%;
}
@media screen and (min-width: 1300px) {
  @media screen and (min-width: 1300px) {
    .carouselPage.demoScreen .MuiButtonBase-root.MuiTab-root {
        width: 120px !important;
        min-width: 120px !important;
        font-size: 12px !important;
    }
}
}
 @media screen and (min-height: 650px) {
  .demoScreen .slick-slide img {width: 77%;}
  }
  @media screen and (min-height: 580px) {
    .demoScreen .slick-slide img {width: 75%;}
  }
  @media screen and (min-height: 550px) {
    .demoScreen .slick-slide img {width: 80%;}
  }
 .expire-select__option[aria-disabled="true"] {
  opacity: 0.45 !important;
}

.showGeoMapIcon {
  position: absolute;
  cursor: pointer;
  top: 40px;
  right: 5px;
  img {
    padding: 2px 2px 4px 4px;
    width: 30px;
    background: #ffffff;
  }
}

.showLabelIcon {
  position: absolute;
  cursor: pointer;
  top: 72px;
  right: 5px;
  img {
    padding: 5px 5px 2px 6px;
    width: 30px;
    background: #ffffff;
  }
}

.liveShareLabelIcon {
  position: absolute;
  opacity: 1;
  text-align: center;
  z-index: 9999;
  bottom: 10px;
  height: 30px;
  cursor: pointer;

  top: 40px;
  right: 22px;

  div {
    margin: 0 2px;
  }

  img {
    padding: 5px 5px 5px 5px;
    width: 29px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  }
}

.liveShareLabelIconMobile {
  position: absolute;
  opacity: 1;
  text-align: center;
  z-index: 9999;
  bottom: 10px;
  height: 30px;
  cursor: pointer;

  top: 120px;
  right: 6px;

  div {
    margin: 0 2px;
  }

  img {
    padding: 5px 5px 5px 5px;
    width: 29px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  }
}

.AlertPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 70px;
  right: 10px;
  background: #fff;
  width: auto;
  z-index: 999;
  font-size: 14px;

  .popup-content {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-height: 85vh;
    display: flex;
    align-items: flex-start;
    overflow-y: auto;

    .alertMessage {
      padding: 10px;

      button {
        color: #d30000;
      }
    }
  }

  .showAllAlertArea {
    padding: 10px 15px 0;
  }

  button {
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px;
    border: none;
    background: none;
    color: #4659b9;
    cursor: pointer;
    padding: 10px;
  }

  .goToAlert {
    padding: 0 10px 3px;
  }

  .closeAlertIcon {
    color: #989696;
    text-decoration: none;
  }

  button:hover {
    background: none;
    box-shadow: none;
  }

  .showAllAlert {
    border-left: 1px #e1e1e1 solid;
    border-right: 1px #e1e1e1 solid;

    button {
      color: #df0000;
    }
  }
}
.pr-20 {padding-right: 20px;}
.reportFilterDiv {
padding: 10px 0 0 0;
}
.selectedFilterData {
  display: flex;
  flex-grow: inherit;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  padding: 10px 10px 0;
  &.flex-end {
    justify-content: flex-end;
  }
  &.alphaFilter {
    flex-grow: 1;
    padding: 0px 10px 0;
    justify-content: flex-end;
  }
  .selectedVehicle {
    padding: 2px 8px;
    border-radius: 20px;
    margin: 0 5px 0 0;
  }
  .showMore {
    position: relative;
    .selectedVehicle {
      cursor: pointer;
    }
    .showMoreSelectedVehicles {
      position: absolute;
      padding: 2px 8px;
      border-radius: 5px;
      z-index: 9;
      height: 200px;
      overflow-y: auto;
      .dropdown-item {
        padding: 2px 0;
        &:hover{
          background: none;
        }
      }
    }
  }
}

.lightTheme.popup_overlay_profile.locationMap,
.darkTheme.popup_overlay_profile.locationMap {
  .map-container {
    height: 350px;
  }
  .pop-up {
    padding: 0;
    .popupHeader {
      padding: 15px 20px 10px 20px;
      display: flex;
      align-items: center;
      h3 {
        color: #5365BE;
        margin: 0;
      }
      img {
        width: 25px;
      }
    }
    .address {
      padding: 1rem;
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
      }
      p {
        margin: 0 10% 0 10px;
        font-weight: bold;
      }
    }
  }
}
.lightTheme.popup_overlay_profile.locationMap {
  .address {
    color: #000;
  }
}
.lightTheme.profile-new-wrapper .menu-dropdown{
  background: white;
}
.darkTheme.popup_overlay_profile.locationMap {
  .address {
    color: #fff;
  }
}
.noWrap {
  white-space: nowrap;
}
.summaryCard div .tripvalue{
  text-align: center;
}

.truck-window-info.gm-style-iw-ch {
  margin-top: -23px;
  padding-top: 18px !important;
  }

.gm-style-iw-chr {
  margin-top: -11px;
}

.gm-style-iw-chr button.gm-ui-hover-effect {
    >span {
    margin: 18px !important;
    }
  }

.confirmationpop-profile-restore {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.confirmationpop-profile-restore.show {
  opacity: 1;
  visibility: visible;
}

.confirmation-popup-content-restore {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: center;
  position: relative;
}

.confirmation-popup-content-restore img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.confirmation-popup-content-restore h3 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
  word-break: break-word;
  white-space: normal;
}

.confirmation-popup-content-restore button {
  background: #1F3276;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.confirmation-popup-content-restore button:hover {
  //background: #0056b3;
  box-shadow: 5px 10px 20px #444 inset;
}

// .confirmation-popup-content-restore button:last-child {
//   background: #0056b3;
// }

// .confirmation-popup-content-restore button:last-child:hover {
//   background: #0056b3;
// }
.excel-icon-newsite {
  padding-top: 20px;
}

.pdf-icon-newsite {
  padding-top: 20px;
}

.trip-custom-filter{
  padding-top: 20px;
}

.playbackCard {
  background-color: #ffffff;
  color: #212a54;
  height: 67px;
  transform: rotateZ(90deg);
  position: absolute !important;
  left: -63px !important;
  top: 164px !important;

  svg {
    margin-right: 10px;
  }

  &:hover {
    opacity: 1 !important;
  }

  .MuiSlider-markLabel{
    transform: rotateZ(-90deg);
    top: 34px;
  }

  .MuiSlider-markLabel[data-index="0"]{
    transform: rotateZ(0deg);
    top: 141px;
    left: -30px !important;
  }

  .MuiSlider-markLabel[data-index="1"]{
    transform: rotateZ(0deg);
    top: 113px;
    left: -30px !important;
  }

  .MuiSlider-markLabel[data-index="2"]{
    transform: rotateZ(0deg);
    top: 66px;
    left: -30px !important;
  }

  .MuiSlider-markLabel[data-index="3"]{
    transform: rotateZ(0deg);
    top: -9px;
    left: -30px !important;
  }
}

.playbackCardControls{
  background-color: #ffffff;
  color: #212a54;
  height: 35px;
  left: 9px;
  top: 50px;
  width: 67px;
  padding-left: 4px;

  svg {
    margin-right: 10px;
  }

  &:hover {
    opacity: 1 !important;
  }
}

.custom-slider{
  transform: rotate(-90deg);
  top: 28px; 
  left: 3%;
  transform-origin: top center;
}

.MuiSlider-vertical .MuiSlider-rail{
height: 96% !important;
}

.MuiSlider-rail{
  position: absolute;
  top: 0% !important;
}

.MuiSlider-root.MuiSlider-vertical{
  height: 168% !important;
  top: 31% !important;
  left: 6% !important;
}

.csSupportDisable {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}
.support-admin-button {
  background: #242844;
  border: 2px solid rgb(0, 49, 113);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12.5px;
}

.support-admin-button img {
  width: 20px;
  height: auto;
}

.payHubBtn{
  background: #CBE5F4;
  border: 2px solid #007BC6;
  color: #007BC6;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 20px;
  border-radius: 5px;
  font-size: 12.5px;
  font-weight: bold;
}
.payHubBtn:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
  opacity: 0.6;
}

.gf-row-container .MuiSwitch-colorSecondary.Mui-checked {
  color: #003171;
}
/* Fluids tab stale*/
.fliudsTab {
  display: flex;
  justify-content: space-between;
  padding: 5px 0 0 0;
  height: calc(100vh - 180px);
  .highcharts-credits {
    display: none;
  }
}
.fuelLeg, .adblueLeg {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.vecihleListPanel {
  width: 20%;
  // background: #fff;
  padding: 10px;
  border-radius: 3px;
  overflow-y: auto;
}
.fluidsGraphPanel {
  position: relative;
  width: 79%;
  border-radius: 3px;
  padding: 10px;
  .vehicleGraph {
    position: absolute;
    top: 15px;
    left: 220px;
    font-size: 14px;
    padding: 0px 10px;
    border-radius: 3px;
  }
}
.fluidsVecihleListPanel {
  padding: 5px 10px;
  border-radius: 3px;
  margin: 0 0 10px;
  cursor: pointer;
  &.active {
    border: 2px #DBAB4C solid;
  }
}

/* Fluids graph style ends here */

.gf-row-container .MuiSwitch-colorSecondary.Mui-checked {
  color: #003171;
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 6px;
  border-style: dashed;
  border-color: #007BC6;
  background-color: #E9F2FB;
  width: 30%;
}
.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.mileage-trend {
  position: relative;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  .vehReg {
    position: absolute;
    top: 17px;
    left: 150px;
    font-size: 14px;
    padding: 0px 10px;
    border-radius: 3px;
  }
}

.ticker-carousel {
  .BrainhubCarousel {
    width: calc(100% - 200px);

    li {
      overflow: hidden;
      font-size: 14px;
      div{
        cursor: pointer;
      }
    }
  }

  .BrainhubCarousel__trackContainer {
    width: 100%;
  }

  .BrainhubCarousel__container {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 30px;

  ul {
    display: flex;
  }

  .BrainhubCarousel__dots {
    li {
      margin-right: 10px;
    }

    button {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      padding: 0px;
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    display: none;
  }

}

.scroll-group {
  .row-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tickerModalContainer {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0rem;
  left: 0rem;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px 10px;
  bottom: 0;
  z-index: 9;
}

.tickerModalContainer .tickerModal {
  background-color: #081c32;
  // border: 2px solid grey;
  width: 100%;
  max-width: 600px;
  margin: 30px auto;
  padding: 30px 50px;
  left: 0;
  right: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
.tick-modal-header{
  font-weight: bold;
  font-size: large;
  margin-bottom: 10px;
}
  ul{
    overflow-y: auto;
    max-height: 400px;
    min-height: 400px;
    padding-left: 20px;
    list-style: disc;
    li{
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;

    .okayButton {
      padding: 5px;
    }

  }

  .content {
    text-align: center;
    color: #afafb0;
  }
}

.critical-bold{
  font-weight: bold;
}

//Footer text colour
.logoutWindow .footerList.colorwhite.loggedOut, .logoutWindow .footerList.colorwhite.loggedOut {
  color: white !important;
}