/**********typo******************/

$baseFontSize: 16px;
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

//Color theme
$primary-color: #00677f;
$error: #ff4500;
$B-color: #a70e13;
$alertbtn-active: #0a4f83;
$alertbtn-inactive: #c1c1c1;
$alertbtn-bg: #dee7ec;
