.map-height {
  height: 87%;
}

.show-button {
  font-size: 0.8rem !important;
}

.fmt-tab #fmt-tab-tab-fmt,
.fmt-tab #fmt-tab-tab-geofence, 
.fmt-tab #fmt-tab-tab-locationShare{
  background: transparent;
  border: none;
  font-size: 0.9em;
  font-weight: bold;
  color: #004997;
  text-decoration: none;
  background-color: transparent;
  position: relative;
  padding:0.5em
}

.fmt-tab .active {
  color: #57b3ff !important;
}

#fmt-tab-tab-fmt::after,
#fmt-tab-tab-geofence::after,
#fmt-tab-tab-locationShare::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #007bff;
  bottom: 25px;
  left: 12px;
  transform: scaleX(0);
}

#fmt-tab-tab-fmt.active::after,
#fmt-tab-tab-geofence.active::after, 
#fmt-tab-tab-locationShare.active::after{
  transform: scaleX(1);
  transform-origin: bottom left;
  -webkit-transition: transform 1s ease-in-out;
}

.fmt-tab-header {
  margin-bottom: -2em;
  justify-content: space-between;
  position: relative;
}
.fmt-tab-header::after {
  content: '';
  position: absolute;
  width: 97%;
  height: 1px;
  background-color: #18416a;
  bottom: 39px;
  left: 5px;
}

.fmt-expand {
  display: flex !important;
}

.geo-table thead tr th,
.geo-table tbody tr td {
  color: white;
}

.geo-head {
  color: #ffffff;
  font-weight: bold;
  font-size: 0.9em;
}

.geo-name {
  color: #ffffff;
  font-size: 0.8em;
}

.geo_search {
  background-color: #02193b;
}

.expand-btn {
  position: absolute;
  height: 2.2em;
  top: 8px;
  right: 0px;
}

.fmt-search {
  color: #ffffff;
  border-color: #044b99;
}

.fmt-search:focus {
  color: #ffffff;
}

.group-drop-fmt-search {
  left: 8px;
  top: calc(1.5em + 0.75rem + 3px);
}

.search-icon {
  background: url(../../assets/images/searchLens.png) no-repeat right 10px
    center;
  background-size: 18px;
}
.iconWrapper {
  width: 15px;
  height: auto;
}
.geo-type-popup,
.playBackPopUp {
  background-color: #141728;
  color: #fff;
}

.iconWrapper-edit {
  width: 20px;
  height: auto;
}
