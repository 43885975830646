.tt-row-container {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 0.5rem;
  // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-columns: 32% 67%;
}

.gf-row-container {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-columns: 40% 59%;
}

.map-container {
  height: 100%;
  width: 100%;

  .gmnoprint {
    display: block;
    zoom: 0.8;
  }

  .gm-style-cc {
    display: none;
  }

  .gm-style .gm-style-iw-c {
    background-color: #fff !important;
    padding-left: 5px;
  }

  .gm-style .gm-style-iw-d {
    overflow: auto !important;
  }
}

.gm-ui-hover-effect {
  visibility: visible !important;

  &:hover {
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
}

.fitToMap {
  margin: 0 2px;
  border: 1px solid;
  width: 70px;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
  }
}

.playIcon {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 180px;
}

.mapIcons {
  position: absolute;
  cursor: pointer;
  bottom: 15px;
  left: 15px;

  div {
    margin: 0 2px;

    img {
      height: 2rem;
    }
  }
}

.count-card {
  width: 80px;
  height: 55px;
  border-radius: 5px;
  border-width: 5px 0 5px 0 !important;
  text-align: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }

  .hdr {
    font-weight: 500;
    font-size: 14px;
    margin-top: 0.2rem;
  }

  .sm-hdr {
    font-size: 9px;
    position: relative;
    top: -7px;
  }

  .count {
    font-size: 14px;
    font-weight: bold;
  }

  .adj-count {
    position: relative;
    top: -12px;
  }
}

.truckcard {
  margin: 0;
  border-radius: 5px;
  height: 55px;
  align-items: center;
  cursor: pointer;
  // border: 1px solid #d0c6c6;
  // box-shadow: 0 10px 20px 0 #2e5bff12;
  border: 0.15px solid #2e5bff12;
  box-shadow: 0 10px 20px 0 #2e5bff12;
  &:hover {
    transform: scale(1.02);
  }

  section {
    width: 100%;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .truck-register-id {
      font-size: 13px;
      font-weight: 500;
    }
    .truck-model {
      font-size: 9px;
    }
    span {
      font-size: 11px;
      font-weight: 400;
      display: inline;
    }
  }
}

.truckStatus {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 10px;
  color: white;
  margin-left: -22px;
}

.sattelite-count {
  margin-left: 95px;
  margin-top: -23px;
}

.parking-icon {
  margin-left: 126px;
  margin-top: -68px;
  width: 18px;
}

.alert-count {
  margin-top: -59px;
}

.alert-icon {
  width: 18px;
  position: relative;
  left: 81%;
}

.alert-icon-count {
  left: 87%;
  font-size: 11px;
  position: relative;
  margin-top: -7px;
}

.dtc-error-live {
  width: 23px;
  position: relative;
  left: 91%;
  margin-top: -67px;
}

.cluster-icon {
  z-index: 1000;
  div {
    top: -21px !important;
  }
}

.truck-info-window {
  margin: 10px 5px;
  border-radius: 2px;
  height: 245px;
  width: 380px;
}

.truck-info-location {
  img {
    width: 16px;
    height: 20px;
  }
  span {
    font-size: 12px;
    font-weight: 400;
  }
}

.map-info-header {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 25px;
  padding: 2px 5px;
}

.map-info-sm-info {
  color: #212529;
}

.left,
.right {
  div {
    font-size: 16px;
    color: #003171;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .subhdr {
    font-weight: 400;
    font-size: 12px;
    color: #000;
  }

  .subinfo {
    font-size: 12px;
    font-weight: 600;
    color: #212529;
  }
}

.left {
  .subhdr {
    width: 65px;
  }
}

.right {
  .subhdr {
    width: 80px;
  }
}

.btn-grp-tt {
  button {
    margin: 0 2px;
    font-size: 12px;
    font-weight: 600;
  }
  svg {
    cursor: pointer;
  }
}

.showData-switch {
  div {
    font-size: 10px;
    font-weight: 400;
  }
}

.playbackCard {
  background-color: #ffffff;
  color: #212a54;
  height: 50px;

  svg {
    margin-right: 10px;
  }

  &:hover {
    opacity: 1 !important;
  }
}

.geofenceshapes {
  img {
    margin: 0 10px;
    cursor: pointer;
    opacity: 0.5;
  }
}

.truckImglist {
  width: 23px;
  margin-left: 7px;
  margin-right: 3px;
  margin-top: -34px;
}

.truckImglistLive{
  width: 22px;
  margin-left: 7px;
  margin-right: 3px;
}

.new-signals-fmt {
  margin-bottom: -25px;
  margin-top: 4px;
}

.signal-icon {
  width: 20px;
  margin-left: 4px;
}

.battery-icon {
  width: 12px;
  margin-left: 13px;
}

.gps-fix {
  width: 22px;
  margin-left: 13px;
}

.sattelite-icon {
  width: 16px;
  margin-left: 1px;
}

.sattelite-icon-count {
  margin-left: 16px;
  font-size: 11px;
  margin-top: -7px;
}

.actionIcon {
  height: 13px;
  cursor: pointer;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.map-container .gm-style .gm-style-iw-c:has(.stopped) {
  border-left: 5px solid #d52929;
}

.map-container .gm-style .gm-style-iw-c:has(.idle) {
  border-left: 5px solid #ffaa00;
}

.map-container .gm-style .gm-style-iw-c:has(.running) {
  border-left: 5px solid #2c8243;
}

.map-container .gm-style .gm-style-iw-c:has(.keyon) {
  border-left: 5px solid #808080;
}

.map-container .gm-style .gm-style-iw-c:has(.offline) {
  border-left: 5px solid darkgray;
}

.mapLoader {
  background-color: #ffffff;
  border: 1px solid black;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  img {
    margin: 20% 45%;
    text-align: center;
    width: 3rem;
    filter: brightness(0.1);
  }
}

.flex1 {
  flex: 1;
}

.tripCardIcon {
  width: 0.75rem;
  height: 1rem;
  transform: scale(0.75);
}

.markerPlaybackInfo {
  width: 400px;
  div {
    font-size: 12px;
  }
  img {
    width: 20px;
    margin-bottom: 15px;
  }
  .currentInfo {
    font-size: 11px;
    font-weight: 500;
  }
}

.cardHeader {
  font-weight: bold;
  font-size: 13px;
  &.titleCard {
    display: inline-block;
    width: 20%;
  }
}

.tripInfo {
  float: right;
  width: 75%;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  img {
    margin: 3px 5px 0;
    width: 18px;
    background: #fff;
    border-radius: 50%;
  }
}
.clear {
  clear: both;
}
.geoFencCard {
  div:nth-child(1) {
    justify-content: start;
  }
  div {
    justify-content: center;
    align-items: center;
    display: flex !important;
    font-size: 12px;
    .fontbold {
      font-weight: bold;
    }
    .tripvalue {
      font-weight: bold;
      text-align: center;
      font-size: 12px;
    }
  }
  .rowheight {
    height: 30px;
  }
}

.geoFencCardHead {
  div:nth-child(1) {
    justify-content: start;
  }
  color: #fff;
  div {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
    .fontbold {
      font-weight: bold;
    }
    .tripvalue {
      font-weight: bold;
      text-align: center;
      font-size: 12px;
    }
  }
  .rowheight {
    height: 30px;
  }
}

.geoFencCardHeadGrid{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  color: white;
}

.geoCardLine{
  margin-bottom: 0px;
}

.geoCardProfileLine{
    cursor: pointer;
    margin-bottom: 0px;
    font-weight: bolder;
}

.tripCardRouteIcon {
  width: 0.75rem;
  height: 1rem;
  transform: scale(1);
}

.truckcardNoHover {
  margin: 0;
  border-radius: 5px;
  height: 55px;
  align-items: center;
  cursor: pointer;
  // border: 1px solid #d0c6c6;
  // box-shadow: 0 10px 20px 0 #2e5bff12;
  border: 0.15px solid #2e5bff12;
  box-shadow: 0 10px 20px 0 #2e5bff12;

  section {
    width: 100%;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .truck-register-id {
      font-size: 13px;
      font-weight: 500;
    }
    .truck-model {
      font-size: 9px;
    }
    span {
      font-size: 11px;
      font-weight: 400;
      display: inline;
    }
  }
}
